import React, { useState, useEffect, memo, useRef } from 'react';
import { Modal, Row, Col, Form, Tab } from 'react-bootstrap';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../utils/axiosRequests';
import { useGetRequest } from '../../../hooks/useGetRequest';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import GroupWithDeleteIcon from '../partials/groupWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';
import TabNav from '../partials/tabNav';
import { useClickAway } from 'react-use';
import { handleGroupData } from '../../../utils/helpers';
import ReferredToGroupForm from './referredToGroupForm';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import SearchItemSuggestions from '../searchItemSuggestions';
import referredto from '../partials/views/referredto';

const ReferredToModal = ({
  modalTitle,
  isReferredToModal,
  setIsReferredToModal,
  selectedReferredto,
  setSelectedReferredto,
  selectedReferredtoGroups,
  setSelectedReferredtoGroups,
}) => {
  const [referredtoList, setReferredtoList] = useState([]);
  const [referredtoListInSearch, setReferredtoListInSearch] = useState([]);
  const [referredtoGroups, setReferredtoGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const [searchItemName, setSearchItemName] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedIndexForFocus, setSelectedIndexForFocus] = useState(null);
  const perPage = 25;
  const inputRefs = useRef([]);

  const handleFocus = (index) => {
    setSelectedIndexForFocus(index);
  };

  const referredToGroupList = async () => {
    await getRequest('referredto/groups')
      .then((data) => {
        setReferredtoGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    referredToGroupList();
  }, []);

  const { isLoading: isReferredtoLoading, refetch: getReferredtoList } =
    useGetRequest(
      'getReferredto',
      `referredto?page=${currentPage}&perPage=${perPage}`,
      (data) => {
        if (currentPage > 1) {
          setReferredtoList([...referredtoList, ...data.data]);
        } else {
          setReferredtoList(data.data);
          setTotalItem(data.total);
        }
      },
      (e) => {
        console.log(e);
      },
    );

  const selectReferredto = (item) => {
    const isItemExist = selectedReferredto?.some(
      (data) => data?.name?.toLowerCase() === item?.name?.toLowerCase(),
    );
    const updatedData = isItemExist
      ? selectedReferredto?.filter(
          (data) => data.name?.toLowerCase() !== item?.name?.toLowerCase(),
        )
      : [...selectedReferredto, { name: item.name, note: '' }];
    setSelectedReferredto(updatedData);
  };

  const deleteReferredTo = async (delId) => {
    await deleteRequest(`referredto/${delId}`)
      .then((data) => {
        setReferredtoList(referredtoList?.filter((item) => item.id !== delId));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteGroup = async (groupId) => {
    await deleteRequest(`referredto/groups/${groupId}`)
      .then((data) => {
        setReferredtoGroups(
          referredtoGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectGroup = (group) => {
    handleGroupData(
      group,
      selectedReferredtoGroups,
      setSelectedReferredtoGroups,
      selectedReferredto,
      setSelectedReferredto,
      'Referredto group',
    );
  };

  const removeReferredto = (item) => {
    setSelectedReferredto(
      selectedReferredto?.filter(
        (selectedItem) => selectedItem.name !== item.name,
      ),
    );
  };

  const handleExtras = (item, fieldName, value) => {
    const objIndex = selectedReferredto?.findIndex(
      (data) => data.name == item.name,
    );
    selectedReferredto[objIndex][fieldName] = value;
    setSelectedReferredto([...selectedReferredto]);
  };

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getReferredSuggestions',
      `prescriptions/suggestions?suggestion_type=referredto`,
      (data) => {
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

    const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);

  // const getChipComplainGroups = async () => {
  //   try {
  //     const data = await getRequest('chief-complains/groups');
  //     console.log(data, 'data');
  //     setComplainGroups(data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const openDropdown = () => {
    // setIsSubItemSuggestionOpen(true);
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (result, itemName) => {
    let data = [...selectedReferredto];
    const item = referredto?.find(
      (data) => data.name === itemName,
    );
    item['note'] = result;
    const isItemExist = data.some((data) => {
      if (data.name === item.name) {
        return true;
      }
    });

    let updatedData = [];
    if (isItemExist) {
      updatedData = data.map((complain) => {
        if (complain.name !== item.name) return complain;
        else return { ...complain, note: item?.note };
      });
    } else {
      updatedData = [
        ...data,
        {
          name: item.name,
          duration: '',
          unit: 'day(s)',
          note: item?.note ? item.note : '',
        },
      ];
    }
    setSelectedReferredto([...updatedData]);
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    // console.log('x, y : ', e.pageX, e.pageY);
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };
  //============End search suggestions ======================//

  const selectReferredtoWithNote = (item) => {
    let data = [...selectedReferredto];
    const isItemExist = data.some((data) => {
      if (data.name === item.name) {
        return true;
      }
    });

    let updatedData = [];
    if (isItemExist) {
      updatedData = data.map((complain) => {
        if (complain.name !== item.name) return complain;
        else return { ...complain, note: item?.note };
      });
    } else {
      updatedData = [
        ...data,
        {
          name: item.name,
          note: item?.note ? item.note : '',
        },
      ];
    }
    setSelectedReferredto([...updatedData]);
  };

  useEffect(() => {
    getReferredtoList();
  }, [currentPage]);

  useEffect(() => {
    if (isReferredToModal) {
      getSuggestions();
    }
  }, [isReferredToModal]);

  const SelectedReferredto = ({
    item,
    handleExtras,
    removeReferredto,
    inputRefs,
    handleFocus,
    index,
    selectedIndexForFocus,
  }) => {
    const [note, setNote] = useState('');
    const debouncedNote = useDebouncedValue(note, 500);
    const componentRef = useRef(null);

    useEffect(() => {
      if (debouncedNote) {
        handleExtras(item, 'note', debouncedNote);
      }
    }, [debouncedNote]);

    useEffect(() => {
      inputRefs.current[selectedIndexForFocus]?.focus();
    }, []);

    useClickAway(componentRef, () => {
      handleFocus(-1);
    });

    return (
      <Row className="selected-item-row" ref={componentRef}>
        <Col className="name" lg="5" md="5" sm="5" xs="5">
          {item.name}
        </Col>
        <Col lg="6" md="6" sm="6" xs="6">
          <Form.Control
            ref={(el) => (inputRefs.current[index] = el)}
            size="sm"
            type="text"
            defaultValue={item.note}
            placeholder="Enter note"
            onChange={(e) => setNote(e.target.value)}
            onClick={(e) => handleFocus(index)}
          />
        </Col>
        <Col lg="1" md="1" sm="1" xs="1">
          <i
            className="fa fa-times-circle pt-2 cursor-pointer"
            aria-hidden="true"
            onClick={() => removeReferredto(item)}
          ></i>
        </Col>
      </Row>
    );
  };

  let allData = () => {
    return referredtoList?.map((item, index) => {
      let isSelected = selectedReferredto?.some(
        (data) => data.name === item.name,
      );
      isSelected = isSelected ? true : false;

      return (
        <ItemWithDeleteIcon
          key={index}
          item={item}
          isSelected={isSelected}
          itemClickAction={selectReferredto}
          removeClickAction={deleteReferredTo}
          suggestions={suggestions}
          selectItemWithNote={selectReferredtoWithNote}
        />
      );
    });
  };

  let groupWithMinusBtn = () => {
    return referredtoGroups?.map((group, index) => {
      let isSelected = selectedReferredtoGroups?.some(
        (data) => data.id === group.id,
      );

      return (
        <GroupWithDeleteIcon
          key={index}
          item={group}
          isSelected={isSelected}
          itemClickAction={selectGroup}
          removeClickAction={deleteGroup}
        />
      );
    });
  };

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const selectedData = selectedOption;
      const isExisting = selectedReferredto?.some(
        (item) =>
          item?.name?.toLowerCase() === selectedData?.name?.toLowerCase(),
      );
      const filteredData = isExisting
        ? selectedReferredto?.filter(
            (item) =>
              item?.name?.toLowerCase() !== selectedData?.name?.toLowerCase(),
          )
        : [
            ...selectedReferredto,
            {
              name: selectedData.name,
              note: '',
            },
          ];
      setSelectedReferredto(filteredData);
      if (selectedData?.id === 'notFound') {
        const data = await postRequest('referredto', {
          name: selectedData.name,
        });
        setReferredtoList([...referredtoList, data]);
        setSearchItemName('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setReferredtoListInSearch([]);
      const url = `referredto?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setReferredtoListInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setIsReferredToModal(false);
    } else if (clickedArea.classList.contains('child-modal')) {
      setIsGroupModal(false);
    }
  });

  return (
    <Modal show={isReferredToModal} size="lg" className="customize-modal-size">
      <ModalHeader title={modalTitle} action={setIsReferredToModal} />
      <Modal.Body ref={ref}>
        <SearchArea
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          searchItemName={searchItemName}
          options={referredtoListInSearch}
          selectedInputs={selectedReferredto}
          placeholder={'referred to'}
          inputRefs={inputRefs}
          handleFocus={handleFocus}
        />
        <Tab.Container id="left-tabs-example" defaultActiveKey="all">
          <TabNav action={setIsGroupModal} selectedItems={selectedReferredto} />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area mr-0 ml-0">{allData()}</Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area mr-0 ml-0 mt-1">
                {groupWithMinusBtn()}
              </Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <ReferredToGroupForm
          modalTitle={modalTitle}
          isGroupModal={isGroupModal}
          setIsGroupModal={setIsGroupModal}
          itemNames={selectedReferredto?.map((item) => item.name)}
          referredtoGroups={referredtoGroups}
          setReferredtoGroups={setReferredtoGroups}
          selectedReferredtoGroups={selectedReferredtoGroups}
          setSelectedReferredtoGroups={setSelectedReferredtoGroups}
        />

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">
          {selectedReferredto?.map((item, index) => (
            <SelectedReferredto
              key={index}
              item={item}
              index={index}
              handleExtras={handleExtras}
              removeReferredto={removeReferredto}
              inputRefs={inputRefs}
              handleFocus={handleFocus}
              selectedIndexForFocus={selectedIndexForFocus}
            />
          ))}
        </div>

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(ReferredToModal);
