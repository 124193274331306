import React, { useState, Fragment, useRef, useEffect } from 'react';
import Header from '../../components/doctors/partials/Header';
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  ButtonGroup,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getRequest, patchRequest } from '../../utils/axiosRequests';
import { useValidation } from '../../hooks/validationHooks/useValiation';
import { appointmentSchema } from '../../validationSchemas/appointment.validation';
import LoadingButton from '../../components/forms/LoadingButton';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { toast } from 'react-toastify';
import {
  userRole,
  ageConvertToDateTime,
  separateDateTime,
} from '../../utils/helpers';
import moment from 'moment';
import TopLeftMenu from '../../components/doctors/partials/topLeftMenu';

function PatientHistory() {
  const { state } = useLocation();
  const [ageOrDob, setAgeOrDob] = useState(false);
  const [birthDate, setBirthDate] = useState('');
  const [patientId, setPatientId] = useState('');
  const [appointmentId, setAppointmentId] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState({
    years: '',
    months: '',
    days: '',
  });
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('male');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [appointmentDateTime, setAppointmentDateTime] = useState('');
  const [visitationFee, setVisitationFee] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [errors, setErrors] = useState([]);
  const [backendError, setBackendError] = useState('');
  const [searchResultArr, setSearchResultArr] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [patientData, setPatientData] = useState({});
  const validation = useValidation;

  const userInfo = useSelector((state) => state.authReducer.data);
  const activeOrganization = useSelector(
    (state) => state.orgReducer.organization,
  );
  const activeDoctor = useSelector(
    (state) => state.doctorsOfAssistantReducer.activeDoctor,
  );

  let navigate = useNavigate();
  const onSuccess = (data) => {
    toast.success(data.message, {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate('/appointments');
  };
  const onError = (error) => {
    setLoadingStatus(false);
    setBackendError(error.message);
  };

  const handleAge = (fieldName, val) => {
    if (fieldName === 'months' && val >= 12) {
      alert('Months must be less than 12');
      return false;
    }
    if (fieldName === 'days') {
      let daysOfMonth = dob?.months
        ? moment().month(dob?.months).daysInMonth()
        : moment().daysInMonth();
      if (val > daysOfMonth) {
        alert(`Days must be less than ${daysOfMonth}`);
        return false;
      }
    }
    const dobData = { ...dob, [fieldName]: val };
    setDob({ ...dobData });
    const convertedDate = ageConvertToDateTime(dobData);
    setBirthDate(convertedDate);
    console.log(dobData, convertedDate, 'dobDatadobData');
  };

  const handleType = (val) => {
    setAgeOrDob(val);
    if (!val) {
      setDob(separateDateTime(birthDate));
    } else {
    }
  };

  useEffect(() => {
    const appointmentInfo = state?.appointmentInfo;
    if (appointmentInfo) {
      const patientInfo = appointmentInfo?.patientId;
      setPatientId(patientInfo?.id);
      setAppointmentId(appointmentInfo?.id);
      setName(patientInfo?.name);
      setPhoneNumber(patientInfo?.phoneNumber);
      setGender(patientInfo?.gender);
      setAddress(patientInfo?.patientDetails?.address);
      setEmail(patientInfo?.email);
      setAppointmentDateTime(
        moment(appointmentInfo?.appointmentDateTime).format('YYYY-MM-DD HH:mm'),
      );
      setVisitationFee(appointmentInfo?.paymentDetails?.amount ?? '');
      setSerialNumber(appointmentInfo?.serialNumber);
      setBirthDate(moment(patientInfo?.dob).format('YYYY-MM-DD'));
      setDob({ ...separateDateTime(patientInfo?.dob) });
    }
  }, []);

  const submitUser = () => {
    patchRequest(
      `appointments/update-appointment-with-patient/${appointmentId}`,
      {
        patientId: patientId,
        name: name,
        dob: birthDate,
        phoneNumber: phoneNumber,
        gender: gender,
        address: address,
        email: email,
        appointmentDateTime:
          moment(appointmentDateTime).format('YYYY-MM-DD HH:mm'),
        serialNumber: serialNumber,
        fee: parseInt(visitationFee),
        doctorId:
          userRole(userInfo.userType) === 'assistant'
            ? activeDoctor.id
            : userInfo.id,
        organizationId:
          userRole(userInfo.userType) === 'assistant'
            ? userInfo?.organizationIds[0]?.id
            : activeOrganization.id,
      },
    )
      .then((res) => {
        onSuccess(res);
      })
      .catch((error) => {
        onError(error);
      });
  };

  // const { isPatientLoading, mutate: submitUser } = usePatchRequest(
  //   `appointments/update-appointment-with-patient/${patientId}`,
  //   {
  //     name: name,
  //     dob: birthDate,
  //     phoneNumber: phoneNumber,
  //     gender: gender,
  //     address: address,
  //     email: email,
  //     appointmentDateTime:
  //       moment(appointmentDateTime).format('YYYY-MM-DD HH:mm'),
  //     serialNumber: serialNumber,
  //     fee: parseInt(visitationFee),
  //     doctorId:
  //       userRole(userInfo.userType) === 'assistant'
  //         ? activeDoctor.id
  //         : userInfo.id,
  //     organizationId:
  //       userRole(userInfo.userType) === 'assistant'
  //         ? userInfo?.organizationIds[0]?.id
  //         : activeOrganization.id,
  //   },
  //   onSuccess,
  //   onError,
  // );
  const handleSubmit = () => {
    setLoadingStatus(true);
    const { isValid, errors } = validation(
      {
        name,
        birthDate,
        phoneNumber,
        gender,
        address,
        email,
        appointmentDateTime,
        visitationFee,
        serialNumber,
      },
      appointmentSchema,
    );
    if (isValid) {
      submitUser();
    }
    {
      if (Object.keys(errors).length !== 0) {
        setErrors(errors);
        setLoadingStatus(false);
      }
    }
  };
  const getErrorMessage = (inputName) => {
    return errors[inputName] ? errors[inputName] : '';
  };

  const typeaheadRef = useRef(null);
  const handleOnInputChange = (val) => {
    setIsLoading(true);
    const url = `patients?phoneNumber=${val}`;
    setPhoneNumber(val);

    getRequest(url)
      .then((data) => {
        setSearchResultArr(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const filterBy = () => true;

  const selectSearchResult = (selectedUser) => {
    setPhoneNumber(selectedUser[0].phoneNumber);
    setName(selectedUser[0].name);
    setDob(selectedUser[0]?.dob);
    setAddress(selectedUser[0].address);
    setGender(selectedUser[0].gender);
    setEmail(selectedUser[0].email);
    setPatientId(selectedUser[0].id);
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-12 sticky-header shadow header-sticky-second">
            <TopLeftMenu />
          </div>
        </div>
        <Row className="rx-body">
          <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold">Edit Appointment</h6>
              </div>

              <div className="card-body patient-history appointment-card-body">
                <Row>
                  <Col nd={3}></Col>
                  <Col md={6} className="pl-4">
                    <Form className="appointmentForm mb-4">
                      <div className="v-error text-center">{backendError}</div>
                      <div className="add-new-rx mb-3">
                        <span>Edit Appointment</span>
                      </div>
                      <Row>
                        <Col xs={12} sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Phone No.</Form.Label>
                            <AsyncTypeahead
                              labelKey="phoneNumber"
                              ref={typeaheadRef}
                              filterBy={filterBy}
                              id="async-example"
                              isLoading={isLoading}
                              options={searchResultArr}
                              placeholder={
                                phoneNumber
                                  ? phoneNumber
                                  : 'Search / Add patient here ...'
                              }
                              onChange={selectSearchResult}
                              minLength={5}
                              onSearch={handleOnInputChange}
                              // size="md"
                              className="sm md"
                              renderMenuItemChildren={(option, props) => (
                                <Fragment>
                                  <span>{option.phoneNumber}</span>
                                </Fragment>
                              )}
                            />
                            <small className="v-error">
                              {getErrorMessage('phoneNumber')}
                            </small>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="name"
                              placeholder="Enter name"
                              defaultValue={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            <small className="v-error">
                              {getErrorMessage('name')}
                            </small>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label style={{ display: 'block' }}>
                              <span>Age</span>
                              <ButtonGroup
                                className="appointment-btn-group"
                                aria-label="Basic example"
                              >
                                <Button
                                  size="sm"
                                  variant={
                                    !ageOrDob ? 'primary' : 'outline-secondary'
                                  }
                                  onClick={() => handleType(false)}
                                >
                                  Age
                                </Button>
                                <Button
                                  size="sm"
                                  variant={
                                    ageOrDob ? 'primary' : 'outline-secondary'
                                  }
                                  onClick={() => handleType(true)}
                                >
                                  DOB
                                </Button>
                              </ButtonGroup>
                            </Form.Label>
                            {!ageOrDob && (
                              <InputGroup>
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  className={dob?.years ? '' : 'placeholder'}
                                  defaultValue={
                                    dob?.years > 0 ? dob?.years : ''
                                  }
                                  value={dob?.years > 0 ? dob?.years : ''}
                                  placeholder="Yrs"
                                  onChange={(e) =>
                                    handleAge('years', e.target.value)
                                  }
                                />
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  className={dob?.months ? '' : 'placeholder'}
                                  defaultValue={
                                    dob?.months > 0 ? dob?.months : ''
                                  }
                                  value={dob?.months > 0 ? dob?.months : ''}
                                  placeholder="Mth"
                                  onChange={(e) =>
                                    handleAge('months', e.target.value)
                                  }
                                />
                                <Form.Control
                                  size="sm"
                                  type="number"
                                  className={dob?.days ? '' : 'placeholder'}
                                  defaultValue={dob?.days > 0 ? dob?.days : ''}
                                  value={dob?.days > 0 ? dob?.days : ''}
                                  placeholder="Day"
                                  onChange={(e) =>
                                    handleAge('days', e.target.value)
                                  }
                                />
                              </InputGroup>
                            )}
                            {ageOrDob && (
                              <Form.Control
                                type={'date'}
                                placeholder="Enter age"
                                defaultValue={birthDate}
                                onChange={(e) => setBirthDate(e.target.value)}
                              />
                            )}
                            <small className="v-error">
                              {getErrorMessage('birthDate')}
                            </small>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Gender</Form.Label>
                            <Form.Select
                              className="form-control"
                              defaultValue={gender}
                              value={gender}
                              onChange={(e) => setGender(e.target.value)}
                            >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="others">Others</option>
                            </Form.Select>
                            <small className="v-error">
                              {getErrorMessage('gender')}
                            </small>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Enter email"
                              defaultValue={email}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                            <small className="v-error">
                              {getErrorMessage('email')}
                            </small>
                          </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter address"
                              defaultValue={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                            <small className="v-error">
                              {getErrorMessage('address')}
                            </small>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Appointment Date</Form.Label>
                            <Form.Control
                              type="datetime-local"
                              value={appointmentDateTime}
                              onChange={(e) =>
                                setAppointmentDateTime(e.target.value)
                              }
                            />
                            <small className="v-error">
                              {getErrorMessage('appointmentDateTime')}
                            </small>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Visitation Fee</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter visitation fee"
                              value={visitationFee}
                              onChange={(e) => setVisitationFee(e.target.value)}
                            />
                            <small className="v-error">
                              {getErrorMessage('visitationFee')}
                            </small>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label>Serial Number</Form.Label>
                            <Form.Control
                              type="number"
                              value={serialNumber}
                              onChange={(e) => setSerialNumber(e.target.value)}
                            />
                            <small className="v-error">
                              {getErrorMessage('serialNumber')}
                            </small>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-center">
                          {loadingStatus ? (
                            <LoadingButton btnFull="yes" />
                          ) : (
                            <Row>
                              <Col
                                xs={12}
                                sm={12}
                                md={6}
                                className="ordering-1"
                              >
                                <Link
                                  // variant="outline-danger"
                                  type="button"
                                  className="btn btn-outline-danger"
                                  style={{ width: '100%' }}
                                  to={'/appointments'}
                                >
                                  <b>Cancel</b>
                                </Link>
                              </Col>
                              <Col
                                xs={12}
                                sm={12}
                                md={6}
                                className="ordering-2"
                              >
                                <Button
                                  variant="primary"
                                  style={{ width: '100%' }}
                                  onClick={handleSubmit}
                                >
                                  <b>Save</b>
                                </Button>
                              </Col>
                            </Row>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col nd={3}></Col>
                </Row>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default PatientHistory;
