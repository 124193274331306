import React, { memo, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import LoadingButton from '../../forms/LoadingButton';
import { useValidation } from '../../../hooks/validationHooks/useValiation';
import { adviceGroupSchema } from '../../../validationSchemas/adviceGroup.validation';
import { usePostRequest } from '../../../hooks/usePostRequest';

const AdviceGroupForm = ({
  modalTitle,
  adviceGroups,
  setAdviceGroups,
  selectedAdvicesGroups,
  setSelectedAdvicesGroups,
  subAdvices,
  isAdviceGroupModal,
  setIsAdviceGroupModal,
  itemNames,
}) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const [isTitle, setIsTitle] = useState(false);
  const validation = useValidation;

  const onSuccess = (data) => {
    setIsAdviceGroupModal(false);
    setAdviceGroups([...adviceGroups, data]);
    setSelectedAdvicesGroups([...selectedAdvicesGroups, data]);
  };

  const onError = (error) => {
    console.log('from react query error: ', error.message);
  };

  const { isLoading, mutate: submitUser } = usePostRequest(
    'advice/groups',
    {
      name: name,
      itemNames: itemNames,
      subAdvices: subAdvices,
      title: isTitle ? name : '',
    },
    onSuccess,
    onError,
  );

  const handleSubmit = () => {
    const { isValid, errors } = validation({ name }, adviceGroupSchema);
    if (isValid) {
      submitUser();
    }
    {
      setErrors(errors);
    }
  };
  const getErrorMessage = (inputName) => {
    return errors[inputName] ? errors[inputName] : '';
  };

  return (
    <Modal show={isAdviceGroupModal} size="md">
      <Modal.Header className="common-modal-header">
        <Modal.Title>{modalTitle} Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label className="label-custom">Group Name</Form.Label>
            <Form.Control
              type="text"
              className={`${getErrorMessage('name') ? 'v-border' : ''}`}
              placeholder="Enter group name"
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
            <small className="v-error">{getErrorMessage('name')}</small>
            <Form.Check
              type={`checkbox`}
              name="isTitle"
              label={`Use Group Name as Advice Title`}
              className="mt-1"
              checked={isTitle === true}
              onChange={(e) => setIsTitle(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => setIsAdviceGroupModal(false)}
        >
          Close
        </Button>
        <div className="d-grid gap-2">
          {isLoading ? (
            <LoadingButton btnFull="yes" />
          ) : (
            <Button variant="primary" size="sm" onClick={handleSubmit}>
              Save Changes
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default memo(AdviceGroupForm);
