import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useGetRequest } from '../../../hooks/useGetRequest';
import { getAvatarName, getColors } from '../../../utils/helpers';

const WaitingPatient = ({ waitingPatientsCount, setWaitingPatientsCount }) => {
  const [toggled, setToggled] = useState('toggled');
  const [waitingPatients, setWaitingPatients] = useState([]);
  const userInfo = useSelector((state) => state.authReducer.data);
  const activeOrganization = useSelector(
    (state) => state.orgReducer.organization,
  );
  const currentDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    getWaitingPatients();
  }, []);

  useEffect(() => {
    setInterval(() => {
      getWaitingPatients();
    }, 120000);
  }, []);

  const { isLoading: isAppointmentLoading, refetch: getWaitingPatients } =
    useGetRequest(
      'get-waiting-patients',
      `appointments?organizationId=${activeOrganization.id}&doctorId=${userInfo.id}&appointmentStatus=new&startDate=${currentDate}&endDate=${currentDate}`,
      (data) => {
        setWaitingPatients(data.appointments);
        setWaitingPatientsCount(data.total);
      },
      (e) => {
        console.log(e.message);
      },
    );

  return (
    <ul
      className={`navbar-nav sidebar sidebar-dark accordion ${toggled}`}
      id="accordionSidebar"
      style={{
        borderBox: 'content-box',
        transition: '0.3s ease-in-out',
      }}
    >
      <div className="avatar-parent">
        {waitingPatients?.slice(0, 7).map((item, key) => {
          return (
            <li className={`nav-item`} key={key}>
              <div
                key={item.id}
                className="avatar-child"
                title={item?.patientId?.name}
                style={{
                  background: [getColors[key]],
                  color: '#ffffff',
                  border: `1px solid ${getColors[key]}`,
                  fontSize: '14px',
                  fontWeight: '600',
                }}
              >
                {getAvatarName(item?.patientId?.name).toUpperCase()}
              </div>
            </li>
          );
        })}
        <li className="waiting-patient">
          <Link to={`/appointments`} state={{ status: 'new' }}>
            {waitingPatientsCount > 7 && (
              <div className="avatar-child more-btn">
                {' '}
                {' ' + waitingPatientsCount > 7
                  ? waitingPatientsCount - 7
                  : waitingPatientsCount}
                <i className="fa fa-plus"></i>
              </div>
            )}
          </Link>
        </li>
      </div>
    </ul>
  );
};

export default memo(WaitingPatient);
