import { Fragment, memo, useState, useRef, useEffect } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useClickAway } from 'react-use';

const PrescriptionSubItemSuggestions = ({
  openDropdown,
  isSubItemSuggestionOpen,
  handleSelect,
  closeDropdown,
  suggestions,
  itemName,
  forSearch,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [searchText, setSearchText] = useState('');

  const ref = useRef(null);
  useClickAway(ref, () => {
    closeDropdown();
  });

  useEffect(() => {
    suggestionsToFilterSuggestions();
  }, [suggestions]);

  useEffect(() => {
    if (searchText) {
      const matchedData = filteredSuggestions.filter((item) =>
        item.includes(searchText),
      );
      setFilteredSuggestions([...matchedData]);
    } else {
      suggestionsToFilterSuggestions();
    }
  }, [searchText]);

  const suggestionsToFilterSuggestions = () => {
    const specificItemSuggestions = suggestions.find(
      (suggestionItem) => suggestionItem._id === itemName,
    );
    const orderedSuggestions = specificItemSuggestions
      ? specificItemSuggestions.notes?.sort()
      : [];
    setFilteredSuggestions([...orderedSuggestions]);
  };

  // const arrowClass = forSearch ? 'fa fa-angle-right' : 'fa fa-angle-down';
  const arrowClass = forSearch ? '' : 'fa fa-angle-down';

  return (
    <NavDropdown
      ref={ref}
      title={
        <span>
          <i
            className={forSearch ? '' : 'fa fa-angle-down'}
            style={{
              color: '#00b38c',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
            onClick={openDropdown}
          />
        </span>
      }
      show={isSubItemSuggestionOpen}
      // onToggle={handleToggle}
      className="subitem-dropdown"
      style={{
        display: 'inline-block',
      }}
    >
      <NavDropdown.Item className="suggestionTitle">
        {filteredSuggestions?.length > 0 ? (
          <input
            type="text"
            style={{ width: '100%', margin: '3px 5px' }}
            name="Suggestion"
            placeholder="Find Suggestion"
            onChange={(e) => setSearchText(e.target.value)}
          />
        ) : (
          'Suggestions'
        )}
      </NavDropdown.Item>

      {filteredSuggestions?.length === 0 && (
        <NavDropdown.Item>No data found</NavDropdown.Item>
      )}

      <div className="subitems">
        {filteredSuggestions.map((item, index) => (
          <NavDropdown.Item
            key={`chief-${index}`}
            className="suggestionItem"
            onClick={(e) => {
              handleSelect(item);
              closeDropdown();
            }}
          >
            {item}
          </NavDropdown.Item>
        ))}
        {!suggestions?.length && (
          <span className="suggestion-error">No data found</span>
        )}
      </div>
      {/* <NavDropdown.Item className="suggestionLoadMore">
        Load More
      </NavDropdown.Item> */}
    </NavDropdown>
  );
};

export default memo(PrescriptionSubItemSuggestions);
