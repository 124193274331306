import logo from '../logo.svg';

const Support = () => {
    return (
        <div className="support-area">
            {/* <img className="support-logo" src={logo} alt="logo" />
            <div>
                <h6 className="for-support">For Support</h6>
                <p className="support-number">8801723452654, 8801723452654</p>
            </div> */}
        </div>
        )
    }
    
export default Support