import { memo, useRef } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import { deleteRequest, patchRequest } from '../../utils/axiosRequests';

const MedicineSubItemSuggestions = ({
  itemName,
  isTabCabType,
  selectMedicines,
  openDropdown,
  closeDropdown,
  schedules,
  setSchedules,
  isSubItemSuggestionOpen,
  item,
}) => {
  const ref = useRef(null);
  useClickAway(ref, () => {
    closeDropdown();
  });

  const defaultAction = async (
    defaultId,
    medicineId,
    doctorId,
    val,
    scheduleData,
    type,
  ) => {
    await selectMedicines(item, [scheduleData]);
    await patchRequest(`medicine-default/${defaultId}`, {
      medicineId: medicineId,
      doctorId: doctorId,
      [type]: val,
      type,
    })
      .then((response) => {
        setSchedules([...response.data]);
        closeDropdown();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteMakeDefault = async (delId) => {
    await deleteRequest(`medicine-default/${delId}`)
      .then((data) => {
        setSchedules(schedules?.filter((item) => item.id !== delId));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const scheduleList = () => {
    return schedules?.map((parentData, key) => {
      return parentData?.itemDetails?.map((scheduleData, index) => {
        return (
          <div
            className={`rx-sub ${parentData?.isDefault ? 'default' : ''}
            ${parentData?.isSelected ? 'selected' : ''}
            `}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              defaultAction(
                parentData.id,
                parentData.medicineId,
                parentData.doctorId,
                parentData?.isSelected ? false : true,
                scheduleData,
                'isSelected',
              );
              closeDropdown();
            }}
          >
            <span className="schedule">
              {isTabCabType &&
                Object.values(scheduleData?.quantities)?.map((item, index) => (
                  <span key={index}>
                    {item}
                    {++index === Object.values(scheduleData?.quantities).length
                      ? ' ' + scheduleData?.quantityUnit
                      : ' + '}
                  </span>
                ))}
              {!isTabCabType && (
                <span>
                  {scheduleData.quantity +
                    ' ' +
                    scheduleData.quantityUnit +
                    ' ' +
                    scheduleData.schedule +
                    ' ' +
                    scheduleData.scheduleUnit}
                </span>
              )}
            </span>
            <span className="instruction">
              {scheduleData?.instructions?.map((insItem, key) => (
                <span key={key}>
                  {insItem}{' '}
                  {scheduleData?.instructions?.length !== key + 1 && ', '}
                </span>
              ))}
            </span>
            {((scheduleData?.duration && scheduleData?.durationUnit) ||
              scheduleData?.durationUnit === 'চলবে') && (
              <span className="duration">
                {scheduleData.duration + ' ' + scheduleData.durationUnit}
              </span>
            )}
            <span
              className="action"
              onClick={(e) => {
                e.stopPropagation();
                defaultAction(
                  parentData.id,
                  parentData.medicineId,
                  parentData.doctorId,
                  parentData.isDefault ? false : true,
                  scheduleData,
                  'isDefault',
                );
              }}
            >
              {parentData?.isDefault ? (
                <i
                  title="Delete Make Default"
                  className="fa fa-star star-active pr-3"
                  style={{ color: '#DAA520', cursor: 'pointer' }}
                ></i>
              ) : (
                <i
                  title="Make Default"
                  className="fa fa-star star-inactive pr-3"
                  style={{ color: '#9aa8c9', cursor: 'pointer' }}
                ></i>
              )}
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  deleteMakeDefault(parentData.id);
                }}
                title="Delete"
                className="fa fa-times pr-2"
                style={{ color: 'red' }}
              ></i>
            </span>
          </div>
        );
      });
    });
  };

  return (
    <NavDropdown
      ref={ref}
      title={
        <span>
          <i
            className={'fa fa-angle-down'}
            style={{
              color: '#00b38c',
              paddingLeft: '8px',
              paddingRight: '5px',
            }}
            onClick={openDropdown}
          />
        </span>
      }
      show={isSubItemSuggestionOpen}
      // onToggle={handleToggle}
      className="subitem-dropdown"
      style={{
        display: 'inline-block',
      }}
    >
      <NavDropdown.Item className="suggestionTitle">
        {`${itemName} Suggestions`}
      </NavDropdown.Item>

      {schedules?.length === 0 && (
        <NavDropdown.Item>No data found</NavDropdown.Item>
      )}

      <div className="subitems subitems-rx parent-rx-sub">
        {schedules?.length > 0 && scheduleList()}
      </div>
    </NavDropdown>
  );
};

export default memo(MedicineSubItemSuggestions);
