import React from 'react';
import Webcam from 'react-webcam';
import { Form, Button } from 'react-bootstrap';

const videoConstraints = {
  width: 250,
  height: 250,
  facingMode: 'user',
};

const WebcamCapture = ({
  imageSrc,
  setImageSrc,
  isOpenCamera,
  setIsOpenCamera,
  setPatientInfo,
  patientInfo,
}) => {
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const screenshot = webcamRef.current.getScreenshot();
    setImageSrc(screenshot);
    setIsOpenCamera(false);
    setPatientInfo({ ...patientInfo, image: screenshot });
  }, [webcamRef]);

  const handleImageUpload = (e) => {
    setIsOpenCamera(false);
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result); // Base64 string is set as the imageSrc
        setPatientInfo({ ...patientInfo, image: reader.result });
      };
      reader.readAsDataURL(file); // Convert the file to a Base64 string
    }
  };

  return (
    <>
      {isOpenCamera && !imageSrc && (
        <>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={205}
            height={220}
            videoConstraints={videoConstraints}
          />
          <Button
            variant="primary"
            size="sm"
            className="mb-2"
            onClick={() => capture()}
          >
            Capture Photo
          </Button>
          <Form.Group controlId="formFileSm" className="mb-1 ml-2">
            <Form.Control type="file" size="sm" onChange={handleImageUpload} />
            <span style={{ fontSize: '11px' }} className="text-danger">
              Only png, jpg, jpeg
            </span>
          </Form.Group>
        </>
      )}
      {imageSrc && (
        <i
          className="fa fa-times cursor-pointer"
          onClick={() => setImageSrc(null)}
        ></i>
      )}
    </>
  );
};

export default WebcamCapture;
