import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import { medicineType, sortMedicines } from '../../../../utils/helpers';
import { getRequest } from '../../../../utils/axiosRequests';
import SearchDrug from './searchDrug';
import SelectedDrugList from './selectedDrugList';

const DrugHistories = ({ selectedData, setSelectedHistories }) => {
  const [editMedicineId, setEditMedicineId] = useState('');
  const [medicinesInSearch, setMedicinesInSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const filterBy = () => true;

  console.log(selectedData, 'selectedDataselectedDataselectedData');

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    const url = `medicines?any=${searchKey}&search=yes`;

    getRequest(url)
      .then((data) => {
        if (data.data.length > 0) {
          const medicines = data.data;
          const sortedMedicines = sortMedicines({
            medicines,
          });
          setMedicinesInSearch([...sortedMedicines]);
        } else {
          setMedicinesInSearch([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const medicineUpdated = (updatedData, index) => {
    selectedData['drugHistories']['D_H'][index] = updatedData;
    setSelectedHistories({ ...selectedData });
  };

  const selectMedicines = async (item, type) => {
    let tempItem = { ...item };
    if (
      !selectedData.drugHistories[type].some(
        (selectedMedicine) => selectedMedicine.id === tempItem.id,
      )
    ) {
      tempItem.name = tempItem['brandName'];
      tempItem.type = tempItem.type;
      tempItem.alterMedicine = '';
      tempItem.itemDetails = [
        {
          quantitiesField: [1, 2, 3],
          schedule: '',
          scheduleUnit: 'বার',
          quantities: { first: 0, second: 0, third: 0 },
          quantity: '',
          quantityUnit: medicineType(tempItem?.type) ? 'টা' : 'চামুচ',
          times: '১',
          timesUnit: 'বেলা',
          duration: '',
          durationUnit: 'দিন',
          instructions: [],
        },
      ];
      let result = [...selectedData.drugHistories[type], tempItem];
      selectedData.drugHistories[type] = result;
      setSelectedHistories({ ...selectedData });
    } else {
      unselectMedicine(item, type);
    }
  };

  const unselectMedicine = (type, itemId) => {
    const result = selectedData?.drugHistories[type]?.filter(
      (selectedItem) => selectedItem.id !== itemId,
    );
    selectedData.drugHistories[type] = result;
    setSelectedHistories({ ...selectedData });
  };

  const handleSelectedMedicine = (
    fieldName,
    medicineId,
    value,
    itemDetailIndex,
  ) => {
    const data = [...selectedData?.drugHistories?.D_H];
    const objIndex = data.findIndex((medicine) => medicine.id === medicineId);
    if (objIndex === -1) return;

    switch (fieldName) {
      case 'quantities':
      case 'quantitiesField':
      case 'quantityUnit':
      case 'quantity':
      case 'schedule':
      case 'scheduleUnit':
      case 'duration':
      case 'durationUnit':
        data[objIndex]['itemDetails'][itemDetailIndex][fieldName] = value;
        if (fieldName === 'duration' && (value === '' || value === 'চলবে')) {
          data[objIndex]['itemDetails'][itemDetailIndex]['durationUnit'] = '';
        }
        if (
          fieldName === 'durationUnit' &&
          (value === '' || value === 'চলবে')
        ) {
          data[objIndex]['itemDetails'][itemDetailIndex]['duration'] = '';
        }
        break;
      case 'quantitiesData':
        data[objIndex] = value;
        break;
      case 'itemDetails':
        data[objIndex]['itemDetails'] = value;
        break;
      case 'medicineType':
        data[objIndex]['type'] = value;
        break;
      default:
        break;
    }
    setSelectedHistories({
      ...selectedData,
      drugHistories: { ...selectedData.drugHistories, D_H: data },
    });
  };

  return (
    <Row className="selected-drug-histories">
      <Col>
        <Form.Group as={Row} className="mb-3 mt-2 common-font-size">
          <Form.Label column sm="1">
            <span className="pl-3">D/H:</span>
          </Form.Label>
          <Col sm="10" className="mt-1">
            <SearchDrug
              type={'D_H'}
              selectMedicines={selectMedicines}
              handleOnInputChange={handleOnInputChange}
              medicinesInSearch={medicinesInSearch}
              setMedicinesInSearch={setMedicinesInSearch}
            />
            <SelectedDrugList
              selectedData={selectedData}
              unselectMedicine={unselectMedicine}
              editMedicineId={editMedicineId}
              setEditMedicineId={setEditMedicineId}
              handleSelectedMedicine={handleSelectedMedicine}
              medicineUpdated={medicineUpdated}
            />
          </Col>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DrugHistories;
