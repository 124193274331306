import { useSelector } from 'react-redux';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';

const BarcodeQrCode = ({ prescriptionId, patientInfo }) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const { doctorId, organizationId } = patientInfo;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const value = `https://api.myrx.health/api/prescriptions-patient/pdf-scan?prescriptionId=${prescriptionId}`;
  
  return (
    <>
      {prescriptionId && userInfo?.codeType === 'barcode' && (
        <div className="bar-qr-code">
          <Barcode
            value={`${baseUrl}/pdf?dId=${doctorId}&pId=${prescriptionId}&oId=${organizationId}`}
            displayValue={false}
            width={0.5}
            height={40}
            format="CODE128"
            fontOptions=""
            font="monospace"
            textAlign="center"
            textPosition="bottom"
            textMargin={1}
            fontSize={20}
            background="#ffffff"
            lineColor="#000000"
            margin={10}
          />
        </div>
      )}
      {prescriptionId && userInfo?.codeType === 'qrcode' && (
        <div
          className="bar-qr-code"
          style={{
            height: 100,
            width: 100,
            margin: '0 auto',
          }}
        >
          <QRCode
            size={100}
            style={{
              height: '100%',
              width: '100%',
            }}
            value={value}
            viewBox={`0 0 100 100`}
          />
        </div>
      )}
    </>
  );
};

export default BarcodeQrCode;
