import React, { memo } from 'react';

const LoadMore = ({ currentPage, totalItem, perPage, currentPageAction }) => {
  return (
    <>
      {currentPage < parseInt(totalItem / perPage) + 1 && (
        <div
          className="cursor-pointer float-right"
          onClick={() => currentPageAction(currentPage + 1)}
        >
          <i className="fas fa-angle-double-down load-more-icon"></i>
        </div>
      )}
    </>
  );
};
export default memo(LoadMore);
