import { memo, useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { Row, Col, Button } from 'react-bootstrap';
import { getRequest } from '../../../utils/axiosRequests';
import { oncologyList } from '../../../utils/helpers';
import { DiagnosisContext } from '../../../contexts/DiagnosisContext';

const Staging = () => {
  const { selectedStage, setSelectedStage } = useContext(DiagnosisContext);
  const defaultData = {
    tnmStagingType: '',
    tumor: '',
    node: '',
    metastasis: '',
    psa: '',
    gradeGroup: '',
    stage: '',
    isShow: false,
  };
  let selectedData = selectedStage || defaultData;
  let {
    tnmStagingType,
    tumor,
    node,
    metastasis,
    psa,
    gradeGroup,
    stage,
    isShow,
  } = selectedData;
  const [tumors, setTumors] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [metastases, setMetastases] = useState([]);
  const [psas, setPsas] = useState([]);
  const [gradeGroups, setGradeGroups] = useState([]);
  const [stagingType, setStagingType] = useState(
    tnmStagingType
      ? {
          value: tnmStagingType,
          label: oncologyList().find((entry) => entry.value == tnmStagingType)
            ?.label,
        }
      : null,
  );
  const getSelectedItem = (item) => {
    return item
      ? {
          value: item,
          label: item,
        }
      : null;
  };
  const [selectedTumor, setSelectedTumor] = useState(getSelectedItem(tumor));
  const [selectedNode, setSelectedNode] = useState(getSelectedItem(node));
  const [selectedMetastasis, setSelectedMetastasis] = useState(
    getSelectedItem(metastasis),
  );
  const [selectedPsa, setSelectedPsa] = useState(getSelectedItem(psa));
  const [selectedGradeGroup, setSelectedGradeGroup] = useState(
    getSelectedItem(gradeGroup),
  );

  useEffect(() => {
    try {
      if (stagingType?.value) {
        handleTnmStaging(stagingType?.value);
      }
    } catch (error) {
      console.log(error);
    }
  }, [stagingType?.value]);

  const handleTnmStaging = (tnmStagingType) => {
    const url = `stagings?tnmStagingType=${tnmStagingType}`;
    getRequest(url)
      .then((data) => {
        handleDataOptions(tnmStagingType, data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDataOptions = (tnmStagingType, data) => {
    setTumors(dataParse(data, 'tumor'));
    setNodes(dataParse(data, 'node'));
    setMetastases(dataParse(data, 'metastasis'));
    if (tnmStagingType == 3 || stagingType?.value == 3) {
      setPsas(dataParse(data, 'psa'));
      setGradeGroups(dataParse(data, 'gradeGroup'));
    }
  };

  useEffect(() => {
    try {
      let url;
      if (
        selectedTumor &&
        selectedNode &&
        selectedMetastasis &&
        stagingType?.value != 3
      ) {
        url = `stagings/get-stage?tnmStagingType=${stagingType?.value}&tumor=${selectedTumor?.value}&node=${selectedNode?.value}&metastasis=${selectedMetastasis?.value}`;
      } else if (
        selectedTumor &&
        selectedNode &&
        selectedMetastasis &&
        selectedPsa &&
        selectedGradeGroup
      ) {
        url = `stagings/get-stage?tnmStagingType=${stagingType?.value}&tumor=${selectedTumor?.value}&node=${selectedNode?.value}&metastasis=${selectedMetastasis?.value}&psa=${selectedPsa?.value}&gradeGroup=${selectedGradeGroup?.value}`;
      }
      if (url) {
        getStagingData(url);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    stagingType,
    selectedTumor,
    selectedNode,
    selectedMetastasis,
    selectedPsa,
    selectedGradeGroup,
  ]);

  const getStagingData = (url) => {
    getRequest(encodeURI(url))
      .then((data) => {
        setSelectedStage({
          ...selectedData,
          tnmStagingType: data?.tnmStagingType,
          tumor: data?.tumor,
          node: data?.node,
          metastasis: data?.metastasis,
          psa: data?.psa,
          gradeGroup: data?.gradeGroup,
          stage: data?.stage,
        });
      })
      .catch((error) => {
        clearData();
        console.log(error, 'catch area');
      });
  };

  const clearData = () => {
    setSelectedStage({ ...defaultData });
  };

  const dataParse = (data, itemName) => {
    return data
      .map((item) => ({ label: item[itemName], value: item[itemName] }))
      .reduce((acc, cur) => {
        let exists = acc.some((item) => item.label === cur.label);
        if (!exists) {
          acc.push(cur);
        }
        return acc;
      }, []);
  };

  const handleShowInPrescription = () => {
    setSelectedStage({
      ...selectedData,
      isShow: isShow ? false : true,
    });
  };

  return (
    <div className="staging">
      <Row>
        <Col sm={2} className="pt-1">
          TNM Staging:
        </Col>
        <Col sm={7}>
          <Select
            className="basic-single"
            classNamePrefix="Select Type"
            defaultValue={stagingType}
            isClearable={true}
            name="type"
            options={oncologyList()}
            onChange={setStagingType}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Row className="mt-2">
            <Col md={3}>Tumor:</Col>
            <Col>
              <Select
                className="basic-single"
                classNamePrefix="Select Type"
                isClearable={true}
                name="tumor"
                defaultValue={selectedTumor}
                options={tumors}
                onChange={setSelectedTumor}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={3}>Nodes:</Col>
            <Col>
              <Select
                className="basic-single"
                classNamePrefix="Select Type"
                isClearable={true}
                name="node"
                defaultValue={selectedNode}
                options={nodes}
                onChange={setSelectedNode}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={3}>Metastasis:</Col>
            <Col>
              <Select
                className="basic-single"
                classNamePrefix="Select Type"
                isClearable={true}
                name="metastasis"
                defaultValue={selectedMetastasis}
                options={metastases}
                onChange={setSelectedMetastasis}
              />
            </Col>
          </Row>

          {(stagingType?.value === 3 || tnmStagingType == 3) && (
            <>
              <Row className="mt-2">
                <Col md={3}>PSA:</Col>
                <Col>
                  <Select
                    className="basic-single"
                    classNamePrefix="Select Type"
                    isClearable={true}
                    name="psa"
                    defaultValue={selectedPsa}
                    options={psas}
                    onChange={setSelectedPsa}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3}>Grade Group:</Col>
                <Col>
                  <Select
                    className="basic-single"
                    classNamePrefix="Select Type"
                    isClearable={true}
                    name="gradeGroup"
                    defaultValue={selectedGradeGroup}
                    options={gradeGroups}
                    onChange={setSelectedGradeGroup}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Col sm={3} className="stage-area">
          <div className="stage">{stage}</div>
          {stage && (
            <Button
              size="sm"
              variant={`${isShow ? 'primary' : 'outline-secondary'}`}
              onClick={() => handleShowInPrescription()}
            >
              Show in prescription
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default memo(Staging);
