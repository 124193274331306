import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';

import { usePostRequest } from '../../../hooks/usePostRequest';
import { useValidation } from '../../../hooks/validationHooks/useValiation';
import { medicineSchema } from '../../../validationSchemas/medicine.validation';
import LoadingButton from '../../forms/LoadingButton';
import { MEDICINE_TYPES } from '../../../utils/helpers';
import Select from 'react-select';

const centerStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999,
  background: 'white',
  width: '340px',
  height: 'auto',
  maxHeight: '590',
  paddingtop: '5px',
  paddingBottom: '5px',
  border: '1px solid ',
  boxShadow: '0 3px 9px rgba(0,0,0,.5)',
};

const stickyStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'yellow',
  zIndex: 1000, // Set a lower z-index value for the sticky component
};

// const socket = io('localhost:3009');

function VideoCallModal({
  openVideoModal,
  setOpenVideoModal,
  onlineUserExist,
  setOnlineUserExist,
  startCalling,
  setStartCalling,
  startVideo,
  setStartVideo,
  callTimeoutHandler,
  setCallTimeoutHandler,
  shouldClearTimeout,
  setShouldClearTimeout,
  socket,
  io,
  cancelCall,
  patientInfo,
  doctorInfo,
}) {
  // const [startCalling, setStartCalling] = useState(1);
  // const [startVideo, setStartVideo] = useState(0);
  // const [callTimeoutHandler, setCallTimeoutHandler] = useState(null);
  // const [shouldClearTimeout, setShouldClearTimeout] = useState(false);

  const domain = 'vchat.scholars-home.org';
  let api = {};

  const navigate = useNavigate();
  const name = patientInfo?.name;

  const patientId = patientInfo?.id;
  const roomName = doctorInfo.id + '_' + patientId;

  useEffect(() => {
    // client-side
    setStartCalling(1);
    const content = {
      toUsername: patientId,
      message: {
        type: 'startCall',
        payload: { roomName: roomName },
      },
    };
    socket.emit('privateMessage', content);
  }, []);

  useEffect(() => {
    // Set a timeout when the component mounts
    const timeoutId = setTimeout(() => {
      setOpenVideoModal(false);
    }, 60000);

    // Save the timeout ID to the state
    setCallTimeoutHandler(timeoutId);

    // Clear the timeout if shouldClearTimeout is true
    if (shouldClearTimeout) {
      clearTimeout(timeoutId);
    }

    // Cleanup function when the component unmounts or shouldClearTimeout changes
    return () => {
      clearTimeout(timeoutId);
    };
  }, [shouldClearTimeout]);

  // INTIALISE THE MEET WITH THIS FUNCTION
  const startMeet = useCallback(() => {
    const options = {
      roomName: roomName,
      width: '100%',
      height: 400,
      configOverwrite: {
        prejoinPageEnabled: false,
        startWithAudioMuted: false,
        startWithVideoMuted: false,
      },
      interfaceConfigOverwrite: {
        // overwrite interface properties if you want
      },
      // VIDEO FRAME WILL BE ADDED HERE
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: name,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    api = new window.JitsiMeetExternalAPI(domain, options);

    api.addEventListeners({
      readyToClose: handleClose,
      participantLeft: handleParticipantLeft,
      participantJoined: handleParticipantJoined,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
    });
  }, [api]);

  // ALL OUR HANDLERS
  const handleClose = () => {
    console.log('handleClose');
    setStartVideo(0);
    setOpenVideoModal(false);
  };

  const handleParticipantLeft = async (participant) => {
    console.log('handleParticipantLeft', participant);
    await getParticipants();
  };

  const handleParticipantJoined = async (participant) => {
    console.log('handleParticipantJoined', participant);
    await getParticipants();
  };

  const handleVideoConferenceJoined = async (participant) => {
    console.log('handleVideoConferenceJoined', participant);
    await getParticipants();
  };

  const handleVideoConferenceLeft = () => {
    console.log('handleVideoConferenceLeft');
    // navigate('/');
  };

  // GETTING ALL PARTICIPANTS
  const getParticipants = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(api.getParticipantsInfo());
      }, 500);
    });
  };

  useEffect(() => {
    console.log('starting video...', startVideo);
    if (startVideo) {
      setShouldClearTimeout(true);
    }
    startStream();
  }, [startVideo]);

  // const startCall = () => {
  //   setStartCalling(1);
  //   setStartVideo(0);
  //   if (callTimeoutHandler) {
  //     clearTimeout(callTimeoutHandler);
  //   }
  // };

  const startStream = () => {
    console.log('starting video 1');
    if (window.JitsiMeetExternalAPI) {
      console.log('starting video 2', startVideo);
      if (startVideo === 1) {
        console.log('starting video 3');
        startMeet();
      }
    } else {
      alert('JitsiMeetExternalAPI not loaded');
    }
  };

  return (
    <>
      <Draggable defaultPosition={{ x: 0, y: 0 }}>
        <div style={{ ...centerStyle }} className="modal-content stickyStyle">
          <div
            class="modal-header ui-draggable-handle"
            style={{ cursor: 'grab' }}
          >
            <span>Patient meet</span>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                // if (api) {
                //   api.dispose();
                // }
                setOpenVideoModal(false);
              }}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            {startVideo === 1 && startVideo === 1 && (
              <div id="jitsi-iframe" style={{ marginBottom: 0 }}></div>
            )}

            {startCalling === 1 && startVideo === 0 && (
              <div>
                <span>Calling...</span>
                <Button
                  variant="danger"
                  style={{ marginLeft: '30px' }}
                  onClick={() => {
                    cancelCall();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </Draggable>
    </>
  );
}
export default VideoCallModal;
