import { memo } from 'react';
import { Nav } from 'react-bootstrap';

const TabNavMedicineDefault = () => {
  return (
    <Nav
      variant="pills"
      className="custom-tab mt-2"
      style={{ justifyContent: 'space-between' }}
    >
      <Nav.Item>
        <Nav.Link eventKey="suggestions">Suggestions</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="similarMedicines">Similar Medicines</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="formatsAndType">Formats & Type</Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="information">Medicine Information</Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default memo(TabNavMedicineDefault);
