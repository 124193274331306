import { memo } from 'react';

const GynePatientType = ({ gynePatType, setGynePatType }) => {
  return (
    <div
      role="group"
      className="btn-group rx-type-btns"
      style={{ marginLeft: '9rem' }}
    >
      <button
        type="button"
        className={`btn btn-sm ${
          gynePatType === 'wife' ? 'btn-primary' : 'btn-outline-secondary'
        }`}
        onClick={() => setGynePatType('wife')}
      >
        Wife
      </button>
      <button
        type="button"
        className={`btn btn-sm ${
          gynePatType === 'husband' ? 'btn-primary' : 'btn-outline-secondary'
        }`}
        onClick={() => setGynePatType('husband')}
      >
        Husband
      </button>
    </div>
  );
};

export default memo(GynePatientType);
