import { memo } from 'react';

const CommonDeleteBtn = ({ action, itemName, delId, isDelBtn = true }) => {
  return (
    <>
      {isDelBtn && (
        <i
          className="fa fa-times-circle float-right pr-5 pt-1"
          onClick={(e) => {
            e.stopPropagation();
            action(itemName, delId);
          }}
        ></i>
      )}
    </>
  );
};

export default memo(CommonDeleteBtn);
