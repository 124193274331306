const Joi = require('joi');

export const specialNoteSchema = Joi.object({
  groupName: Joi.string().required().messages({
    'string.empty': `Group name is required`,
  }),
  note: Joi.string().required().messages({
    'string.empty': `Note is required`,
  }),
}).unknown();
