import React, { useState, Fragment, memo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Form, InputGroup, Row } from 'react-bootstrap';
import {
  agePrint,
  sizeConverter,
  separateDateTime,
  calculatePatientLabelPadding,
  capitalizeFirstLetter,
} from '../../utils/helpers';
import AddPatientInfo from '../../components/doctors/modals/addPatientInfo';

const PatientSection = (props) => {
  let {
    patientPicture,
    patientInfo,
    isPad,
    appointmentInfo,
    prescriptionSetting,
    setPatientInfo,
    selectedOnExamination,
    setSelectedOnExamination,
  } = props;
  let { name, dob, gender, phoneNumber } = patientInfo;
  dob = separateDateTime(dob);
  const [infoModal, setInfoModal] = useState(false);
  const patientSettings = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.patientSettings,
  );
  const firstRow = patientSettings?.items?.filter(
    (item) => item.rowNumber === 1 && item.isHidden === false,
  );
  const secondRow = patientSettings?.items?.filter(
    (item) => item.rowNumber === 2 && item.isHidden === false,
  );
  const thirdRow = patientSettings?.items?.filter(
    (item) => item.rowNumber === 3 && item.isHidden === false,
  );
  const ageProperties = patientSettings?.items?.find(
    (item) => item.name === 'age',
  );
  let { unitProperties } = ageProperties || {};
  let { years, months, days, hours } = unitProperties || {};
  const handlePatientInput = (fieldName, val) => {
    setPatientInfo({
      ...patientInfo,
      [fieldName]: val,
    });
  };

  const handleAge = (fieldName, val) => {
    if (fieldName === 'months' && val >= 12) {
      alert('Months must be less than 12');
      return false;
    }
    if (fieldName === 'days') {
      let daysOfMonth = dob?.months
        ? moment().month(dob?.months).daysInMonth()
        : moment().daysInMonth();
      if (val > daysOfMonth) {
        alert(`Days must be less than ${daysOfMonth}`);
        return false;
      }
    }

    const dobData = patientInfo.id
      ? { ...dob, [fieldName]: val }
      : { ...patientInfo.dob, [fieldName]: val };

    setPatientInfo({
      ...patientInfo,
      dob: dobData,
    });
  };

  const actualWidth = prescriptionSetting?.page?.width?.quantity;
  const totalPadding = sizeConverter(
    actualWidth,
    parseFloat(prescriptionSetting?.page?.marginLeft?.quantity) +
      parseFloat(prescriptionSetting?.page?.marginRight?.quantity),
  );

  const patientSettingStyle = {
    marginLeft:
      prescriptionSetting?.patientSetting?.marginLeft?.quantity +
      prescriptionSetting?.patientSetting?.marginLeft?.unit,
    marginRight:
      prescriptionSetting?.patientSetting?.marginRight?.quantity +
      prescriptionSetting?.patientSetting?.marginRight?.unit,
    marginTop:
      prescriptionSetting?.patientSetting?.marginTop?.quantity +
      prescriptionSetting?.patientSetting?.marginTop?.unit,
    marginBottom:
      prescriptionSetting?.patientSetting?.marginBottom?.quantity +
      prescriptionSetting?.patientSetting?.marginBottom?.unit,
    borderLeft: prescriptionSetting?.patientSetting?.borderLeft,
    borderRight: prescriptionSetting?.patientSetting?.borderRight,
    borderTop: prescriptionSetting?.patientSetting?.borderTop,
    borderBottom: prescriptionSetting?.patientSetting?.borderBottom,
  };

  const appointmentDate = (appointmentDateTime) => {
    return appointmentInfo?.appointmentDateTime !== ''
      ? moment(appointmentInfo?.appointmentDateTime).format('DD/MM/YYYY')
      : moment().format('DD/MM/YYYY');
  };

  const printWeightHeight = (itemName) => {
    let dataInfo = selectedOnExamination?.generalExaminations;
    let result = '';
    if (itemName === 'height') {
      result = dataInfo?.heightInfo?.feet ? dataInfo.heightInfo.feet : '';
      result += dataInfo?.heightInfo?.inch
        ? '.' + dataInfo?.heightInfo?.inch
        : '';
      result += result ? ' ' + dataInfo?.heightInfo?.heightUnit : '';
    } else if (itemName === 'weight') {
      result = dataInfo?.weightInfo?.weight ? dataInfo.weightInfo?.weight : '';
      result += result ? ' ' + dataInfo?.weightInfo?.weightUnit : '';
    }
    return result;
  };

  return (
    <>
      {firstRow?.length > 0 && (
        <Row
          className={`patient-section-print ${
            isPad ? '' : 'no-pad-patient-section'
          }`}
          style={isPad ? patientSettingStyle : {}}
        >
          {firstRow?.map((item, index) => {
            return (
              <Fragment key={index}>
                {isPad ? (
                  item?.label?.properties?.isHidden ? (
                    <div
                      style={calculatePatientLabelPadding(
                        item,
                        'label',
                        'paddingLeft',
                        'paddingRight',
                        'paddingTop',
                        'paddingBottom',
                        actualWidth,
                        totalPadding,
                        isPad,
                      )}
                    ></div>
                  ) : (
                    <div
                      className={`${
                        item?.name == 'phoneNumber' ? 'print-phn-label' : ''
                      } ${item?.name == 'patientId' ? 'print-patient-id' : ''}
                      }`}
                      style={calculatePatientLabelPadding(
                        item,
                        'label',
                        'paddingLeft',
                        'paddingRight',
                        'paddingTop',
                        'paddingBottom',
                        actualWidth,
                        totalPadding,
                        isPad,
                      )}
                    >
                      {item?.label?.labelName}:
                    </div>
                  )
                ) : (
                  ''
                )}
                {isPad ? (
                  <div
                    className="p-item-val"
                    style={
                      isPad
                        ? calculatePatientLabelPadding(
                            item,
                            'value',
                            'paddingLeft',
                            'paddingRight',
                            'paddingTop',
                            'paddingBottom',
                            actualWidth,
                            totalPadding,
                            isPad,
                          )
                        : {}
                    }
                  >
                    {item.name === 'age'
                      ? agePrint(dob, item?.unitProperties)
                      : item.name === 'date'
                      ? appointmentDate()
                      : item.name === 'weight' || item.name === 'height'
                      ? printWeightHeight(item.name)
                      : item.name === 'gender'
                      ? capitalizeFirstLetter(patientInfo[item.name])
                      : item.name === 'address'
                      ? patientInfo?.patientDetails?.address
                      : item.name === 'referenceBy'
                      ? patientInfo?.patientDetails?.reference_by
                      : item.name === 'bloodGroup'
                      ? selectedOnExamination?.generalExaminations?.bloodGroup
                      : patientInfo[item.name]}
                  </div>
                ) : (
                  <div className="n">
                    {item?.label?.labelName}: &nbsp;{' '}
                    {item.name === 'age'
                      ? agePrint(dob, item?.unitProperties)
                      : item.name === 'date'
                      ? appointmentDate()
                      : item.name === 'weight' || item.name === 'height'
                      ? printWeightHeight(item.name)
                      : item.name === 'gender'
                      ? capitalizeFirstLetter(patientInfo[item.name])
                      : item.name === 'address'
                      ? patientInfo?.patientDetails?.address
                      : item.name === 'referenceBy'
                      ? patientInfo?.patientDetails?.reference_by
                      : item.name === 'bloodGroup'
                      ? selectedOnExamination?.generalExaminations?.bloodGroup
                      : patientInfo[item.name]}
                  </div>
                )}
              </Fragment>
            );
          })}
        </Row>
      )}
      {
        secondRow?.length > 0 && (
          <Row
            className={`patient-section-print ${
              isPad ? '' : 'no-pad-patient-section'
            }`}
            style={isPad ? patientSettingStyle : {}}
          >
            {secondRow?.map((item, index) => {
              return (
                <Fragment key={index}>
                  {isPad ? (
                    item?.label?.properties?.isHidden ? (
                      <div
                        style={calculatePatientLabelPadding(
                          item,
                          'label',
                          'paddingLeft',
                          'paddingRight',
                          'paddingTop',
                          'paddingBottom',
                          actualWidth,
                          totalPadding,
                          isPad,
                        )}
                      ></div>
                    ) : (
                      <div
                        style={calculatePatientLabelPadding(
                          item,
                          'label',
                          'paddingLeft',
                          'paddingRight',
                          'paddingTop',
                          'paddingBottom',
                          actualWidth,
                          totalPadding,
                          isPad,
                        )}
                      >
                        {item?.label?.labelName}:
                      </div>
                    )
                  ) : (
                    ''
                  )}
                  {isPad ? (
                    <div
                      className="p-item-val"
                      style={
                        isPad
                          ? calculatePatientLabelPadding(
                              item,
                              'value',
                              'paddingLeft',
                              'paddingRight',
                              'paddingTop',
                              'paddingBottom',
                              actualWidth,
                              totalPadding,
                              isPad,
                            )
                          : {}
                      }
                    >
                      {item.name === 'age'
                        ? agePrint(dob, item?.unitProperties)
                        : item.name === 'date'
                        ? appointmentDate()
                        : item.name === 'weight' || item.name === 'height'
                        ? printWeightHeight(item.name)
                        : item.name === 'gender'
                        ? capitalizeFirstLetter(patientInfo[item.name])
                        : item.name === 'address'
                        ? patientInfo?.patientDetails?.address
                        : item.name === 'referenceBy'
                        ? patientInfo?.patientDetails?.reference_by
                        : item.name === 'bloodGroup'
                        ? selectedOnExamination?.generalExaminations?.bloodGroup
                        : patientInfo[item.name]}
                    </div>
                  ) : (
                    <div className="n">
                      {item?.label?.labelName}: &nbsp;{' '}
                      {item.name === 'age'
                        ? agePrint(dob, item?.unitProperties)
                        : item.name === 'date'
                        ? appointmentDate()
                        : item.name === 'weight' || item.name === 'height'
                        ? printWeightHeight(item.name)
                        : item.name === 'gender'
                        ? capitalizeFirstLetter(patientInfo[item.name])
                        : item.name === 'address'
                        ? patientInfo?.patientDetails?.address
                        : item.name === 'referenceBy'
                        ? patientInfo?.patientDetails?.reference_by
                        : item.name === 'bloodGroup'
                        ? selectedOnExamination?.generalExaminations?.bloodGroup
                        : patientInfo[item.name]}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </Row>
        )}
      {
        thirdRow?.length > 0 && (
          <Row
            className={`patient-section-print ${
              isPad ? '' : 'no-pad-patient-section'
            }`}
            style={isPad ? patientSettingStyle : {}}
          >
            {thirdRow?.map((item, index) => {
              return (
                <Fragment key={index}>
                  {isPad ? (
                    item?.label?.properties?.isHidden ? (
                      <div
                        style={calculatePatientLabelPadding(
                          item,
                          'label',
                          'paddingLeft',
                          'paddingRight',
                          'paddingTop',
                          'paddingBottom',
                          actualWidth,
                          totalPadding,
                          isPad,
                        )}
                      ></div>
                    ) : (
                      <div
                        style={calculatePatientLabelPadding(
                          item,
                          'label',
                          'paddingLeft',
                          'paddingRight',
                          'paddingTop',
                          'paddingBottom',
                          actualWidth,
                          totalPadding,
                          isPad,
                        )}
                      >
                        {item?.label?.labelName}:
                      </div>
                    )
                  ) : (
                    ''
                  )}
                  {isPad ? (
                    <div
                      className="p-item-val"
                      style={
                        isPad
                          ? calculatePatientLabelPadding(
                              item,
                              'value',
                              'paddingLeft',
                              'paddingRight',
                              'paddingTop',
                              'paddingBottom',
                              actualWidth,
                              totalPadding,
                              isPad,
                            )
                          : {}
                      }
                    >
                      {item.name === 'age'
                        ? agePrint(dob, item?.unitProperties)
                        : item.name === 'date'
                        ? appointmentDate()
                        : item.name === 'weight' || item.name === 'height'
                        ? printWeightHeight(item.name)
                        : item.name === 'gender'
                        ? capitalizeFirstLetter(patientInfo[item.name])
                        : item.name === 'address'
                        ? patientInfo?.patientDetails?.address
                        : item.name === 'referenceBy'
                        ? patientInfo?.patientDetails?.reference_by
                        : item.name === 'bloodGroup'
                        ? selectedOnExamination?.generalExaminations?.bloodGroup
                        : patientInfo[item.name]}
                    </div>
                  ) : (
                    <div className="n">
                      {item?.label?.labelName}: &nbsp;{' '}
                      {item.name === 'age'
                        ? agePrint(dob, item?.unitProperties)
                        : item.name === 'date'
                        ? appointmentDate()
                        : item.name === 'weight' || item.name === 'height'
                        ? printWeightHeight(item.name)
                        : item.name === 'gender'
                        ? capitalizeFirstLetter(patientInfo[item.name])
                        : item.name === 'address'
                        ? patientInfo?.patientDetails?.address
                        : item.name === 'referenceBy'
                        ? patientInfo?.patientDetails?.reference_by
                        : item.name === 'bloodGroup'
                        ? selectedOnExamination?.generalExaminations?.bloodGroup
                        : patientInfo[item.name]}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </Row>
        )}
      <Row className="patient-section mb-4">
        <div className="form-group form-inline">
          <label htmlFor="name">Name:</label>
          <Form.Control
            type="text"
            className={`name ${name ? '' : 'placeholder'}`}
            placeholder="Enter your patient name"
            value={name}
            onChange={(e) => handlePatientInput('name', e.target.value)}
          />
        </div>
        <div className="form-group form-inline">
          <label htmlFor="name">Age:</label>
          <InputGroup className="age-input-group">
            {(years?.enabled || !unitProperties) && (
              <>
                <Form.Control
                  size="sm"
                  type="number"
                  className={dob?.years ? '' : 'placeholder'}
                  defaultValue={dob?.years > 0 ? dob?.years : ''}
                  value={dob?.years > 0 ? dob?.years : ''}
                  placeholder="Yrs"
                  onChange={(e) => handleAge('years', e.target.value)}
                />
                {dob?.years > 0 && <span>{years?.label}</span>}
              </>
            )}
            {months?.enabled && (
              <>
                <Form.Control
                  size="sm"
                  type="number"
                  className={dob?.months ? '' : 'placeholder'}
                  defaultValue={dob?.months > 0 ? dob?.months : ''}
                  value={dob?.months > 0 ? dob?.months : ''}
                  placeholder="Mth"
                  onChange={(e) => handleAge('months', e.target.value)}
                />
                {dob?.months > 0 && <span>{months?.label}</span>}
              </>
            )}
            {days?.enabled && (
              <>
                <Form.Control
                  size="sm"
                  type="number"
                  className={dob?.days ? '' : 'placeholder'}
                  defaultValue={dob?.days > 0 ? dob?.days : ''}
                  value={dob?.days > 0 ? dob?.days : ''}
                  placeholder="Day"
                  onChange={(e) => handleAge('days', e.target.value)}
                />
                {dob?.days > 0 && <span>{days?.label}</span>}
              </>
            )}
            {hours?.enabled && (
              <>
                <Form.Control
                  size="sm"
                  type="number"
                  className={dob?.hours ? '' : 'placeholder'}
                  placeholder="Hr"
                  defaultValue={dob?.hours > 0 ? dob?.hours : ''}
                  value={dob?.hours > 0 ? dob?.hours : ''}
                  onChange={(e) => handleAge('hours', e.target.value)}
                />
                {dob?.hours > 0 && <span>{hours?.label}</span>}
              </>
            )}
          </InputGroup>
        </div>
        <div className="form-group form-inline">
          <label htmlFor="gender">Gender:</label>
          <Form.Select
            className={`form-control ${gender ? '' : 'placeholder'}`}
            size="sm"
            value={gender}
            onChange={(e) => handlePatientInput('gender', e.target.value)}
          >
            <option value={'male'}>Male</option>
            <option value={'female'}>Female</option>
            <option value={'others'}>Others</option>
          </Form.Select>
        </div>
        <div className="form-group form-inline">
          <label htmlFor="gender">Phone:</label>
          <Form.Control
            type="text"
            className={`phone-number ${phoneNumber ? '' : 'placeholder'}`}
            placeholder="01XXXXXXXXX"
            value={phoneNumber}
            onChange={(e) => handlePatientInput('phoneNumber', e.target.value)}
          />
        </div>
        <div className="form-group form-inline">
          <span className="add-more-info" onClick={() => setInfoModal(true)}>
            <i className="fa fa-plus-circle" aria-hidden="true"></i>
            &nbsp;<span className="add-more-sm">Add More</span>
            <span className="add-more-lg">Add Patient Information</span>
          </span>
          <label htmlFor="date" className="date-label">
            Date:
          </label>
          <span
            className="pl-1 date-field"
            style={{ fontWeight: 'normal', color: '#6e707e' }}
          >
            {moment().format('DD-MM-YYYY')}
          </span>
        </div>
      </Row>

      <AddPatientInfo
        patientPicture={patientPicture}
        patientInfo={patientInfo}
        setPatientInfo={setPatientInfo}
        infoModal={infoModal}
        setInfoModal={setInfoModal}
        selectedOnExamination={selectedOnExamination}
        setSelectedOnExamination={setSelectedOnExamination}
      />
    </>
  );
};

export default memo(PatientSection);
