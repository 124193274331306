import { memo } from 'react';

const CreateBtn = ({ clickAction, itemNewData }) => {
  return (
    <span
      className="new-rx-btn"
      {...(itemNewData?.name
        ? {
            onClick: () => clickAction(itemNewData),
          }
        : {})}
    >
      <i className="fa fa-plus-circle" aria-hidden="true"></i> Create
    </span>
  );
};

export default memo(CreateBtn);
