import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import {
  pagePrintStyle,
  leftOrRightItems,
  isExistAnyHistory,
  isExistAnyEyeGlass,
  leftSidePrintStyle,
  isExistAnyRehabData,
  rightSidePrintStyle,
  ageConvertToDateTime,
  isExistAnyOnExamination,
  formatOldPrescriptionData,
  isExistAnyInfertilityData,
} from '../../utils/helpers';
import '../../print.css';
import { useGetRequest } from '../../hooks/useGetRequest';
import PrescriptionHeader from '../../components/doctors/pdfPrescriptionHeader';
import PrescriptionFooter from '../../components/doctors/pdfPrescriptionFooter';
import PatientSection from '../../components/doctors/pdfPatientSection';
import ChiefComplainView from '../../components/doctors/partials/views/chiefComplain';
import RxView from '../../components/doctors/partials/rxView';
import DiagnosisView from '../../components/doctors/partials/diagnosisView';
import EyeGlassView from '../../components/doctors/partials/views/eyeGlassView';
import HistoryView from '../../components/doctors/partials/views/historyView';
import RehabilitationView from '../../components/doctors/partials/views/rehabilitationView';
import InfertilityView from '../../components/doctors/partials/views/infertility';
import InvestigationView from '../../components/doctors/partials/views/investigation';
import AdviceView from '../../components/doctors/partials/views/advice';
import FollowUpView from '../../components/doctors/partials/views/followUp';
import SpecialNoteView from '../../components/doctors/partials/views/specialNoteView';
import SignatureView from '../../components/doctors/pdfSignature';
import OnExaminationView from '../../components/doctors/partials/views/onExamination';

const PdfPrescription = ({}) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const organizationId = urlSearchParams.get('organizationId');
  const doctorId = urlSearchParams.get('doctorId');
  const prescriptionId = urlSearchParams.get('prescriptionId');
  const [instructions, setInstructions] = useState([]);
  const [selectedChiefComplains, setSelectedChiefComplains] = useState([]);
  const [selectedAdvices, setSelectedAdvices] = useState([]);
  const [selectedSpecialNotes, setSelectedSpecialNotes] = useState([]);
  const [selectedInfertilities, setSelectedInfertilities] = useState({});
  const [selectedRehabilitation, setSelectedRehabilitation] = useState({
    orthoses: [],
    exercises: [],
    physicalTherapies: [],
  });
  const [selectedFollowUps, setSelectedFollowUps] = useState([]);
  const [selectedEyeGlass, setSelectedEyeGlass] = useState({
    isPlano: false,
    leftPlano: false,
    rightPlano: false,
    r: { sph: '', cyl: '', axis: '' },
    l: { sph: '', cyl: '', axis: '' },
    remarks: [],
  });

  const [selectedInvestigations, setSelectedInvestigations] = useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = useState([]);
  const [selectedStage, setSelectedStage] = useState({});
  const [selectedHistories, setSelectedHistories] = useState({});
  const [selectedOnExamination, setSelectedOnExamination] = useState({});
  const [editMedicineId, setEditMedicineId] = useState('');
  const [selectedMedicines, setSelectedMedicines] = useState([]);
  const [patientInfo, setPatientInfo] = useState({});
  const [headerInfo, setHeaderInfo] = useState(null);
  const [footerInfo, setFooterInfo] = useState(null);
  const [prescriptionItems, setPrescriptionItems] = useState(null);
  const [prescriptionSetting, setPrescriptionSetting] = useState(null);
  const [patientItemSettings, setPatientItemSettings] = useState(null);
  const [doctorSignature, setDoctorSignature] = useState(null);
  const [isPad, setIsPad] = useState(false);
  const [note, setNote] = useState('');

  useEffect(() => {
    getPrescriptionData();
    getHeaderData();
    getFooterData();
    getPrescriptionItemData();
    getPrescriptionPrintData();
    getPatientItemSettings();
    getDoctorSignature();
  }, []);

  const { isLoading: isGetImageLoading, refetch: getDoctorSignature } =
    useGetRequest(
      `getSignatureContent}`,
      `pdf-settings/signature-content/${doctorId}`,
      (data) => {
        setDoctorSignature(data);
      },
      (e) => {
        console.log(e);
      },
    );

  const { isLoading: isPatientItemSettings, refetch: getPatientItemSettings } =
    useGetRequest(
      `getPatientItemSettings`,
      `pdf-settings/prescription-patient-item-setting?organizationId=${organizationId}&doctorId=${doctorId}`,
      (data) => {
        setPatientItemSettings(data);
      },
      (error) => {
        console.log(error, 'error');
      },
    );

  const { isLoading: isPrescriptionPrint, refetch: getPrescriptionPrintData } =
    useGetRequest(
      `getPrescriptionPrintData`,
      `pdf-settings/prescription-print-setting?organizationId=${organizationId}&doctorId=${doctorId}`,
      (data) => {
        setPrescriptionSetting(data);
        //setIsPad(prescriptionSetting?.isPadPrescription);
      },
      (error) => {
        console.log(error, 'error');
      },
    );

  const { isLoading: isPrescriptionItems, refetch: getPrescriptionItemData } =
    useGetRequest(
      `getPrescriptionItemData`,
      `pdf-settings/prescription-item-setting?organizationId=${organizationId}&doctorId=${doctorId}`,
      (data) => {
        setPrescriptionItems(data);
      },
      (error) => {
        console.log(error, 'error');
      },
    );

  const { isLoading: isHeader, refetch: getHeaderData } = useGetRequest(
    `getHeaderData`,
    `pdf-settings/prescription-header?organizationId=${organizationId}&doctorId=${doctorId}`,
    (data) => {
      setHeaderInfo(data);
    },
    (error) => {
      console.log(error, 'error');
    },
  );

  const { isLoading: isFooter, refetch: getFooterData } = useGetRequest(
    `getFooterData`,
    `pdf-settings/prescription-footer?organizationId=${organizationId}&doctorId=${doctorId}`,
    (data) => {
      setFooterInfo(data);
    },
    (error) => {
      console.log(error, 'error');
    },
  );

  const { isLoading: isPrescription, refetch: getPrescriptionData } =
    useGetRequest(
      `getPatientLastPrescription`,
      `prescriptions/${prescriptionId}`,
      (data) => {
        setPatientInfo(data?.patientId);
        setPrescriptionData(data);
        setNote(data?.note?.note);
      },
      (error) => {
        console.log(error, 'error');
      },
    );

  const setPrescriptionData = (prescription) => {
    prescription?.chiefComplains
      ? setSelectedChiefComplains([...prescription?.chiefComplains])
      : setSelectedChiefComplains([]);
    prescription?.diagnoses
      ? setSelectedDiagnosis([...prescription?.diagnoses])
      : setSelectedDiagnosis([]);
    prescription?.staging
      ? setSelectedStage({ ...prescription?.staging })
      : setSelectedStage({});
    prescription?.advices
      ? setSelectedAdvices([...prescription?.advices])
      : setSelectedAdvices([]);
    prescription?.followUps
      ? setSelectedFollowUps([...prescription?.followUps])
      : setSelectedFollowUps([]);
    prescription?.medicines
      ? setSelectedMedicines([
          ...formatOldPrescriptionData(prescription?.medicines),
        ])
      : setSelectedMedicines([]);
    prescription?.histories
      ? setSelectedHistories({ ...prescription?.histories })
      : setSelectedHistories({});
    prescription?.onExaminations
      ? setSelectedOnExamination({ ...prescription?.onExaminations })
      : setSelectedOnExamination({});
    prescription?.infertilities
      ? setSelectedInfertilities({ ...prescription?.infertilities })
      : setSelectedInfertilities({});
    prescription?.investigations
      ? setSelectedInvestigations([...prescription?.investigations])
      : setSelectedInvestigations([]);
    prescription?.eyeGlass
      ? setSelectedEyeGlass({ ...prescription?.eyeGlass })
      : setSelectedEyeGlass({});
    prescription?.rehabilitation
      ? setSelectedRehabilitation({ ...prescription?.rehabilitation })
      : setSelectedRehabilitation({});
    prescription?.specialNotes
      ? setSelectedSpecialNotes([...prescription?.specialNotes])
      : setSelectedSpecialNotes([]);
  };

  const actualWidth = prescriptionSetting?.page?.width?.quantity;

  const chiefComplainView = (element) => {
    if (selectedChiefComplains.length === 0) {
      return false;
    }
    return (
      <ChiefComplainView
        element={element}
        key={'chiefComplain'}
        prescriptionItems={prescriptionItems}
        selectedChiefComplains={selectedChiefComplains}
      />
    );
  };

  const historyView = (element) => {
    if (!isExistAnyHistory(selectedHistories)) {
      return false;
    }
    return (
      <HistoryView
        element={element}
        key={'history'}
        selectedHistories={selectedHistories}
        setSelectedHistories={setSelectedHistories}
        prescriptionItems={prescriptionItems}
      />
    );
  };

  const onExaminationView = (element) => {
    if (!isExistAnyOnExamination(selectedOnExamination)) {
      return false;
    }
    return (
      <OnExaminationView
        element={element}
        key={'onExamination'}
        selectedOnExamination={selectedOnExamination}
        prescriptionItems={prescriptionItems}
        setSelectedOnExamination={setSelectedOnExamination}
      />
    );
  };

  const investigationView = (element) => {
    if (selectedInvestigations.length === 0) {
      return false;
    }
    return (
      <InvestigationView
        element={element}
        key={'investigation'}
        prescriptionItems={prescriptionItems}
        selectedInvestigations={selectedInvestigations}
      />
    );
  };

  const adviceView = (element) => {
    if (selectedAdvices.length === 0) {
      return false;
    }
    return (
      <AdviceView
        element={element}
        key={'advice'}
        prescriptionItems={prescriptionItems}
        selectedAdvices={selectedAdvices}
        setSelectedAdvices={setSelectedAdvices}
      />
    );
  };

  const followUpView = (element) => {
    if (selectedFollowUps.length === 0) {
      return false;
    }
    return (
      <FollowUpView
        element={element}
        key={'followUp'}
        prescriptionItems={prescriptionItems}
        selectedFollowUps={selectedFollowUps}
      />
    );
  };

  const eyeGlassView = (element) => {
    if (!isExistAnyEyeGlass(selectedEyeGlass)) {
      return false;
    }
    return (
      <EyeGlassView
        selectedEyeGlass={selectedEyeGlass}
        setSelectedEyeGlass={setSelectedEyeGlass}
        element={element}
        key={'eyeGlass'}
      />
    );
  };

  const viewDiagnosis = (element) => {
    if (selectedDiagnosis.length === 0 && !selectedStage?.isShow) {
      return false;
    }
    return (
      <DiagnosisView
        key={'diagnosis'}
        selectedDiagnosis={selectedDiagnosis}
        prescriptionItems={prescriptionItems}
        selectedStage={selectedStage}
        element={element}
      />
    );
  };

  const viewRehabilitation = (element) => {
    if (!isExistAnyRehabData(selectedRehabilitation)) {
      return false;
    }
    return (
      <RehabilitationView
        selectedRehabilitation={selectedRehabilitation}
        setSelectedRehabilitation={setSelectedRehabilitation}
        element={element}
        key={'rehabilitation'}
      />
    );
  };

  const viewRx = (element) => {
    if (selectedMedicines.length === 0) {
      return false;
    }
    return (
      <RxView
        selectedMedicines={selectedMedicines}
        setSelectedMedicines={setSelectedMedicines}
        instructions={instructions}
        setInstructions={setInstructions}
        prescriptionItems={prescriptionItems}
        editMedicineId={editMedicineId}
        setEditMedicineId={setEditMedicineId}
        element={element}
        key={'rx'}
      />
    );
  };

  const infertilityView = (element) => {
    if (!isExistAnyInfertilityData(selectedInfertilities)) {
      return false;
    }
    return (
      <InfertilityView
        element={element}
        key={'infertility'}
        selectedInfertilities={selectedInfertilities}
        setSelectedInfertilities={setSelectedInfertilities}
      />
    );
  };

  const specialNoteView = (element) => {
    if (selectedSpecialNotes.length === 0) {
      return false;
    }
    return (
      <SpecialNoteView
        selectedSpecialNotes={selectedSpecialNotes}
        setSelectedSpecialNotes={setSelectedSpecialNotes}
        prescriptionItems={prescriptionItems}
        element={element}
        key={'specialNote'}
      />
    );
  };

  const signatureView = () => {
    const { doctorName, signatureContent } = doctorSignature ?? {};
    if (!doctorName && !signatureContent) {
      return false;
    }
    return (
      <SignatureView
        key={'signature'}
        imageContent={signatureContent}
        doctorName={doctorName}
      />
    );
  };

  return (
    <div className="container-fluid pdf-prescription">
      <Row className="rx-body">
        <div className="col-xl-12 col-md-12 col-lg-12">
          <div className="card mb-4">
            <div
              className={`
                  card-body print-card-body pdf
                  ${isPad ? '' : 'blank-prescription-card-body'}`}
              style={
                isPad ? pagePrintStyle(prescriptionSetting, actualWidth) : {}
              }
            >
              <PrescriptionHeader
                headerInfo={headerInfo}
                prescriptionSetting={prescriptionSetting}
              />
              <PatientSection
                patientInfo={patientInfo}
                patientSettings={patientItemSettings}
                prescriptionSetting={prescriptionSetting}
                actualWidth={actualWidth}
                isPad={isPad}
              />
              <div className="row prescription-body-print">
                {leftOrRightItems(prescriptionItems, 'left').length > 0 && (
                  <div
                    style={leftSidePrintStyle(isPad, prescriptionSetting)}
                    className={`prescription-body-left
                        ${isPad ? '' : 'blank-prescription-print-left'}`}
                  >
                    {leftOrRightItems(prescriptionItems, 'left').map(
                      (element) => {
                        if (element.name === 'chief-complain') {
                          return chiefComplainView(element);
                        } else if (element.name === 'history') {
                          return historyView(element);
                        } else if (element.name === 'on-examination') {
                          return onExaminationView(element);
                        } else if (element.name === 'diagnosis') {
                          return viewDiagnosis(element);
                        } else if (element.name === 'investigation') {
                          return investigationView(element);
                        } else if (element.name === 'rx') {
                          return viewRx(element);
                        } else if (element.name === 'rehabilitation') {
                          return viewRehabilitation(element);
                        } else if (element.name === 'advice') {
                          return adviceView(element);
                        } else if (element.name === 'follow-up') {
                          return followUpView(element);
                        } else if (element.name === 'eye-glass') {
                          return eyeGlassView(element);
                        } else if (element.name === 'infertility') {
                          return infertilityView(element);
                        } else if (element.name === 'special-note') {
                          return specialNoteView(element);
                        } else if (element.name === 'signature') {
                          return signatureView(element);
                        }
                      },
                    )}
                  </div>
                )}
                {leftOrRightItems(prescriptionItems, 'right').length > 0 && (
                  <div
                    style={
                      isPad ? rightSidePrintStyle(prescriptionSetting) : {}
                    }
                    className={`prescription-body-right
                            ${isPad ? '' : 'blank-prescription-print-right'}`}
                  >
                    {leftOrRightItems(prescriptionItems, 'right').map(
                      (element) => {
                        if (element.name === 'chief-complain') {
                          return chiefComplainView(element);
                        } else if (element.name === 'history') {
                          return historyView(element);
                        } else if (element.name === 'on-examination') {
                          return onExaminationView(element);
                        } else if (element.name === 'diagnosis') {
                          return viewDiagnosis(element);
                        } else if (element.name === 'investigation') {
                          return investigationView(element);
                        } else if (element.name === 'rx') {
                          return viewRx(element);
                        } else if (element.name === 'rehabilitation') {
                          return viewRehabilitation(element);
                        } else if (element.name === 'advice') {
                          return adviceView(element);
                        } else if (element.name === 'follow-up') {
                          return followUpView(element);
                        } else if (element.name === 'eye-glass') {
                          return eyeGlassView(element);
                        } else if (element.name === 'infertility') {
                          return infertilityView(element);
                        } else if (element.name === 'special-note') {
                          return specialNoteView(element);
                        } else if (element.name === 'signature') {
                          return signatureView(element);
                        }
                      },
                    )}
                  </div>
                )}
              </div>
              <PrescriptionFooter
                footerInfo={footerInfo}
                prescriptionSetting={prescriptionSetting}
              />
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default PdfPrescription;
