import { memo, useState } from 'react';
import PrescriptionSubItemSuggestions from '../prescriptionSubItemSuggestions';

const ItemWithDeleteIcon = ({
  item,
  isSelected,
  // removeClickAction,
  itemClickAction,
  itemType,
  isSpecialNote = false,
  suggestions,
  selectItemWithNote,
}) => {
  // const [hoverClass, setHoverClass] = useState('complain-remove-btn');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);

  // Function to handle toggle
  const handleToggle = (isOpen) => {
    setIsSubItemSuggestionOpen(isOpen);
  };

  // Programmatically open dropdown
  const openDropdown = () => {
    // setIsSubItemSuggestionOpen(true);
    setIsSubItemSuggestionOpen(!isSubItemSuggestionOpen);
  };

  // Programmatically close dropdown
  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  // Handle item selection
  const handleSelect = (result) => {
    console.log(`Selected item key: ${result}`);
    item['note'] = result;
    selectItemWithNote(item);
  };

  return (
    <>
      <div
        className={`chief-complain-item mp-20 ${
          isSelected ? 'is-selected' : ''
        }`}
        key={item.id}
        // onMouseOver={() => {
        //   setHoverClass('complain-remove-btn-hover');
        // }}
        // onMouseOut={() => {
        //   setHoverClass('complain-remove-btn');
        // }}
        style={{ paddingRight: suggestions?.length > 0 ? '0px' : '6px' }}
      >
        <span
          className="cursor-pointer"
          onClick={() => itemClickAction(item, itemType)}
        >
          {isSpecialNote ? item.groupName : item.name}
        </span>

        {suggestions?.length > 0 && (
          <PrescriptionSubItemSuggestions
            openDropdown={openDropdown}
            isSubItemSuggestionOpen={isSubItemSuggestionOpen}
            handleSelect={handleSelect}
            closeDropdown={closeDropdown}
            suggestions={suggestions}
            itemName={item.name}
          />
        )}
        {/* <NavDropdown
          title={
            <span>
              <i
                className="fa fa-angle-down"
                style={{
                  color: '#00b38c',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                }}
                onClick={openDropdown}
              />
            </span>
          }
          show={isSubItemSuggestionOpen}
          // onToggle={handleToggle}
          className="subitem-dropdown"
          style={{
            display: 'inline-block',
          }}
        >
          <NavDropdown.Item className="suggestionTitle">
            Suggestions
          </NavDropdown.Item>

          <div className="subitems">
            <NavDropdown.Item
              className="suggestionItem"
              onClick={(e) => {
                handleSelect(e, 'Action');
              }}
            >
              Action
            </NavDropdown.Item>
            <NavDropdown.Item
              className="suggestionItem"
              onClick={(e) => {
                handleSelect(e, 'Another action');
              }}
            >
              Another action
            </NavDropdown.Item>
            <NavDropdown.Item
              className="suggestionItem"
              onClick={(e) => {
                handleSelect(e, 'Action');
              }}
            >
              Action1
            </NavDropdown.Item>
            <NavDropdown.Item
              className="suggestionItem"
              onClick={(e) => {
                handleSelect(e, 'Another action');
              }}
            >
              Another action1
            </NavDropdown.Item>
          </div>
          <NavDropdown.Item className="suggestionLoadMore">
            Load More
          </NavDropdown.Item>
        </NavDropdown> */}

        {/* <span
          className={hoverClass}
          onClick={() => deleteConfirmation(removeClickAction, item.id)}
        >
          <i
            className="fa fa-minus-circle"
            aria-hidden="true"
            style={{ fontSize: '14px' }}
          ></i>
        </span> */}
      </div>
    </>
  );
};

export default memo(ItemWithDeleteIcon);
