import React, { memo } from 'react';
import SignatureImageView from './partials/views/signatureImageView';

const PdfSignature = ({ imageContent, doctorName }) => {
  return (
    <div className="prescription-signature-view">
      <div>
        {imageContent && (
          <SignatureImageView
            signature={imageContent}
            height={80}
            width={280}
          />
        )}
      </div>
      {doctorName && <div className="text-center mt-2">{doctorName}</div>}
    </div>
  );
};

export default memo(PdfSignature);
