import { Fragment, memo, useState, useRef, useEffect } from 'react';
import { Dropdown, NavDropdown } from 'react-bootstrap';
import { useClickAway } from 'react-use';

const SearchItemSuggestions = ({
  openDropdown,
  isSubItemSuggestionOpen,
  handleSelect,
  closeDropdown,
  suggestions,
  itemName,
  forSearch,
  suggetionMenuTop,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [searchText, setSearchText] = useState('');

  console.log('suggestion: ', suggestions);

  const ref = useRef(null);
  useClickAway(ref, () => {
    closeDropdown();
  });

  useEffect(() => {
    suggestionsToFilterSuggestions();
  }, [suggestions, itemName]);

  useEffect(() => {
    if (searchText) {
      const matchedData = filteredSuggestions.filter((item) =>
        item.includes(searchText),
      );
      setFilteredSuggestions([...matchedData]);
    } else {
      suggestionsToFilterSuggestions();
    }
  }, [searchText]);

  const suggestionsToFilterSuggestions = () => {
    const specificItemSuggestions = suggestions.find(
      (suggestionItem) => suggestionItem._id === itemName,
    );
    const orderedSuggestions = specificItemSuggestions
      ? specificItemSuggestions.notes?.sort()
      : [];
    setFilteredSuggestions([...orderedSuggestions]);
  };

  // const arrowClass = forSearch ? 'fa fa-angle-right' : 'fa fa-angle-down';
  const arrowClass = forSearch ? '' : 'fa fa-angle-down';

  if (!isSubItemSuggestionOpen) {
    return '';
  }
  return (
    <Dropdown
      ref={ref}
      style={{
        top: `${suggetionMenuTop}rem`,
      }}
      title={<span>{itemName}</span>}
      // onToggle={handleToggle}
      className="subitem-dropdown search-suggestion"
    >
      <NavDropdown.Item className="suggestionTitle">
        {/* {filteredSuggestions?.length > 0 ? (
          <input
            type="text"
            style={{ width: '100%' }}
            name="Suggestion"
            placeholder="Find Suggestion"
            onChange={(e) => setSearchText(e.target.value)}
          />
        ) : (
          'Suggestions'
        )} */}
        {itemName}
      </NavDropdown.Item>

      {filteredSuggestions?.length === 0 && (
        <NavDropdown.Item>No data found</NavDropdown.Item>
      )}

      <div className="subitems">
        {filteredSuggestions.map((sugItem, index) => (
          <NavDropdown.Item
            key={`chief-${index}`}
            className="suggestionItem"
            onClick={(e) => {
              console.log('clicked on sug item......');
              handleSelect(sugItem, itemName);
              closeDropdown();
            }}
          >
            {sugItem}
          </NavDropdown.Item>
        ))}
      </div>
    </Dropdown>
  );
};

export default memo(SearchItemSuggestions);
