import { memo } from 'react';
import { Row, Col, Nav } from 'react-bootstrap';

const DiagnosisTabNav = ({
  selectedItems,
  setSelectedDiagnosisTab,
  action,
  type,
}) => {
  return (
    <Nav
      variant="pills"
      className="d-flex custom-tab"
      style={{ alignItems: 'center', textAlign: 'center' }}
    >
      <Nav.Item className="flex-fill">
        <Nav.Link eventKey="all" onClick={() => setSelectedDiagnosisTab('all')}>
          All
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className="flex-fill">
        <Nav.Link
          eventKey="group"
          onClick={() => setSelectedDiagnosisTab('group')}
        >
          Group
        </Nav.Link>
      </Nav.Item>
      {type === 'staging' && (
        <Nav.Item className="flex-fill">
          <Nav.Link
            eventKey="staging"
            onClick={() => setSelectedDiagnosisTab('staging')}
          >
            Staging
          </Nav.Link>
        </Nav.Item>
      )}
      {selectedItems.length > 1 && (
        <Nav.Item className="flex-fil save-btn-area">
          <i
            className="fa fa-save"
            aria-hidden="true"
            onClick={() => action(true)}
          ></i>
        </Nav.Item>
      )}
    </Nav>
  );
  // return (
  //   <Nav variant="pills" className="custom-tab">
  //     <Row style={{ display: 'contents' }}>
  //       <Col>
  //         <Nav.Item>
  //           <Nav.Link
  //             eventKey="all"
  //             onClick={() => setSelectedDiagnosisTab('all')}
  //           >
  //             All
  //           </Nav.Link>
  //         </Nav.Item>
  //       </Col>
  //       <Col>
  //         <Nav.Item>
  //           <Nav.Link
  //             eventKey="group"
  //             onClick={() => setSelectedDiagnosisTab('group')}
  //           >
  //             Group
  //           </Nav.Link>
  //         </Nav.Item>
  //       </Col>
  //       {type === 'staging' && (
  //         <Col>
  //           <Nav.Item>
  //             <Nav.Link
  //               eventKey="staging"
  //               onClick={() => setSelectedDiagnosisTab('staging')}
  //             >
  //               Staging
  //             </Nav.Link>
  //           </Nav.Item>
  //         </Col>
  //       )}
  //       {selectedItems.length > 1 && (
  //         <Col md={1} className="text-right">
  //           <i
  //             className="fa fa-save"
  //             aria-hidden="true"
  //             onClick={() => action(true)}
  //           ></i>
  //         </Col>
  //       )}
  //     </Row>
  //   </Nav>
  // );
};

export default memo(DiagnosisTabNav);
