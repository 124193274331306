import React, { useEffect, useCallback, useContext } from 'react';
import Header from '../../components/doctors/partials/Header';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function VideoCall() {
  //AS OF NOW DOMAIN WOULD BE JITSI'S AS WE ARE STILL USING THIER SERVERS
  // const domain = "meet.jit.si";
  const domain = 'vchat.scholars-home.org';
  let api = {};

  const navigate = useNavigate();
  const name = 'Jakir';

  // INTIALISE THE MEET WITH THIS FUNCTION
  const startMeet = useCallback(() => {
    const options = {
      roomName: 'TestRoomName',
      width: '100%',
      height: 500,
      configOverwrite: { prejoinPageEnabled: false },
      interfaceConfigOverwrite: {
        // overwrite interface properties if you want
      },
      // VIDEO FRAME WILL BE ADDED HERE
      parentNode: document.querySelector('#jitsi-iframe'),
      userInfo: {
        displayName: name,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    api = new window.JitsiMeetExternalAPI(domain, options);

    api.addEventListeners({
      readyToClose: handleClose,
      participantLeft: handleParticipantLeft,
      participantJoined: handleParticipantJoined,
      videoConferenceJoined: handleVideoConferenceJoined,
      videoConferenceLeft: handleVideoConferenceLeft,
    });
  }, [api]);

  // ALL OUR HANDLERS
  const handleClose = () => {
    console.log('handleClose');
  };

  const handleParticipantLeft = async (participant) => {
    console.log('handleParticipantLeft', participant);
    await getParticipants();
  };

  const handleParticipantJoined = async (participant) => {
    console.log('handleParticipantJoined', participant);
    await getParticipants();
  };

  const handleVideoConferenceJoined = async (participant) => {
    console.log('handleVideoConferenceJoined', participant);
    await getParticipants();
  };

  const handleVideoConferenceLeft = () => {
    console.log('handleVideoConferenceLeft');
    // navigate('/');
  };

  // GETTING ALL PARTICIPANTS
  const getParticipants = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(api.getParticipantsInfo());
      }, 500);
    });
  };

  useEffect(() => {
    if (window.JitsiMeetExternalAPI) {
      startMeet();
    } else {
      alert('JitsiMeetExternalAPI not loaded');
    }
  }, [startMeet]);

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <Row>
          <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card shadow mb-4 card-content">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Reports</h6>
              </div>
              {/* {isReportDataLoading && <p className="center mt-5">loading...</p>} */}

              <div className="card-body appointment-card-body">
                <Row>
                  <Col md={12} className="center">
                    <div id="jitsi-iframe" style={{ marginBottom: 0 }}></div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default VideoCall;
