import { useState, useEffect, memo } from 'react';
import { Row, Col } from 'react-bootstrap';
import parse from 'html-react-parser';
import { useGetRequest } from '../../hooks/useGetRequest';
import { defaultPatientPictureSettings } from '../../utils/helpers';

const PrescriptionHeader = ({
  patientId,
  patientPicture,
  headerInfo,
  prescriptionSetting,
}) => {
  //const [patientPicture, setPatientPicture] = useState(null);
  const { position, height, width, isPrint } = defaultPatientPictureSettings();

  let headerData = headerInfo?.contents?.map((data, index) => {
    return (
      <div style={{ width: data.width + '%' }} key={index}>
        {parse(data.content.replace(/<p>&nbsp;<\/p>/gi, ''))}
      </div>
    );
  });
  const prescriptionStyle = {
    height:
      prescriptionSetting?.header?.height?.quantity +
      prescriptionSetting?.header?.height?.unit,
  };

  return (
    <>
      <div className="show-in-print">
        {prescriptionSetting?.isPadPrescription ? (
          <Row className="d-flex" style={prescriptionStyle}></Row>
        ) : patientPicture && isPrint ? (
          <Row className="blank-header d-flex">
            {position === 'left' ? (
              <>
                <Col sm={2}>
                  <img
                    src={patientPicture}
                    height={height ?? 300}
                    width={width ?? 280}
                    className="patient-img"
                    alt="image"
                  />
                </Col>
                <Col
                  className="d-flex"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  {headerData}
                </Col>
              </>
            ) : (
              <>
                <Col
                  className="d-flex"
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                >
                  {headerData}
                </Col>
                <Col sm={2}>
                  <img
                    src={patientPicture}
                    height={height ?? 300}
                    width={width ?? 280}
                    className="patient-img"
                    alt="image"
                  />
                </Col>
              </>
            )}
          </Row>
        ) : (
          <Row className="blank-header d-flex">{headerData}</Row>
        )}
      </div>
      <Row className="hide-in-print header-no-print">{headerData}</Row>
    </>
  );
};

export default memo(PrescriptionHeader);
