import React from 'react'

function Page404() {
    return (
        <div>
            <h1>This is 404 page</h1>
        </div>
    )
}

export default Page404;