import React from 'react'

function Dashboard() {
    return (
        <div>
            <div className="container">
                <div className="row">
                    <div style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        fontWeight: "bold"
                      }}>
                    This is the dashboard</div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;