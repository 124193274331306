import React, { useEffect, useState } from 'react';
import { useGetRequest } from '../../../hooks/useGetRequest';
//import { RotatingLines } from 'react-loader-spinner';

const ImageItem = ({ patientId }) => {
  const [imageContent, setImageContent] = useState('');

  const { isLoading: isLoading, refetch: getProfilePicture } = useGetRequest(
    `get-patient-img-${patientId}`,
    `auth/patient-picture/${patientId}`,
    (data) => {
      setImageContent(data);
    },
    (e) => {
      console.log('error message: ', e.message);
    },
  );

  useEffect(() => {
    getProfilePicture();
  }, []);

  return (
    <div className="image-item-container">
      {imageContent && (
        <img
          src={imageContent}
          className="patient-pic rounded-circle"
          alt={`Picture`}
        />
      )}
    </div>
  );
};

export default ImageItem;
