import { memo } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const PersonalInfo = ({ selectedData, setSelectedHistories }) => {
  const personalHistoryData = (fieldName, val) => {
    if (fieldName === 'notes') {
      selectedData['personal'].notes = val;
    } else {
      selectedData['personal'][fieldName] = val ? true : false;
    }
    setSelectedHistories({ ...selectedData });
  };

  return (
    <>
      <Form.Group as={Row} className="mb-1 mt-1">
        <Col sm="2">
          <Form.Label className="font-weight-bold">P/H:</Form.Label>
        </Col>
        <Col sm="3">
          <Form.Check
            type={`checkbox`}
            id={`smoker`}
            label={`Smoker`}
            checked={selectedData?.personal?.smoker === true}
            onChange={(e) => personalHistoryData('smoker', e.target.checked)}
          />
        </Col>
        <Col sm="3">
          <Form.Check
            type={`checkbox`}
            id={`alcoholic`}
            label={`Alcoholic`}
            checked={selectedData?.personal?.alcoholic === true}
            onChange={(e) => personalHistoryData('alcoholic', e.target.checked)}
          />
        </Col>
        <Col sm="3">
          <Form.Check
            type={`checkbox`}
            id={`tobacco`}
            label={`Tobacco`}
            checked={selectedData?.personal?.tobacco === true}
            onChange={(e) => personalHistoryData('tobacco', e.target.checked)}
          />
        </Col>
      </Form.Group>
      <Row>
        <Col md="2" className="font-weight-bold mt-1">
          Note:
        </Col>
        <Col md="10">
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Add note"
            defaultValue={selectedData?.personal?.notes}
            onChange={(e) => personalHistoryData('notes', e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(PersonalInfo);
