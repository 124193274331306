import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { Typeahead, AsyncTypeahead } from 'react-bootstrap-typeahead';
import {
  MEDICINE_TYPES_SHORTS,
  countOccurrencesOf,
  sortMedicines,
} from '../../../../utils/helpers';
import { getRequest, postRequest } from '../../../../utils/axiosRequests';
import InvestigationUnit from '../../partials/investigationUnit';
import { useGetRequest } from '../../../../hooks/useGetRequest';

const InvestigationHistories = ({
  selectedData,
  deleteAction,
  setSelectedHistories,
  medicinesInSearch,
  handleOnInputChange,
}) => {
  const [investigationList, setInvestigationList] = useState([]);
  const [units, setUnits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const investigationRef = useRef(null);

  const typeaheadRef = useRef(null);
  const anotherRef = useRef(null);
  const filterBy = () => true;

  useEffect(() => {
    getInvestigationList();
    getUnits();
  }, []);

  const getInvestigationList = () => {
    getRequest('investigations')
      .then((data) => {
        setInvestigationList(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { isLoading: isUnitLoading, refetch: getUnits } = useGetRequest(
    'getUnitList',
    `units`,
    (data) => {
      setUnits(data.data);
    },
    (e) => {
      console.log(e);
    },
  );

  const mergeSelectedInv = (newData) => {
    const selectedInvestigate = [
      ...selectedData.investigations,
      { name: newData, result: '' },
    ];
    selectedData.investigations = selectedInvestigate;
    setSelectedHistories({ ...selectedData });
  };

  const handleInvestigation = (selectedOption) => {
    const selectedData = selectedOption[0]?.name;
    if (investigationList.some((item) => item.name === selectedData)) {
      if (
        !selectedData?.investigations?.some(
          (item) => item.name === selectedData,
        )
      ) {
        mergeSelectedInv(selectedData);
      }
    } else {
      postRequest('investigations', { name: selectedData })
        .then((data) => {
          mergeSelectedInv(selectedData);
          setInvestigationList([...investigationList, data]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    investigationRef?.current?.clear();
  };

  const handleInvestigationUnit = (data) => {
    console.log(data, 'sdfsghfsdhgf');
    // selectedData.investigations[index][fieldName] = val;
    // setSelectedHistories({ ...selectedData });
  };

  const selectedInvestigations = () => {
    return selectedData?.investigations?.map((item, index) => {
      return (
        <Row className={'mt-1'} key={index}>
          <Col md={3}>{item.name}</Col>
          <Col>
            <Form.Control
              size="sm"
              min={0}
              type="text"
              defaultValue={item.instruction}
              placeholder="Add instruction"
              onChange={(e) =>
                investigationData(index, 'instruction', e.target.value)
              }
            />
          </Col>
          <Col>
            <Form.Control
              rows={3}
              size="sm"
              placeholder="Add result"
              defaultValue={item?.result}
              onChange={(e) =>
                investigationData(index, 'result', e.target.value)
              }
            />
          </Col>
          <Col md={2} className="inv-parent pr-0">
            <InvestigationUnit
              index={index}
              units={units}
              item={item}
              selectedInvestigations={selectedData.investigations}
              action={handleInvestigationUnit}
              type={'history'}
            />
          </Col>
          <Col md={1} className="text-center">
            <span
              className="cursor-pointer"
              onClick={() => deleteAction('investigation', item)}
            >
              <i
                className="fa fa-times-circle pt-2"
                style={{ color: '#CB2020D9' }}
              ></i>
            </span>
          </Col>
        </Row>
      );
    });
  };

  const investigationData = (index, fieldName, val) => {
    selectedData.investigations[index][fieldName] = val;
    setSelectedHistories({ ...selectedData });
  };

  return (
    <Form.Group as={Row} className="mb-3">
      <Form.Label column sm="2" className="font-weight-bold">
        Investigations
      </Form.Label>
      <Col sm="10" className="mt-1">
        <Typeahead
          allowNew
          labelKey="name"
          ref={investigationRef}
          id="custom-selections-example"
          newSelectionPrefix="Click to add new: "
          options={investigationList}
          placeholder="Search / Add investigation here ..."
          onChange={handleInvestigation}
          size="sm"
          renderMenuItemChildren={(option, props) => (
            <Fragment>
              <span>{option.name}</span>
            </Fragment>
          )}
        />
        <div className="mt-3">{selectedInvestigations()}</div>
      </Col>
    </Form.Group>
  );
};

export default InvestigationHistories;
