import { memo, useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { itemSettingStyle, getDragDropStyle } from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';
import { Row, Col, Form } from 'react-bootstrap';
import { useClickAway } from 'react-use';

const Advice = ({
  element,
  prescriptionItems,
  setShowAdvice,
  selectedAdvices,
  setSelectedAdvices,
  deletePrescriptionItem,
  isHistoryPage = false,
}) => {
  const [isEdit, setIsEdit] = useState('');
  const [selectedForSub, setSelectedForSub] = useState('');
  let formatedAdvices = [];
  let key = 'untitled';
  selectedAdvices?.forEach((item) => {
    if (item?.title) {
      key = item?.title;
      formatedAdvices[key] = [item];
    } else {
      if (Array.isArray(formatedAdvices[key])) {
        formatedAdvices[key].push(item);
      } else {
        formatedAdvices[key] = [item];
      }
    }
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      selectedAdvices,
      result.source.index,
      result.destination.index,
    );
    setSelectedAdvices(reorderedItems);
  };

  // this function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const removeAdvice = (type, itemName) => {
    const data = selectedAdvices.filter(
      (selectedItem) => selectedItem.name !== itemName,
    );
    setSelectedAdvices([...data]);
    setSelectedForSub('');
  };

  const removeSubAdvice = (item, subItemId) => {
    let subItems = item?.subAdvices;
    subItems = subItems.filter((subItem) => subItem.id !== subItemId);
    item.subAdvices = subItems;
    const advices = selectedAdvices.map((advice) => {
      if (advice.id === item.id) {
        return item;
      } else {
        return advice;
      }
    });
    setSelectedAdvices([...advices]);
  };

  const handleSelectedForSub = (itemId) => {
    if (itemId === selectedForSub) {
      setSelectedForSub('');
    } else {
      setSelectedForSub(itemId);
    }
  };

  const handleAdviceNote = (key, val) => {
    selectedAdvices[key].note = val;
    setSelectedAdvices([...selectedAdvices]);
  };

  const handleAdviceSubNote = (itemMain, parentIndex, key, val) => {
    itemMain.subAdvices[key]['note'] = val;
    selectedAdvices[parentIndex] = itemMain;
    setSelectedAdvices([...selectedAdvices]);
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    setIsEdit('');
  });

  const selectedSubAdviceList = (itemMain, parentIndex) =>
    itemMain?.subAdvices?.map((item, index) => {
      console.log(itemMain, 'itemMain,');
      return (
        <Row
          className="selected-item-row"
          key={index}
          style={{ marginLeft: '20px', marginRight: '20px' }}
        >
          <Col>{item.name}</Col>
          <Col>
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(e) =>
                handleAdviceSubNote(
                  itemMain,
                  parentIndex,
                  index,
                  e.target.value,
                )
              }
            />
          </Col>
        </Row>
      );
    });

  const editMode = (item, index) => {
    return (
      <>
        {item?.title && (
          <span className="advice-title" key={index}>
            {item?.title}:{' '}
          </span>
        )}
        <Row ref={ref} className="selected-item-row" key={index}>
          <Col>{item.name}</Col>
          <Col>
            <Form.Control
              size="sm"
              type="text"
              defaultValue={item.note}
              placeholder="Enter note"
              onChange={(e) => handleAdviceNote(index, e.target.value)}
            />
          </Col>
          <Col md={1} className="pr-0">
            <span
              className="text-left"
              style={{
                width: '70%',
                display: 'inline-block',
                cursor: 'pointer',
              }}
            >
              {' '}
              {/* <i
                className={`fa fa-plus ${
                  selectedForSub === item.id ? 'dark' : 'grey'
                }`}
                aria-hidden="true"
                onClick={() => handleSelectedForSub(item.id)}
              ></i> */}
              <i
                className={`fa fa-plus ${
                  selectedForSub === item.id ? 'dark' : 'grey'
                }`}
                aria-hidden="true"
                onClick={() => handleSelectedForSub(item.id)}
              ></i>
            </span>
            <span
              className="text-right"
              style={{ width: '30%', display: 'inline-block' }}
            >
              {' '}
              <i
                className="fa fa-times-circle"
                aria-hidden="true"
                onClick={() => setIsEdit('')}
              ></i>
            </span>
          </Col>
        </Row>
        {selectedSubAdviceList(item, index)}
      </>
    );
  };

  const deleteAdvice = (itemName, parentName, type) => {
    let result = selectedAdvices;
    if (type === 'advice') {
      result = selectedAdvices.filter((advice) => advice.name !== itemName);
    } else {
      const index = selectedAdvices.findIndex(
        (advice) => advice.name === parentName,
      );
      if (index !== -1) {
        console.log(selectedAdvices[index], parentName, type);
        let subAdvs = selectedAdvices[index]?.subAdvices?.filter(
          (advice) => advice.name !== itemName,
        );
        result[index].subAdvices = subAdvs;
      }
    }
    setSelectedAdvices([...result]);
  };

  return (
    <div
      className={`row item-row ${
        selectedAdvices.length ? 'data-item-history' : 'no-data-item-history'
      }`}
      key={`advice`}
    >
      <span
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setShowAdvice(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span>Advice +</span>
        )}
      </span>
      {Object.keys(formatedAdvices)?.map((key) => {
        return (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <ol
                  className="sub-child-list advice-ul"
                  style={itemSettingStyle(
                    prescriptionItems?.items?.['advice']?.itemStyle || {},
                  )}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {formatedAdvices[key]?.map((item, index) => (
                    <Draggable
                      key={item.name}
                      draggableId={item.name}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <>
                          {item?.title && (
                            <span className="advice-title">
                              {item?.title}:{' '}
                            </span>
                          )}
                          <li
                            key={index}
                            className="advice-item"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getDragDropStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                            onClick={() => setIsEdit(index)}
                          >
                            <div>
                              <span
                                className="text-left pr-5"
                                style={itemSettingStyle(
                                  prescriptionItems?.items?.['advice']
                                    ?.itemStyle || {},
                                )}
                              >
                                {item.name}
                              </span>
                              {!isHistoryPage && (
                                <CommonDeleteBtn
                                  action={removeAdvice}
                                  itemName={'advice'}
                                  delId={item.name}
                                />
                              )}
                            </div>
                            {item.note && (
                              <div>
                                <span
                                  className="text-left pr-5"
                                  style={itemSettingStyle(
                                    prescriptionItems?.items?.['advice']
                                      ?.subItemStyle || {},
                                  )}
                                >
                                  {item.note}
                                </span>
                              </div>
                            )}
                            <ul>
                              {item?.subAdvices?.map((subAdvice, subIndex) => (
                                <li
                                  key={subAdvice.name}
                                  className="advice-item-sub"
                                >
                                  <div>
                                    <span
                                      className="text-left pr-5"
                                      style={itemSettingStyle(
                                        prescriptionItems?.items?.['advice']
                                          ?.itemStyle || {},
                                      )}
                                    >
                                      {subAdvice.name}
                                    </span>
                                    {!isHistoryPage && (
                                      <CommonDeleteBtn
                                        action={removeSubAdvice}
                                        itemName={item}
                                        delId={subAdvice.id}
                                      />
                                    )}
                                  </div>
                                  {subAdvice.note && (
                                    <div>
                                      <span
                                        className="text-left pr-5"
                                        style={itemSettingStyle(
                                          prescriptionItems?.items?.['advice']
                                            ?.subItemStyle || {},
                                        )}
                                      >
                                        <i>- {subAdvice.note}</i>
                                      </span>
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>
                          {index === isEdit && editMode(item, index)}
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ol>
              )}
            </Droppable>
          </DragDropContext>
        );
      })}
    </div>
  );
};

export default memo(Advice);
