import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import { memo, useState, useContext } from 'react';
import { getRequest } from '../../utils/axiosRequests';
import TemplateSearchArea from './partials/templateSearchArea';
import AddAttachmentModal from './modals/addAttachment';
import PrescriptionNoteModal from './modals/prescrptionNote';
import TopLeftMenu from './partials/topLeftMenu';
import { useSelector } from 'react-redux';
import { PrescriptionTopContext } from '../../contexts/PrescriptionTopContext';
import { usePostRequest } from '../../hooks/usePostRequest';

const PrescriptionTop = ({
  printRef,
  prescriptionId,
  title,
  setTitle,
  file,
  setFile,
  handlePrintStatus,
  note,
  setNote,
  setOpenVideoModal,
  onlineUserExist,
}) => {
  const { patientInfo } = useContext(PrescriptionTopContext);
  const userInfo = useSelector((state) => state.authReducer.data);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [templatesInSearch, setTemplatesInSearch] = useState([]);
  const [isNoteModal, setIsNoteModal] = useState(false);
  const [isAttachmentModal, setIsAttachmentModal] = useState(false);

  const handleOnInputChange = (searchKey) => {
    if (!searchKey || searchKey.length < 3) return;
    setIsLoading(true);
    const url = `prescriptions/templates-groups?name=${searchKey.toString()}`;
    setSearchQuery(searchKey.toString());

    getRequest(url)
      .then((data) => {
        let customizedResults = [];
        if (data?.prescriptionTemplates?.length > 0) {
          const templateData = data?.prescriptionTemplates?.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
              type: 'Prescription Template',
            };
          });
          customizedResults = [...customizedResults, ...templateData];
        }
        if (data?.chiefComplainGroups?.length > 0) {
          const complainData = data?.chiefComplainGroups?.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
              type: 'Chief complain group',
            };
          });
          customizedResults = [...customizedResults, ...complainData];
        }
        if (data?.adviceGroups?.length > 0) {
          const adviceData = data?.adviceGroups?.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
              type: 'Advice group',
            };
          });
          customizedResults = [...customizedResults, ...adviceData];
        }
        if (data?.diagnoisGroups?.length > 0) {
          const diagnosisData = data?.diagnoisGroups?.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
              type: 'Diagnosis group',
            };
          });
          customizedResults = [...customizedResults, ...diagnosisData];
        }
        if (data?.rxGroups?.length > 0) {
          const diagnosisData = data?.rxGroups?.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
              type: 'Medicine group',
            };
          });
          customizedResults = [...customizedResults, ...diagnosisData];
        }
        if (data?.investigationGroups?.length > 0) {
          const investigationData = data?.investigationGroups?.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
              type: 'Investigation group',
            };
          });
          customizedResults = [...customizedResults, ...investigationData];
        }
        if (data?.medicalGroups?.length > 0) {
          const medicalData = data?.medicalGroups?.map((item) => {
            return {
              ...item,
              label: item.name,
              value: item.name,
              type: 'Medical History group',
            };
          });
          customizedResults = [...customizedResults, ...medicalData];
        }
        if (data?.patientList?.length > 0) {
          const patientData = data?.patientList?.map((item) => {
            let patintExtraInfoToShow = '';
            const idForDoctor = item.patientIdForDoctor?.find(
              (idItem) => idItem?.doctorId === userInfo.id,
            );

            const patientId = idForDoctor ? idForDoctor.patientId : '';

            if (patientId) {
              patintExtraInfoToShow += patientId;
            }

            if (item?.phoneNumber) {
              patintExtraInfoToShow += ', ' + item.phoneNumber;
            }

            return {
              ...item,
              label: item.name,
              value: item.name + ' ,' + patintExtraInfoToShow,
              type: 'Patient',
              doctorId: userInfo.id,
              patientId,
            };
          });
          customizedResults = [...customizedResults, ...patientData];
        }
        setTemplatesInSearch([...customizedResults]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { isPushNotificationSendLoading, mutate: sendPushNotification } =
    usePostRequest(
      'auth-patient/send-push-notification',
      {
        patientId: patientInfo?.id,
        roomName: userInfo.id + '_' + patientInfo?.id,
      },
      (data) => {
        console.log(data);
      },
      (e) => {
        console.log(e);
      },
    );

  const callHandler = () => {
    sendPushNotification();
    setOpenVideoModal(true);
  };

  return (
    <div className="row mb-2">
      <div className="col-md-12 sticky-header shadow header-sticky-second">
        <TopLeftMenu />
        <div className="right-side-pres">
          {userInfo?.doctorDetails?.enabledOnline &&
            userInfo?.doctorDetails?.isVideoCall && (
              <>
                <div className="">
                  {onlineUserExist && <div className="patient_online"></div>}
                  {!onlineUserExist && <div className="patient_offline"></div>}
                </div>
                <div className="cursor-pointer">
                  <Link
                    to={''}
                    // className={appointmentStatus === 'completed' ? '' : 'disabled-link'}
                    style={{
                      // marginRight: '20px',
                      textDecoration: 'none',
                      color: 'rgb(20, 158, 39)',
                    }}
                    onClick={() => callHandler()}
                  >
                    <span className="take-note">Patient Call</span>
                    <span>
                      <i className="fa fa-phone"></i>
                    </span>
                  </Link>
                </div>
              </>
            )}

          <div className="cursor-pointer">
            {prescriptionId ? (
              <Link
                to={'/attachments'}
                state={{ patientId: patientInfo?.id, prescriptionId }}
              >
                <span className="take-note">Attachments</span>
                <span>
                  <i className="fa fa-paperclip"></i>
                </span>
              </Link>
            ) : (
              <div
                onClick={() => setIsAttachmentModal(true)}
                style={{ color: '#4e73df' }}
              >
                <span className="take-note">Attachments</span>
                <span>
                  <i className="fa fa-paperclip"></i>
                </span>
              </div>
            )}
          </div>
          <div
            className="cursor-pointer"
            onClick={() => setIsNoteModal(true)}
            style={{ color: '#3C6478' }}
          >
            <span className="take-note">Take Note</span>
            <span>
              <i className="fas fa-edit"></i>
            </span>
          </div>
          <div className="cursor-pointer" style={{ color: '#fa7f0b' }}>
            <ReactToPrint
              trigger={() => (
                <span className="print-preview">
                  <span className="take-note">Print Preview</span>
                  <i className="fa fa-print" aria-hidden="true"></i>
                </span>
              )}
              onBeforePrint={() => handlePrintStatus(true)}
              onAfterPrint={() => handlePrintStatus(false)}
              content={() => printRef.current}
            />
          </div>
          <div className="template-search">
            <TemplateSearchArea
              handleOnInputChange={handleOnInputChange}
              searchQuery={searchQuery}
              options={templatesInSearch}
              placeholder={'template'}
            />
          </div>
        </div>

        <PrescriptionNoteModal
          note={note}
          setNote={setNote}
          isNoteModal={isNoteModal}
          setIsNoteModal={setIsNoteModal}
        />
        <AddAttachmentModal
          isAttachmentModal={isAttachmentModal}
          setIsAttachmentModal={setIsAttachmentModal}
          title={title}
          setTitle={setTitle}
          file={file}
          setFile={setFile}
        />
      </div>
    </div>
  );
};

export default memo(PrescriptionTop);
