import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { medicineType } from '../../../utils/helpers';
import { memo } from 'react';
import MedicineSubItemSuggestions from '../medicineSubItemSuggestions';
import { useGetRequest } from '../../../hooks/useGetRequest';

const ItemMedicineWithDeleteIcon = ({
  item,
  isSelected,
  removeMedicine,
  itemClickAction,
  showMedicineName,
  selectMedicines,
}) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const [hoverClass, setHoverClass] = useState('complain-remove-btn');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [schedules, setSchedules] = useState([]);

  const { isLoading: isLoadingMedicineDefault, refetch: getMedicineDefault } =
    useGetRequest(
      `getMedicineDefault-${item?.id}`,
      `medicine-default?medicineId=${item?.id}`,
      (data) => {
        if (data.data?.length) setSchedules([...data.data]);
        else setSchedules([]);
      },
      (e) => {
        console.log(e);
        setSchedules([]);
      },
    );

  useEffect(() => {
    if (isSubItemSuggestionOpen) {
      getMedicineDefault();
    }
  }, [item]);

  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const toggleDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleClickOnDownArrow = (e, item) => {
    e.stopPropagation();
    if (!isSubItemSuggestionOpen) {
      getMedicineDefault();
    }
    setIsSubItemSuggestionOpen(!isSubItemSuggestionOpen);
  };

  return (
    <>
      {item.createdBy === userInfo.id ? (
        <div
          className={`chief-complain-item mp-20 ${
            isSelected ? 'is-selected' : ''
          }`}
          key={item.id}
          onMouseOver={() => {
            setHoverClass('complain-remove-btn-hover');
          }}
          onMouseOut={() => {
            setHoverClass('complain-remove-btn');
          }}
        >
          <span
            className="cursor-pointer"
            onClick={() =>
              isSelected ? removeMedicine(item) : itemClickAction(item)
            }
          >
            <span style={{ fontWeight: 600 }}>
              {showMedicineName === 'brandName' || showMedicineName === 'any'
                ? item.brandName
                : item.genericName}
            </span>{' '}
            -
            <span style={{ fontSize: '12px', color: '#1f932f' }}>
              {' '}
              {item.type}{' '}
            </span>
            {item.strength ? (
              <span style={{ color: '#bf620b', fontSize: '14px' }}>
                - {item.strength}
              </span>
            ) : (
              ''
            )}
          </span>
          <MedicineSubItemSuggestions
            openDropdown={handleClickOnDownArrow}
            isSubItemSuggestionOpen={isSubItemSuggestionOpen}
            closeDropdown={closeDropdown}
            itemName={item.brandName}
            medicineId={item.id}
            schedules={schedules}
            setSchedules={setSchedules}
            selectMedicines={selectMedicines}
            isTabCabType={medicineType(item.type ? item.type : '')}
            item={item}
          />
        </div>
      ) : (
        <div
          className={`chief-complain-item mp-20 ${
            isSelected ? 'is-selected' : ''
          }`}
          key={item.id}
          onClick={() =>
            isSelected ? removeMedicine(item) : itemClickAction(item)
          }
          onMouseOut={() => {
            setHoverClass('complain-remove-btn');
          }}
        >
          <span className="cursor-pointer">
            <span style={{ fontWeight: 600 }}>
              {showMedicineName === 'brandName' || showMedicineName === 'any'
                ? item.brandName
                : item.genericName}
            </span>{' '}
            -
            <span style={{ fontSize: '12px', color: '#1f932f' }}>
              {' '}
              {item.type}{' '}
            </span>
            {item.strength ? (
              <span style={{ color: '#bf620b', fontSize: '14px' }}>
                - {item.strength}
              </span>
            ) : (
              ''
            )}
          </span>
          <MedicineSubItemSuggestions
            openDropdown={handleClickOnDownArrow}
            isSubItemSuggestionOpen={isSubItemSuggestionOpen}
            closeDropdown={closeDropdown}
            itemName={item.brandName}
            medicineId={item.id}
            schedules={schedules}
            setSchedules={setSchedules}
            selectMedicines={selectMedicines}
            isTabCabType={medicineType(item.type ? item.type : '')}
            item={item}
          />
        </div>
      )}
    </>
  );
};

export default memo(ItemMedicineWithDeleteIcon);
