import React, { memo } from 'react';
import parse from 'html-react-parser';
import { Row, Col } from 'react-bootstrap';
import { styledComponent } from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';

const SpecialNoteView = ({
  // specialNote,
  // setSpecialNote,
  selectedSpecialNotes,
  setSelectedSpecialNotes,
  setShowSpecialNote,
  prescriptionItems,
  element,
  isHistoryPage = false,
}) => {
  const Title = styledComponent(
    prescriptionItems?.items?.['special-note']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['special-note']?.subItemStyle || {},
  );
  const deleteSpecialNote = (specialNoteId) => {
    selectedSpecialNotes = selectedSpecialNotes.filter(
      (specialNote) => specialNote?.note !== specialNoteId,
    );
    setSelectedSpecialNotes([...selectedSpecialNotes]);
  };

  return (
    <div
      className={`row item-row special-note-view ${
        selectedSpecialNotes?.length ? 'data-item' : 'no-data-item'
      }`}
    >
      <span
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setShowSpecialNote(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span>Special Note +</span>
        )}
      </span>
      <ul className="sub-child-list special-note-view">
        {selectedSpecialNotes?.map((item, index) => {
          if (item.groupName || item.note) {
            return (
              <li key={index} className="">
                {item?.groupName && (
                  // <div style={{ display: 'flex' }}>
                  //   <span className="text-left pr-5">
                  //     {item.groupName ? item.groupName : parse(item.note)}
                  //   </span>
                  //   {!isHistoryPage && (
                  //     <CommonDeleteBtn
                  //       action={deleteSpecialNote}
                  //       itemName={item?.note}
                  //       delId={''}
                  //     />
                  //   )}
                  // </div>
                  <>
                    <Row className="">
                      <Col className="groupName">{item.groupName}</Col>
                    </Row>
                    <Row>
                      <Col className="special-note">{parse(item.note)}</Col>
                      <Col sm={1}>
                        <CommonDeleteBtn
                          action={deleteSpecialNote}
                          itemName={item?.note}
                          delId={''}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {/* {!item?.groupName && item.note && (
                  <div className="pl-2 font-italic">
                    <span>- {parse(item.note)}</span>
                    <CommonDeleteBtn
                      action={deleteSpecialNote}
                      itemName={item?.note}
                      delId={''}
                    />
                  </div>
                )} */}
                {!item?.groupName && item.note && (
                  <Row>
                    <Col className="special-note">{parse(item.note)}</Col>
                    <Col sm={1}>
                      <CommonDeleteBtn
                        action={deleteSpecialNote}
                        itemName={item?.note}
                        delId={''}
                      />
                    </Col>
                  </Row>
                )}
              </li>
            );
          }
        })}
        {/* <div>
          <span className="text-left pr-5">
            <Value> {parse(specialNote)}</Value>
          </span>
          {!isHistoryPage && (
            <i
              className="fa fa-times-circle float-right pr-5"
              onClick={() => setSpecialNote('')}
            ></i>
          )}
        </div> */}
        {/* )} */}
      </ul>
    </div>
  );
};

export default memo(SpecialNoteView);
