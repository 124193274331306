import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  MEDICINE_TYPES_SHORTS,
  countOccurrencesOf,
} from '../../../../utils/helpers';
import SelectedMedicineScheduleHistory from '../history/selectedMedicineScheduleHistory';

const DrugHistoryView = ({ selectedHistories, setSelectedHistories }) => {
  const prescriptionItems = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.prescriptionItems,
  );

  const incrementSerial = (index) => {
    return index + 1;
  };

  const getRxStrength = (item) => {
    let strengthGeneric =
      item.strength && countOccurrencesOf(item.strength, '+') <= 1
        ? ' (' + item.strength + ')'
        : '';
    return strengthGeneric;
  };

  const scheduleViews = (item) => {
    return item?.itemDetails?.map((data, index) => {
      return (
        <SelectedMedicineScheduleHistory
          data={data}
          item={item}
          key={index}
          typeName={item?.type}
          prescriptionItems={prescriptionItems}
        />
      );
    });
  };

  const unselectMedicine = (type, itemId) => {
    const result = selectedHistories?.drugHistories[type]?.filter(
      (selectedItem) => selectedItem.id !== itemId,
    );
    selectedHistories.drugHistories[type] = result;
    setSelectedHistories({ ...selectedHistories });
  };

  return (
    <div>
      {selectedHistories?.drugHistories?.D_H?.map((item, index) => (
        <Row
          className={`selected-medicine-row ${index === 0 ? 'mt-2' : ''}`}
          key={index}
        >
          <Col md={10} className="medicine-schedule-parent drug-view">
            <div className="text-left">
              <span>
                {incrementSerial(index)}. {MEDICINE_TYPES_SHORTS[item.type]}{' '}
                {item.name} {`${getRxStrength(item)}`}{' '}
                {item?.alterMedicine
                  ? ` /  ${MEDICINE_TYPES_SHORTS[item?.alterMedicine?.type]} ${
                      item?.alterMedicine?.name
                    } ${' '} ${getRxStrength(item?.alterMedicine)}`
                  : ''}
              </span>
            </div>
            {scheduleViews(item)}
          </Col>
          <Col md={1} className="pr-0">
            <i
              className="fa fa-times-circle float-right pr-1 pt-1"
              onClick={() => unselectMedicine('D_H', item.id)}
            ></i>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default DrugHistoryView;
