import { memo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { itemSettingStyle, getDragDropStyle } from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';

const FollowUp = ({
  element,
  prescriptionItems,
  setShowFollowUp,
  deletePrescriptionItem,
  selectedFollowUps,
  setSelectedFollowUps,
  isHistoryPage = false,
}) => {
  let result = selectedFollowUps ? selectedFollowUps : [];
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      selectedFollowUps,
      result.source.index,
      result.destination.index,
    );
    setSelectedFollowUps(reorderedItems);
  };

  // this function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  return (
    <div
      className={`row item-row ${
        result?.length ? 'data-item' : 'no-data-item'
      }`}
      key={`follow-up`}
    >
      <span
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setShowFollowUp(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span>Follow Up +</span>
        )}
      </span>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul
              className="sub-child-list drag-drop follow-ul"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {selectedFollowUps?.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(provided, snapshot) => (
                    <li
                      key={index}
                      className="follow-up-list"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getDragDropStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <span
                        className="text-left pr-5"
                        style={itemSettingStyle(
                          prescriptionItems?.items?.['follow-up']?.itemStyle ||
                            {},
                        )}
                      >
                        {item}
                      </span>
                      {!isHistoryPage && (
                        <CommonDeleteBtn
                          action={deletePrescriptionItem}
                          itemName={'followup'}
                          delId={item}
                        />
                      )}
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default memo(FollowUp);
