import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  MEDICINE_TYPES_SHORTS,
  countOccurrencesOf,
} from '../../../../utils/helpers';
import SelectedMedicineScheduleHistory from './selectedMedicineScheduleHistory';
import EditSelectedMedicineItemHistory from '../editSelectedMedicineItemHistory';
import { HistoryContext } from '../../../../contexts/HistoryContext';

const SelectedDrugList = ({
  selectedData,
  setEditMedicineId,
  unselectMedicine,
  handleSelectedMedicine,
  editMedicineId,
  medicineUpdated,
}) => {
  const { selectedMedicines, setSelectedMedicines } =
    useContext(HistoryContext);
  const prescriptionItems = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.prescriptionItems,
  );

  const incrementSerial = (index) => {
    return index + 1;
  };

  const getRxStrength = (item) => {
    let strengthGeneric =
      item.strength && countOccurrencesOf(item.strength, '+') <= 1
        ? ' (' + item.strength + ')'
        : '';
    return strengthGeneric;
  };

  const scheduleViews = (item) => {
    return item?.itemDetails?.map((data, index) => {
      return (
        <SelectedMedicineScheduleHistory
          data={data}
          item={item}
          key={index}
          typeName={item?.type}
          element={''}
          rxWhite={''}
          prescriptionItems={prescriptionItems}
          setEditMedicineId={setEditMedicineId}
        />
      );
    });
  };

  const selectedMedicineRow = (items) => {
    return items.map((item, index) => {
      let objIndex = selectedData?.drugHistories['D_H']?.findIndex(
        (medicine) => medicine.id == item.id,
      );
      return (
        <EditSelectedMedicineItemHistory
          item={item}
          itemIndexNo={objIndex}
          key={index}
          medicineUpdated={medicineUpdated}
          handleSelectedMedicine={handleSelectedMedicine}
          setEditMedicineId={setEditMedicineId}
        />
      );
    });
  };

  const moveDrug = (movingItems) => {
    const selectedmedIds = selectedMedicines.map((medicine) => medicine.id);
    setSelectedMedicines(
      selectedMedicines.length > 0
        ? [
            ...selectedMedicines,
            ...movingItems.filter((item) => !selectedmedIds.includes(item.id)),
          ]
        : [...movingItems],
    );
  };

  return (
    <div>
      {selectedData?.drugHistories?.D_H?.map((item, index) => (
        <Row
          className={`selected-medicine-row ${index === 0 ? 'mt-2' : ''}`}
          key={index}
        >
          <Col
            className="medicine-schedule-parent"
            onClick={() => setEditMedicineId(item?.id)}
          >
            <div className="text-left">
              <span>
                {incrementSerial(index)}. {MEDICINE_TYPES_SHORTS[item.type]}{' '}
                {item.name} {`${getRxStrength(item)}`}{' '}
                {item?.alterMedicine
                  ? ` /  ${MEDICINE_TYPES_SHORTS[item?.alterMedicine?.type]} ${
                      item?.alterMedicine?.name
                    } ${' '} ${getRxStrength(item?.alterMedicine)}`
                  : ''}
              </span>
            </div>
            {scheduleViews(item)}
          </Col>
          <Col md={1}>
            <i
              onClick={() => moveDrug([item])}
              className="fas fa-share move-to-rx pt-1"
            ></i>
          </Col>
          <Col md={1}>
            <i
              className="fa fa-times-circle float-right pt-1"
              onClick={(e) => {
                e.stopPropagation();
                unselectMedicine('D_H', item.id);
              }}
            ></i>
          </Col>
          {editMedicineId === item.id &&
            selectedMedicineRow(
              selectedData?.drugHistories?.D_H.filter(
                (medicine) => medicine.id === item.id,
              ),
            )}
        </Row>
      ))}
      {selectedData?.drugHistories?.D_H?.length > 1 && (
        <Row>
          <Col className="text-right">
            <span
              className="move-all-btn"
              onClick={() => moveDrug(selectedData?.drugHistories?.D_H)}
            >
              <span className="mr-2">Move All</span>
              <i className="fas fa-share pt-1 mr-3"></i>
            </span>
          </Col>
          <Col md={1}></Col>
        </Row>
      )}
    </div>
  );
};

export default SelectedDrugList;
