import React, { useState, useEffect, memo, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Row, Tab } from 'react-bootstrap';
import { getRequest, deleteRequest } from '../../../utils/axiosRequests';
import SelectedMedicineItem from '../partials/selectedMedicineItem';
import MedicineGroupForm from '../modals/medicineGroupForm';
import ItemMedicineWithDeleteIcon from '../partials/itemMedicineWithDeleteIcon';
import ItemGroupWithDeleteIcon from '../partials/itemGroupWithDeleteIcon';
import AddMedicineForm from './addMedicineForm';
import LoadMore from '../partials/loadMore';
import { medicineType, checkDoctorDept } from '../../../utils/helpers';
import MedicineSearchArea from '../partials/medicineSearchArea';
import TabNav from '../partials/tabNav';
import { RxContext } from '../../../contexts/RxContext';
import { useClickAway } from 'react-use';
import RxNameType from '../partials/rx/rxNameType';
import GynePatientType from '../partials/rx/gynePatientType';
import SearchMedicineSuggestion from '../searchMedicineSuggestion';
import { useGetRequest } from '../../../hooks/useGetRequest';

const RxModal = ({
  modalTitle,
  medicines,
  setMedicines,
  setCurrentPage,
  totalItem,
  perPage,
  currentPage,
  medicinesInSearch,
  setMedicinesInSearch,
  showRx,
  setShowRx,
}) => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const specialties = useSelector(
    (state) => state.specialtyReducer.specialties,
  );
  const isGynecology = checkDoctorDept(
    'gynecology',
    userInfo?.speciality[0],
    specialties,
  );
  const isActivePatientOption =
    isGynecology && userInfo?.doctorDetails?.isGyneRxView;
  const [gynePatType, setGynePatType] = useState('wife');
  const [showMedicineName, setShowMedicineName] = useState('brandName');
  const [isMedicineGroupModal, setIsMedicineGroupModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMedicineModal, setIsMedicineModal] = useState(false);
  const [parentId, setParentId] = useState('');
  const [isMedicineDefault, setIsMedicineDefault] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAlterMedicine, setIsAlterMedicine] = useState('');
  const [searchItemName, setSearchItemName] = useState('');

  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  // const [clickedItemIdForShowSuggestion, setClickedItemIdForShowSuggestion] =
  //   useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(5);
  const [schedules, setSchedules] = useState([]);

  const {
    selectedMedicines,
    setSelectedMedicines,
    selectedMedicineGroups,
    setSelectedMedicineGroups,
    medicineGroups,
    setMedicineGroups,
  } = useContext(RxContext);

  const notFoundResult = [
    {
      id: 'notFound',
      brandName: 'not found',
      genericName: 'not found',
    },
  ];
  const deleteMedicineGroup = (groupId) => {
    deleteRequest(`prescriptions/groups/${groupId}`)
      .then((data) => {
        setMedicineGroups(
          medicineGroups.filter((group) => group.id !== groupId),
        );
        setSelectedMedicineGroups(
          selectedMedicineGroups.filter((group) => group.id !== groupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const { isLoading: isLoadingMedicineDefault, refetch: getMedicineDefault } =
    useGetRequest(
      `getMedicineDefault-${clickedItemForShowSuggestion?.id}`,
      `medicine-default?medicineId=${clickedItemForShowSuggestion?.id}`,
      (data) => {
        if (data.data?.length) setSchedules([...data.data]);
        else setSchedules([]);
      },
      (e) => {
        console.log(e);
        setSchedules([]);
      },
    );

  useEffect(() => {
    if (clickedItemForShowSuggestion && isSubItemSuggestionOpen) {
      getMedicineDefault();
    }
  }, [clickedItemForShowSuggestion]);

  const selectMedicineGroup = (medicineGroup) => {
    const newMedicines = medicineGroup?.medicines?.filter(
      (medicine) => !selectedMedicines.some((data) => data.id === medicine.id),
    );
    setSelectedMedicines([...selectedMedicines, ...newMedicines]);
    setSelectedMedicineGroups([...selectedMedicineGroups, medicineGroup]);
  };

  const unSelectMedicineGroup = (medicineGroup) => {
    const otherGroups = selectedMedicineGroups.filter(
      (data) => data.name !== medicineGroup.name,
    );
    setSelectedMedicineGroups([...otherGroups]);
    let itemsToDelete = medicineGroup?.medicines?.map((item) => item.id);
    if (otherGroups?.length) {
      const otherGroupItems = otherGroups.flatMap((obj) =>
        obj?.medicines?.map((item) => item.id),
      );
      itemsToDelete = itemsToDelete.filter(
        (item) => !otherGroupItems?.includes(item),
      );
    }
    setSelectedMedicines((prevItems) =>
      prevItems.filter((item) => !itemsToDelete.includes(item.id)),
    );
  };

  const handleAlterMedicine = (medicineName, item) => {
    const objIndex = selectedMedicines.findIndex(
      (medicine) => medicine.id == isAlterMedicine,
    );
    selectedMedicines[objIndex]['alterMedicine'] = {
      id: item.id,
      name: medicineName,
      type: item.type,
      strength: item.strength,
      brandName: item.brandName,
      genericName: item.genericName,
    };
    setSelectedMedicines([...selectedMedicines]);
    setIsAlterMedicine('');
  };

  const selectMedicines = async (item, defaultDataFromSub = null) => {
    setIsMedicineDefault(false);
    let tempItem = { ...item };
    const isExist = selectedMedicines?.some(
      (selectedMedicine) => selectedMedicine.id === tempItem.id,
    );
    if (!isExist) {
      let medicineName =
        tempItem[showMedicineName === 'any' ? 'brandName' : showMedicineName];
      if (isAlterMedicine) {
        handleAlterMedicine(medicineName, tempItem);
        return false;
      }
      tempItem.name = medicineName;
      tempItem.type = tempItem.type;
      tempItem.parentId = parentId;
      tempItem.alterMedicine = '';
      if (isActivePatientOption) {
        tempItem.gynePatType = gynePatType;
      }

      if (defaultDataFromSub) {
        tempItem.itemDetails = defaultDataFromSub;
      } else {
        tempItem.itemDetails = [
          {
            quantitiesField: [1, 2, 3],
            schedule: '',
            scheduleUnit: 'বার',
            quantities: { first: 0, second: 0, third: 0 },
            quantity: '',
            quantityUnit: medicineType(tempItem?.type) ? 'টা' : 'চামুচ',
            times: '১',
            timesUnit: 'বেলা',
            duration: '',
            durationUnit: 'দিন',
            instructions: [],
          },
        ];
        await getRequest(`medicine-default/${tempItem.id}`)
          .then((data) => {
            if (data?.itemDetails?.length > 0) {
              tempItem.itemDetails = data?.itemDetails;
              tempItem.isDefaultSchedule = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setSelectedMedicines([...selectedMedicines, tempItem]);
    } else if (isExist && defaultDataFromSub) {
      const existingMedIndex = selectedMedicines?.findIndex(
        (med) => med.id === item?.id,
      );
      if (existingMedIndex !== -1) {
        selectedMedicines[existingMedIndex]['itemDetails'] = defaultDataFromSub;
        setSelectedMedicines([...selectedMedicines]);
      }
    } else {
      unselectMedicine(item);
    }
  };

  const deleteMedicine = (medicineId) => {
    deleteRequest(`medicines/${medicineId}`)
      .then((data) => {
        const result = medicines.filter(
          (medicine) => medicine.id !== medicineId,
        );
        setMedicines([...result]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const unselectMedicine = (item) => {
  //   const isAlterMedicine = selectedMedicines.some(
  //     (selectedItem) => selectedItem?.alterMedicine?.id === item.id,
  //   );
  //   if (isAlterMedicine) {
  //     for (let medicine of selectedMedicines) {
  //       if (medicine?.alterMedicine?.id === item.id) {
  //         delete medicine.alterMedicine;
  //       }
  //     }
  //     console.log(selectedMedicines, 'Unselect medicine');
  //     setSelectedMedicines([...selectedMedicines]);
  //   } else {
  //     const result = selectedMedicines.filter(
  //       (selectedItem) => selectedItem.id !== item.id,
  //     );
  //     setSelectedMedicines([...result]);
  //   }
  // };

  const unselectMedicine = (item) => {
    let isAlterMedicineFound = false;

    const updatedMedicines = selectedMedicines?.map((medicine) => {
      if (medicine?.alterMedicine?.id === item.id) {
        isAlterMedicineFound = true;
        const updatedMedicine = { ...medicine }; // Create a shallow copy
        delete updatedMedicine.alterMedicine;
        return updatedMedicine;
      }
      return medicine;
    });

    if (isAlterMedicineFound) {
      setSelectedMedicines(updatedMedicines);
    } else {
      setSelectedMedicines(
        selectedMedicines.filter((selectedItem) => selectedItem.id !== item.id),
      );
    }
  };

  const allMedicine = () =>
    medicines.map((item, index) => {
      let isSelected = selectedMedicines?.some(
        (data) => data.id === item.id || data?.alterMedicine?.id === item.id,
      );
      return (
        <ItemMedicineWithDeleteIcon
          key={index}
          item={item}
          isSelected={isSelected}
          removeMedicine={unselectMedicine}
          itemClickAction={selectMedicines}
          removeClickAction={deleteMedicine}
          showMedicineName={showMedicineName}
          handleSelectedMedicine={handleSelectedMedicine}
          selectMedicines={selectMedicines}
        />
      );
    });

  let allMedicineGroups = medicineGroups.map((item, index) => {
    let isSelectedGroup = selectedMedicineGroups.some(
      (data) => data.name === item.name,
    );

    return (
      <ItemGroupWithDeleteIcon
        key={index}
        item={item}
        isSelectedGroup={isSelectedGroup}
        unSelectMedicineGroup={unSelectMedicineGroup}
        itemClickAction={selectMedicineGroup}
        removeClickAction={deleteMedicineGroup}
      />
    );
  });

  useEffect(() => {
    allMedicine();
  }, [showMedicineName]);

  const handleSelectedMedicine = (
    fieldName,
    medicineId,
    value,
    itemDetailIndex,
  ) => {
    const objIndex = selectedMedicines.findIndex(
      (medicine) => medicine.id == medicineId,
    );
    switch (fieldName) {
      case 'quantities':
      case 'quantitiesField':
      case 'quantityUnit':
      //case 'duration':
      //case 'durationUnit':
      case 'quantity':
      case 'schedule':
      case 'scheduleUnit':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        break;
      case 'duration':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        if (value === '') {
          selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
            'durationUnit'
          ] = '';
        }
        break;
      case 'durationUnit':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        if (value === '' || value === 'চলবে') {
          selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
            'duration'
          ] = '';
        }
        break;
      case 'instructions':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          [
            ...new Set([
              ...selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
                fieldName
              ],
              value,
            ]),
          ];
        break;
      case 'instructionRemove':
        selectedMedicines[objIndex]['itemDetails'][itemDetailIndex][
          'instructions'
        ] = value;
        break;
      case 'itemDetails':
        selectedMedicines[objIndex][fieldName] = value;
        break;
      case 'similarMedicine':
        let replacedData = selectedMedicines[objIndex];
        replacedData.name =
          value[showMedicineName === 'any' ? 'brandName' : showMedicineName];
        replacedData.id = value?.id;
        replacedData.brandName = value?.brandName;
        replacedData.companyName = value?.companyName;
        replacedData.genericName = value?.genericName;
        replacedData.strength = value?.strength;
        replacedData.type = value?.type;
        replacedData.altName = value?.altName;
        replacedData.createdBy = value?.createdBy;
        selectedMedicines[objIndex] = replacedData;
        break;
      case 'medicineType':
        selectedMedicines[objIndex]['type'] = value;
        setSelectedMedicines([...selectedMedicines]);
        break;
    }
    setSelectedMedicines([...selectedMedicines]);
  };

  const medicineUpdated = (updatedData, selectedIndex) => {
    selectedMedicines[selectedIndex] = updatedData;
    setSelectedMedicines([...selectedMedicines]);
  };

  const selectedMedicineRow = () => {
    const reverseSelectedMedicines = [...selectedMedicines].reverse();
    let count = reverseSelectedMedicines.length;
    return reverseSelectedMedicines.map((item, index) => {
      return (
        <SelectedMedicineItem
          item={item}
          selectedIndex={--count}
          key={index}
          parentId={parentId}
          setParentId={setParentId}
          removeMedicine={unselectMedicine}
          handleSelectedMedicine={handleSelectedMedicine}
          showMedicineName={showMedicineName}
          medicineUpdated={medicineUpdated}
          isAlterMedicine={isAlterMedicine}
          setIsAlterMedicine={setIsAlterMedicine}
          isMedicineDefault={isMedicineDefault}
          setIsMedicineDefault={setIsMedicineDefault}
        />
      );
    });
  };

  const handleMedicineSearch = (selectedOption) => {
    if (selectedOption.id === 'notFound') {
      setIsMedicineModal(true);
    } else {
      selectMedicines(selectedOption);
      //setMedicinesInSearch([...notFoundResult]);
      //setSearchQuery('');
    }
  };

  const handleOnInputChange = (val) => {
    setIsLoading(true);
    if (val) {
      setSearchItemName(val);
      setMedicinesInSearch([]);
      const searchKey = encodeURIComponent(val);
      const url = `medicines?${showMedicineName}=${searchKey}&search=yes`;
      setSearchQuery(val);
      getRequest(url)
        .then((data) => {
          if (data?.data?.length > 0) {
            const customizedResults = data.data.map((item) => {
              return {
                ...item,
                label:
                  item[
                    showMedicineName === 'any' ? 'brandName' : showMedicineName
                  ],
                value:
                  item[
                    showMedicineName === 'any' ? 'brandName' : showMedicineName
                  ],
              };
            });
            //for sorting medicine names during search
            // const customizedResults = sortMedicines({
            //   medicines: medicineResults,
            //   sortBy: showMedicineName,
            // });
            // setMedicinesInSearch([...medicineResults]);
            setMedicinesInSearch([...customizedResults]);
          } else {
            setMedicinesInSearch([...notFoundResult]);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setShowRx(false);
      setIsMedicineDefault(false);
    } else if (clickedArea.classList.contains('medicine-default')) {
      setIsMedicineDefault(false);
    } else if (clickedArea.classList.contains('medicine-modal')) {
      setIsMedicineModal(false);
    } else if (clickedArea.classList.contains('medicine-group-modal')) {
      setIsMedicineGroupModal(false);
    }
  });

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setClickedItemForShowSuggestion('');
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    console.log('click on medicine suggestion: ', note, itemName);
    // const item = advices.find(
    //   (advicePlanItem) => advicePlanItem.name === itemName,
    // );
    // item['note'] = note;
    // if (selectedAdvices.some((advice) => advice.name === item.name)) {
    //   setSelectedAdvices(
    //     selectedAdvices.map((advice) =>
    //       advice.name !== item.name ? advice : { ...advice, note: item?.note },
    //     ),
    //   );
    // } else {
    //   setSelectedAdvices([
    //     ...selectedAdvices,
    //     { name: item.name, note: item?.note },
    //   ]);
    // }
  };

  const handleClickOnRightArrow = (e, item) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion.label === item.label) {
      setClickedItemForShowSuggestion('');
      // setClickedItemIdForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(item);
      // setClickedItemIdForShowSuggestion(medicineId);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 4;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };

  const formatOptionLabel = ({ value, label }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <span style={{ paddingTop: '4px' }}>{label}</span>
      <span>
        <i
          className="fa fa-angle-right"
          style={{
            color: '#00b38c',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '8px',
            paddingBottom: '5px',
            fontSize: '18px',
            // border: '1px solid lightGrey',
          }}
          onClick={(e) => handleClickOnRightArrow(e, label)}
        />
      </span>
    </div>
  );

  return (
    <Modal show={showRx} size="lg" className="customize-modal-size">
      <Modal.Header className="common-modal-header">
        <Modal.Title>{modalTitle}</Modal.Title>
        {isActivePatientOption && (
          <GynePatientType
            gynePatType={gynePatType}
            setGynePatType={setGynePatType}
          />
        )}
        <RxNameType
          setShowRx={setShowRx}
          showMedicineName={showMedicineName}
          setShowMedicineName={setShowMedicineName}
        />
      </Modal.Header>
      <Modal.Body ref={ref} style={{ position: 'relative' }}>
        <MedicineSearchArea
          handleOnInputChange={handleOnInputChange}
          onChange={handleMedicineSearch}
          searchQuery={searchQuery}
          options={medicinesInSearch}
          placeholder={'medicine'}
          showMedicineName={showMedicineName}
          selectedInputs={selectedMedicines}
          setIsMedicineModal={setIsMedicineModal}
          handleClickOnRightArrow={handleClickOnRightArrow}
        />
        <Tab.Container defaultActiveKey="all">
          <TabNav
            action={setIsMedicineGroupModal}
            selectedItems={selectedMedicines}
          />
          <Tab.Content>
            <Tab.Pane eventKey="all" className="add-scroll">
              <Row className="complains-area ml-0 mr-0">{allMedicine()}</Row>
              <LoadMore
                currentPage={currentPage}
                totalItem={totalItem}
                perPage={perPage}
                currentPageAction={setCurrentPage}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="group" className="add-scroll">
              <Row className="complains-area ml-0 mr-0 mt-1">
                {allMedicineGroups}
              </Row>
            </Tab.Pane>
            <Tab.Pane eventKey="">
              <Row className="complains-area ml-0 mr-0">{''}</Row>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-medicine-area">{selectedMedicineRow()}</div>

        <MedicineGroupForm
          modalTitle={modalTitle}
          isMedicineGroupModal={isMedicineGroupModal}
          setIsMedicineGroupModal={setIsMedicineGroupModal}
        />
        <AddMedicineForm
          isMedicineModal={isMedicineModal}
          setIsMedicineModal={setIsMedicineModal}
          searchQuery={searchQuery}
          medicines={medicines}
          setMedicines={setMedicines}
          selectMedicines={selectMedicines}
        />
        {isSubItemSuggestionOpen && (
          <SearchMedicineSuggestion
            openDropdown={openDropdown}
            isSubItemSuggestionOpen={isSubItemSuggestionOpen}
            closeDropdown={closeDropdown}
            itemName={
              clickedItemForShowSuggestion?.label
                ? clickedItemForShowSuggestion?.label
                : ''
            }
            suggetionMenuTop={suggetionMenuTop}
            medicineId={
              clickedItemForShowSuggestion.id
                ? clickedItemForShowSuggestion.id
                : ''
            }
            schedules={schedules}
            setSchedules={setSchedules}
            clickedItemForShowSuggestion={clickedItemForShowSuggestion}
            selectMedicines={selectMedicines}
            isTabCabType={medicineType(
              clickedItemForShowSuggestion.type
                ? clickedItemForShowSuggestion.type
                : '',
            )}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
export default memo(RxModal);
