import { memo } from 'react';
import {
  isExistAnyGyneData,
  isExistAnyDentalData,
  isExistAnyOncologyData,
  isExistObservationData,
  isExistAnyOnExamination,
  isExistAnyCardiologyData,
  isExistGeneralExaminationData,
} from '../../../../utils/helpers';
import ObservationView from './observation';
import OcularExaminationView from './ocularExaminationView';
import GeneralExaminationView from './generalExaminationView';
import DentalView from './dental';
import SystemicExaminationView from './systemicExamination';
import OncologyExaminationView from './oncologyExamination';
import GyneExaminationView from './gyne';

const Predictions = ({
  element,
  prescriptionItems,
  selectedOnExamination,
  setShowPredictions,
  deletePrescriptionItem,
  setSelectedOnExamination,
  isHistoryPage = false,
}) => {
  return (
    <div
      className={`row item-row ${
        !isExistAnyOnExamination(selectedOnExamination) ? 'pb-5' : 'pb-2'
      }`}
      key={`predictions`}
    >
      <div
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setShowPredictions(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span>Predictions +</span>
        )}
      </div>
      <div className="on-examination-view-area">
        {/* {isExistAnyOncologyData(selectedOnExamination?.oncologyExamination) && ( */}
        <OncologyExaminationView
          selectedOnExamination={selectedOnExamination}
          setSelectedOnExamination={setSelectedOnExamination}
          isHistoryPage={isHistoryPage}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default memo(Predictions);
