import React, { useEffect, useState } from 'react';
import Header from '../../components/doctors/partials/Header';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { getRequest } from '../../utils/axiosRequests';
import TopLeftMenu from '../../components/doctors/partials/topLeftMenu';
import PatientHistoryLeft from '../../components/doctors/partials/patientHistoryLeft';
import PatientHistoryRight from '../../components/doctors/partials/patientHistoryRight';

function PatientHistory() {
  const { state } = useLocation();
  const { patientData, appointment } = state;
  const [prescriptions, setPrescriptions] = useState([]);
  const [note, setNote] = useState('');
  const [generalExamination, setGeneralExamination] = useState({});
  const activeOrganization = useSelector(
    (state) => state.orgReducer.organization,
  );

  const getPrescriptionList = async () => {
    try {
      let result = await getRequest(
        'prescriptions?organizationId=' +
          activeOrganization.id +
          '&patientId=' +
          patientData?.id,
      );
      const note = result?.note?.note;
      result = result.data;
      let length = result.length;
      let prescription =
        length > 0 ? (length === 1 ? result[0] : result[length - 1]) : {};
      setGeneralExamination(prescription?.onExaminations?.generalExaminations);
      setPrescriptions(result);
      setNote(note);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPrescriptionList();
  }, []);

  return (
    <div className="patient-histories">
      <Header />
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-12 sticky-header shadow header-sticky-second">
            <TopLeftMenu />
          </div>
        </div>
        <Row className="rx-body">
          <div className="col-xl-12 col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold">
                  <Link to={'/appointments'}> Patient Appointment History</Link>{' '}
                  <i className="fas fa-angle-right"></i> {patientData?.name}
                </h6>
              </div>
              <div className="card-body">
                <div>
                  <Row>
                    <Col lg={2} md={4} sm={12} className="patint-history-left">
                      <PatientHistoryLeft
                        patientData={patientData}
                        prescriptions={prescriptions}
                        generalExamination={generalExamination}
                      />
                    </Col>
                    <Col lg={10} md={8} sm={12} className="pl-4">
                      <PatientHistoryRight
                        patientData={patientData}
                        prescriptions={prescriptions}
                        setPrescriptions={setPrescriptions}
                        appointment={appointment}
                        note={note}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default PatientHistory;
