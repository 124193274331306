import React, { memo, useEffect, useRef, useState } from 'react';
import { Modal, Tab, Nav, Row, Col } from 'react-bootstrap';
import ModalHeader from '../partials/modalHeader';
import Editor from '../partials/editorSpecialNote';
import SpecialNoteGroupModal from './specialNoteGroup';
import { useGetRequest } from '../../../hooks/useGetRequest';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import LoadMore from '../partials/loadMore';
import { useClickAway } from 'react-use';
import TabNav from '../partials/tabNav';

const SpecialNoteModal = ({
  modalTitle,
  showSpecialNote,
  setShowSpecialNote,
  selectedSpecialNotes,
  setSelectedSpecialNotes,
}) => {
  const [isSpecialNoteGroup, setIsSpecialNoteGroup] = useState(false);
  const [specialNote, setSpecialNote] = useState('');
  const [specialNotes, setSpecialNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const perPage = 30;

  useEffect(() => {
    getSpecialNoteList();
  }, [currentPage]);

  const { isLoading: isLoading, refetch: getSpecialNoteList } = useGetRequest(
    'getSpecialNotes',
    `special-notes?page=${currentPage}&perPage=${perPage}`,
    (data) => {
      const result = data?.data?.map((item) => {
        return {
          note: item.note,
          groupName: item.groupName,
        };
      });
      if (currentPage > 1) {
        setSpecialNotes([...specialNotes, ...result]);
      } else {
        setSpecialNotes([...result]);
        setTotalItem(data.total);
      }
    },
    (e) => {
      console.log(e);
    },
  );

  const selectSpecialNote = (data) => {
    if (selectedSpecialNotes.some((item) => item.note === data.note)) {
      setSelectedSpecialNotes(
        selectedSpecialNotes.filter((item) => item.note !== data.note),
      );
    } else {
      setSelectedSpecialNotes([...selectedSpecialNotes, data]);
    }
  };

  const allSpecialNotes = () => {
    return specialNotes?.map((item, index) => {
      if (item.groupName !== '') {
        let isSelected = selectedSpecialNotes?.some(
          (data) => data?.groupName === item.groupName,
        );
        isSelected = isSelected ? true : false;
        return (
          <ItemWithDeleteIcon
            key={index}
            item={item}
            isSelected={isSelected}
            isSpecialNote={true}
            itemClickAction={selectSpecialNote}
          />
        );
      }
    });
  };

  const handleSpecialNote = (data, noteKey) => {
    selectedSpecialNotes[noteKey]['note'] = data;
    setSelectedSpecialNotes([...selectedSpecialNotes]);
  };

  const selectedGroups = () => {
    return selectedSpecialNotes?.map((item, index) => {
      if (item.groupName !== '') {
        return (
          <Editor
            key={index}
            data={item?.note}
            specialNoteKey={index}
            setData={handleSpecialNote}
            height={220}
          />
        );
      }
    });
  };

  const handleData = (val) => {
    setSpecialNote(val);
    const index = selectedSpecialNotes?.findIndex(
      (item) => item.isNew === true,
    );
    if (index !== -1) {
      selectedSpecialNotes[index]['note'] = val;
    } else {
      selectedSpecialNotes = [
        ...selectedSpecialNotes,
        { isNew: true, note: val, groupName: '' },
      ];
    }
    setSelectedSpecialNotes([...selectedSpecialNotes]);
  };

  const currentData = () => {
    const result = selectedSpecialNotes?.find((item) => item.isNew);
    return result ? result.note : '';
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    if (clickedArea.classList.contains('customize-modal-size')) {
      setShowSpecialNote(false);
    } else if (clickedArea.classList.contains('child-modal')) {
      setIsSpecialNoteGroup(false);
    }
  });

  return (
    <>
      <Modal show={showSpecialNote} size="lg" className="customize-modal-size">
        <ModalHeader title={modalTitle} action={setShowSpecialNote} />
        <Modal.Body ref={ref}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="all">
            <TabNav
              selectedItems={[]}
              setIsSpecialNoteGroup={setIsSpecialNoteGroup}
            />
            <Tab.Content>
              <Tab.Pane eventKey="all" style={{ height: 400 }}>
                <Editor
                  data={currentData()}
                  setData={handleData}
                  height={380}
                />
              </Tab.Pane>
              <Tab.Pane
                eventKey="group"
                className="add-scroll"
                style={{ height: 450 }}
              >
                <Row className="complains-area mr-0 ml-0 mb-3">
                  {allSpecialNotes()}
                </Row>
                <Row className="mt-3 ml-1 mr-1">{selectedGroups()}</Row>
                <LoadMore
                  currentPage={currentPage}
                  totalItem={totalItem}
                  perPage={perPage}
                  currentPageAction={setCurrentPage}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
      </Modal>
      <SpecialNoteGroupModal
        modalTitle={modalTitle}
        isSpecialNoteGroup={isSpecialNoteGroup}
        setIsSpecialNoteGroup={setIsSpecialNoteGroup}
        specialNotes={specialNotes}
        setSpecialNotes={setSpecialNotes}
        specialNote={specialNote}
        setSpecialNote={setSpecialNote}
      />
    </>
  );
};

export default memo(SpecialNoteModal);
