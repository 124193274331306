import { memo } from 'react';

const RxNameType = ({ setShowRx, showMedicineName, setShowMedicineName }) => {
  return (
    <div role="group" className="btn-group rx-type-btns">
      <button
        type="button"
        className={`btn btn-sm ${
          showMedicineName !== 'genericName'
            ? 'btn-primary'
            : 'btn-outline-secondary'
        }`}
        onClick={() => setShowMedicineName('brandName')}
      >
        Brand Name
      </button>
      <button
        type="button"
        className={`btn btn-sm ${
          showMedicineName === 'genericName'
            ? 'btn-primary'
            : 'btn-outline-secondary'
        }`}
        onClick={() => setShowMedicineName('genericName')}
      >
        Generic Name
      </button>
      <i
        onClick={() => setShowRx(false)}
        className="fa fa-times-circle cursor-pointer pt-1"
        aria-hidden="true"
      ></i>
    </div>
  );
};

export default memo(RxNameType);
