import { Row, Col } from 'react-bootstrap';
import SelectCustom from './selectCustom';
import { components } from 'react-select';
import { countOccurrencesOf } from '../../../utils/helpers';

function MedicineSearchArea({
  handleOnInputChange,
  onChange,
  options,
  searchQuery,
  placeholder,
  selectedInputs,
  showMedicineName,
  setIsMedicineModal,
  handleClickOnRightArrow,
}) {
  const Option = (props) => (
    <components.Option {...props}>
      <span
        className="medicine-type"
        style={{ color: 'green', marginRight: '55px', textAlign: 'end' }}
      >
        {props?.data?.type}
      </span>
      <span
        className="medicine-name"
        style={{ fontSize: '14px' }}
        title={props?.data?.label + ' - ' + props?.data?.strength}
      >
        <span>{props?.data?.label}</span>{' '}
        <span
          style={{ fontWeight: 'normal', fontSize: '12px', color: '#bf620b' }}
        >
          {props?.data?.strength &&
          countOccurrencesOf(props?.data?.strength, '+') <= 1
            ? ' - (' + props?.data?.strength + ')'
            : ''}
        </span>
      </span>
      <span className="medicine-company">
        {showMedicineName === 'genericName' ? (
          <>
            <span style={{ color: '#cb6403' }}>{props?.data?.brandName}</span> -{' '}
            <span>{props?.data?.companyName}</span>
          </>
        ) : (
          <span>{props?.data?.companyName}</span>
        )}
      </span>
      <span style={{ display: 'flex', justifyContent: 'end', borderLeft: '1px solid #000' }}>
        <i
          className="fa fa-angle-right"
          style={{
            color: '#00b38c',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingTop: '5px',
            paddingBottom: '5px',
            fontSize: '18px',
            marginTop: '-40px',
            borderLeft: '1px solid #d9d9d9'
          }}
          onClick={(e) => handleClickOnRightArrow(e, props?.data)}
        />
      </span>
    </components.Option>
  );

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {options.length === 1 && options[0].id === 'notFound' ? (
            <span onClick={() => onChange(options[0])}>
              <span
                className="float-left"
                style={{
                  paddingBottom: '6px',
                  cursor: 'pointer',
                  width: '90%',
                  color: 'black',
                }}
              >
                {searchQuery}{' '}
              </span>
              <span className="float-right">
                <i
                  className="fa fa-plus-circle text-right"
                  aria-hidden="true"
                ></i>
              </span>
            </span>
          ) : (
            'No Data'
          )}
        </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <Row>
      <Col style={{ position: 'relative' }} className="search-parent">
        <SelectCustom
          handleInputChange={handleOnInputChange}
          onChange={onChange}
          components={{ Option, NoOptionsMessage }}
          options={options}
          selectedInputs={selectedInputs}
          placeholder={placeholder}
        />
        <svg width="14" height="14" className="search-icon" viewBox="0 0 20 20">
          <path
            d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
            stroke="currentColor"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
        <span className="new-rx-btn" onClick={() => setIsMedicineModal(true)}>
          <i className="fa fa-plus-circle" aria-hidden="true"></i> Create
        </span>
      </Col>
    </Row>
  );
}

export default MedicineSearchArea;
