import { Col, Button, Modal } from 'react-bootstrap';

const PopupModal = ({
  resetPrescription,
  showConfirmModal,
  setShowConfirmModal,
}) => {
  return (
    <Modal size="lg" show={showConfirmModal}>
      <Modal.Header className="common-modal-header">
        <Col>
          <Modal.Title>Delete</Modal.Title>
        </Col>
        <Col md={4}></Col>
        <Col md={1} style={{ textAlign: 'end' }}>
          <i
            className="fa fa-times cursor-pointer text-danger"
            aria-hidden="true"
            onClick={() => setShowConfirmModal(false)}
          ></i>
        </Col>
      </Modal.Header>
      <Modal.Body>
        <span className="text-danger">
          Your prescription has not been saved yet. Do you want to clear the
          data?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => setShowConfirmModal(false)}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            setShowConfirmModal(false);
            resetPrescription();
          }}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PopupModal;
