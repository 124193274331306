import React, { useState, useEffect, memo } from 'react';
import { Modal, Row, Col, Form } from 'react-bootstrap';
import {
  getRequest,
  postRequest,
  deleteRequest,
} from '../../../utils/axiosRequests';
import { englishToBanglaNumber } from '../../../utils/helpers';
import ItemWithDeleteIcon from '../partials/itemWithDeleteIcon';
import ModalHeader from '../partials/modalHeader';
import SearchArea from '../partials/searchArea';

const FollowUpModal = ({
  modalTitle,
  selectedFollowUps,
  setSelectedFollowUps,
  showFollowUp,
  setShowFollowUp,
}) => {
  const [followUps, setFollowUps] = useState([]);
  const [followUpsInSearch, setFollowUpsInSearch] = useState([]);
  const [comeAfter, setComeAfter] = useState(0);
  const [timeUnit, setTimeUnit] = useState('দিন');
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchItemName, setSearchItemName] = useState('');

  useEffect(() => {
    getRequest('followups')
      .then((data) => {
        setFollowUps(data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const selectFollowUps = (item) => {
    if (selectedFollowUps.some((follow) => follow === item.name)) {
      setSelectedFollowUps(selectedFollowUps.filter((f) => f !== item.name));
    } else {
      setSelectedFollowUps([...selectedFollowUps, item.name]);
    }
  };

  const deleteFollowUp = (followupId) => {
    deleteRequest(`followups/${followupId}`)
      .then((data) => {
        setFollowUps(
          followUps.filter((followup) => followup.id !== followupId),
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const selectedFollowUpList = () => {
    return selectedFollowUps.map((item, index) => {
      return (
        <Row className="selected-item-row" key={index}>
          <Col lg={11} md={11} sm={11} xs={10}>
            {item}
          </Col>
          <Col lg={1} md={1} sm={1} xs={1} className="text-right">
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              onClick={() => removeFollowUp(item)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  let allFollowUp = followUps?.map((item, index) => {
    let isSelected = selectedFollowUps.some((data) => data === item.name);

    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectFollowUps}
        removeClickAction={deleteFollowUp}
      />
    );
  });

  const handleSearchOrNew = (selectedOption) => {
    const name = selectedOption.name;
    if (followUps.some((followUp) => followUp.name === name)) {
      if (!selectedFollowUps.some((followUp) => followUp === name)) {
        setSelectedFollowUps([...selectedFollowUps, name]);
      } else {
        setSelectedFollowUps(
          selectedFollowUps.filter((followUp) => followUp !== name),
        );
      }
    } else {
      postRequest('followups', { name: name })
        .then((data) => {
          setSearchItemName('');
          setFollowUps([...followUps, data]);
          setSelectedFollowUps([...selectedFollowUps, data.name]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setSearchQuery('');
  };

  const handleFollowInput = (fieldName, e) => {
    const val = e.target.value;
    if (fieldName === 'comeDate') {
      selectedFollowUps[0] = englishToBanglaNumber(val) + ' তারিখে দেখা করবেন';
      setSelectedFollowUps([...selectedFollowUps]);
    } else if (fieldName === 'comeAfter') {
      selectedFollowUps[0] =
        englishToBanglaNumber(val) + ' ' + timeUnit + ' পর দেখা করবেন';
      setSelectedFollowUps([...selectedFollowUps]);
    } else if (fieldName === 'unit') {
      selectedFollowUps[0] =
        englishToBanglaNumber(comeAfter) + ' ' + val + ' পর দেখা করবেন';
      setSelectedFollowUps([...selectedFollowUps]);
    }
  };

  const removeFollowUp = (item) => {
    setSelectedFollowUps(
      selectedFollowUps.filter((selectedItem) => selectedItem !== item),
    );
  };

  const handleOnInputChange = (searchKey) => {
    setIsLoading(true);
    if (searchKey) {
      setSearchItemName(searchKey);
      setFollowUpsInSearch([]);

      const url = `followups?name=${encodeURIComponent(searchKey)}`;
      setSearchQuery(searchKey);

      getRequest(url)
        .then((data) => {
          const customizedResults =
            data?.data?.length > 0
              ? data.data.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.name,
                }))
              : [{ id: 'notFound', name: searchKey }];

          setFollowUpsInSearch(customizedResults);
          setIsLoading(false);
        })
        .catch(console.error);
    }
  };

  return (
    <Modal
      show={showFollowUp}
      size="lg"
      className="customize-modal-size"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <ModalHeader title={modalTitle} action={setShowFollowUp} />
      <Modal.Body>
        <SearchArea
          handleOnInputChange={handleOnInputChange}
          handleSearchOrNew={handleSearchOrNew}
          searchQuery={searchQuery}
          searchItemName={searchItemName}
          options={followUpsInSearch}
          placeholder={'follow up'}
          selectedInputs={selectedFollowUps}
        />
        <hr />
        <Row className="follow-up-form">
          <Col lg={7} md={7} sm={12} xs={12} className="responsive">
            <Form.Group as={Row}>
              <Form.Label
                column
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="text-center pr-0"
              >
                পরবর্তী ভিজিট:
              </Form.Label>
              <Col lg={5} md={5} sm={5} xs={5} className="pl-0">
                <Form.Control
                  className="form-control form-control-sm"
                  type="number"
                  min={0}
                  defaultValue={0}
                  onChange={(e) => {
                    setComeAfter(e.target.value);
                    handleFollowInput('comeAfter', e);
                  }}
                />
              </Col>
              <Col lg={3} md={3} sm={3} xs={3} className="pl-0">
                <Form.Select
                  className="form-control form-control-sm"
                  onChange={(evt) => {
                    setTimeUnit(evt.target.value);
                    handleFollowInput('unit', evt);
                  }}
                >
                  <option value={'দিন'}>দিন</option>
                  <option value="সপ্তাহ">সপ্তাহ</option>
                  <option value={'মাস'}>মাস</option>
                  <option value={'বছর'}>বছর</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Col>
          <Col lg={5} md={5} sm={12} xs={12}>
            <Form.Group as={Row}>
              <Form.Label
                column
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="text-right pr-0"
              >
                Add Date:
              </Form.Label>
              <Col lg={8} md={8} sm={8} xs={8} className="pl-0">
                <Form.Control
                  type="date"
                  className="form-control form-control-sm"
                  onChange={(evt) => handleFollowInput('comeDate', evt)}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <hr className="custom-hr" />
        <div
          className="add-scroll"
          style={{ height: '211px', background: '#F5F5F5' }}
        >
          <Row className="complains-area" style={{ margin: 0 }}>
            {allFollowUp}
          </Row>
        </div>
        <hr className="selected-hr" />
        <div className="selected-item-title">Selected list</div>
        <div className="selected-item-area">{selectedFollowUpList()}</div>
      </Modal.Body>
    </Modal>
  );
};
export default memo(FollowUpModal);
