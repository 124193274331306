import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Header from '../components/doctors/partials/Header';
import TopLeftMenu from '../components/doctors/partials/topLeftMenu';

const Error = ({ error, resetErrorBoundary }) => {
  return (
    <div>
      {/* <Header /> */}
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-12 sticky-header shadow header-sticky-second">
            {/* <TopLeftMenu /> */}
          </div>
        </div>
        <Row className="rx-body">
          <div className="col-xl-3 col-md-3 col-lg-3"></div>
          <div className="col-xl-6 col-md-6 col-lg-6 mt-5">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-danger">Error</h6>
              </div>
              <div className="card-body appointment-card-body text-center">
                <div style={{ margin: '18px' }}>
                  <p className="text-danger">Error 404: Page Not Found</p>
                </div>
                <Button
                  className="btn btn-sm btn-primary mb-5 mt-3"
                  onClick={resetErrorBoundary}
                >
                  Back to Home
                </Button>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default Error;
