import { memo, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

const FormatsAndType = ({
  item,
  removeSchedule,
  isTabCabType,
  duplicateSchedule,
  handleSelectedMedicine,
}) => {
  const planAction = (type) => {
    if (type === 'minus' && item?.itemDetails?.length > 1) {
      removeSchedule(item?.itemDetails?.length - 1);
    } else if (type === 'plus' && item?.itemDetails?.length < 5) {
      duplicateSchedule();
    }
  };
  return (
    <>
      <div className="format-items">
        <h5>Formats</h5>
        <div
          className={`${
            isTabCabType && item?.type !== 'Injection' ? 'is-tab-selected' : ''
          }`}
          onClick={() =>
            handleSelectedMedicine('medicineType', item.id, 'Tablet')
          }
        >
          Tablet
        </div>
        <div
          className={`${!isTabCabType ? 'is-syrup-selected' : ''}`}
          onClick={() =>
            handleSelectedMedicine('medicineType', item.id, 'Syrup')
          }
        >
          Syrup
        </div>
        <div
          className={`${item?.type === 'Injection' ? 'is-inj-selected' : ''}`}
          onClick={() =>
            handleSelectedMedicine('medicineType', item.id, 'Injection')
          }
        >
          Injection
        </div>
      </div>
      <hr />
      <h5>Type</h5>
      <Row className="type-item">
        <Col md={9}>Multiple Medicine Plan</Col>
        <Col className="type">
          <span onClick={() => planAction('minus')}>
            <i className="fa fa-minus"></i>
          </span>
          <span>{item?.itemDetails?.length}</span>
          <span onClick={() => planAction('plus')}>
            <i className="fa fa-plus"></i>
          </span>
        </Col>
      </Row>
    </>
  );
};

export default memo(FormatsAndType);
