import React, { memo, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import LoadingButton from '../../forms/LoadingButton';
import { useValidation } from '../../../hooks/validationHooks/useValiation';
import { treatmentPlanGroupSchema } from '../../../validationSchemas/treatmentPlanGroup.validation';
import { usePostRequest } from '../../../hooks/usePostRequest';
import ModalHeader from '../partials/modalHeader';

const TreatmentPlanGroupForm = ({
  isGroupModal,
  setIsGroupModal,
  itemNames,
  modalTitle,
  treatmentPlanGroups,
  setTreatmentPlanGroups,
  selectedTreatmentPlansGroups,
  setSelectedTreatmentPlansGroups,
}) => {
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({});
  const validation = useValidation;

  const onSuccess = (data) => {
    setIsGroupModal(false);
    setTreatmentPlanGroups([...treatmentPlanGroups, data]);
    setSelectedTreatmentPlansGroups([...selectedTreatmentPlansGroups, data]);
  };

  const onError = (error) => {
    console.log('from react query error: ', error.message);
  };

  const { isLoading, mutate: submitUser } = usePostRequest(
    'treatment-plans/groups',
    {
      name,
      itemNames,
    },
    onSuccess,
    onError,
  );

  const handleSubmit = () => {
    const { isValid, errors } = validation({ name }, treatmentPlanGroupSchema);
    if (isValid) {
      submitUser();
    }
    {
      setErrors(errors);
    }
  };
  const getErrorMessage = (inputName) => {
    return errors[inputName] ? errors[inputName] : '';
  };

  return (
    <Modal show={isGroupModal} size="md" className="child-modal">
      <ModalHeader title={`${modalTitle} Group`} action={setIsGroupModal} />
      <Modal.Body>
        <Form>
          <Form.Group className="mb-2" controlId="exampleForm.ControlInput1">
            <Form.Label className="label-custom">Group Name</Form.Label>
            <Form.Control
              type="text"
              size="sm"
              className={`${getErrorMessage('name') ? 'v-border' : ''}`}
              placeholder="Enter group name"
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
            <small className="v-error">{getErrorMessage('name')}</small>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          size="sm"
          onClick={() => setIsGroupModal(false)}
        >
          Close
        </Button>
        <div className="d-grid gap-2">
          {isLoading ? (
            <LoadingButton btnFull="yes" />
          ) : (
            <Button variant="primary" size="sm" onClick={handleSubmit}>
              Save Changes
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default memo(TreatmentPlanGroupForm);
