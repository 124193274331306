import React, { memo, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import LoadingButton from '../../forms/LoadingButton';
import { useValidation } from '../../../hooks/validationHooks/useValiation';
import { usePostRequest } from '../../../hooks/usePostRequest';
import { specialNoteSchema } from '../../../validationSchemas/specialNote.validation';

const SpecialGroupNote = ({
  modalTitle,
  isSpecialNoteGroup,
  setIsSpecialNoteGroup,
  specialNote,
  setSpecialNote,
  specialNotes,
  setSpecialNotes,
}) => {
  const [groupName, setGroupName] = useState('');
  const [errors, setErrors] = useState({});
  const validation = useValidation;

  const onSuccess = (data) => {
    setSpecialNotes([
      ...specialNotes,
      { note: data.note, groupName: data.groupName },
    ]);
    setSpecialNote('');
    setIsSpecialNoteGroup(false);
  };

  const onError = (error) => {
    //setAuthError(error.message);
    console.log('from react query error: ', error.message);
  };

  const { isLoading, mutate: submitUser } = usePostRequest(
    'special-notes',
    {
      groupName: groupName,
      note: specialNote,
    },
    onSuccess,
    onError,
  );

  const handleSubmit = () => {
    const { isValid, errors } = validation(
      { note: specialNote, groupName },
      specialNoteSchema,
    );
    if (isValid) {
      submitUser();
    }
    {
      setErrors(errors);
    }
  };
  const getErrorMessage = (inputName) => {
    return errors[inputName] ? 'Please enter note and group name' : '';
  };

  return (
    <>
      <Modal show={isSpecialNoteGroup} size="md" className="child-modal">
        <Modal.Header className="common-modal-header">
          <Modal.Title>{modalTitle} Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="label-custom">Group Name</Form.Label>
              <Form.Control
                type="text"
                size="sm"
                className={`${getErrorMessage('groupName') ? 'v-border' : ''}`}
                placeholder="Enter group name"
                autoFocus
                onChange={(e) => setGroupName(e.target.value)}
              />
              <small className="v-error">{getErrorMessage('groupName')}</small>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setIsSpecialNoteGroup(false)}
          >
            Close
          </Button>
          <div className="d-grid gap-2">
            {isLoading ? (
              <LoadingButton btnFull="yes" />
            ) : (
              <Button variant="primary" size="sm" onClick={handleSubmit}>
                Save Changes
              </Button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default memo(SpecialGroupNote);
