import { memo, useState, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { itemSettingStyle, getDragDropStyle } from '../../../../utils/helpers';
import CommonDeleteBtn from '../commonDeleteBtn';
import { Row, Col, Form } from 'react-bootstrap';
import { useClickAway } from 'react-use';
import moment from 'moment';

const Referredto = ({
  element,
  prescriptionItems,
  setIsReferredToModal,
  deletePrescriptionItem,
  selectedReferredto,
  setSelectedReferredto,
  isHistoryPage = false,
  isDelBtn = true,
}) => {
  const [isEdit, setIsEdit] = useState('');

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      selectedReferredto,
      result.source.index,
      result.destination.index,
    );
    setSelectedReferredto(reorderedItems);
  };

  // this function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleTreatmentExtras = (item, fieldName, e) => {
    const objIndex = selectedReferredto?.findIndex(
      (data) => data.name == item.name,
    );
    selectedReferredto[objIndex][fieldName] = e.target.value;
    setSelectedReferredto([...selectedReferredto]);
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    setIsEdit('');
  });

  const editMode = (item) => {
    return (
      <Row ref={ref} className="selected-item-row" key={item.name}>
        <Col className="name" lg="5" md="5" sm="5" xs="5">
          {item.name}
        </Col>
        <Col lg="6" md="6" sm="6" xs="6" className="responsive">
          <Form.Control
            size="sm"
            type="text"
            defaultValue={item?.note}
            placeholder="Enter note"
            onChange={(e) => handleTreatmentExtras(item, 'note', e)}
          />
        </Col>
        <Col md="1" className="text-right pr-0">
          <i
            className="fa fa-times-circle pt-2"
            aria-hidden="true"
            onClick={() => setIsEdit('')}
          ></i>
        </Col>
      </Row>
    );
  };

  return (
    <div
      className={`row item-row ${
        selectedReferredto?.length ? 'data-item' : 'no-data-item'
      }`}
      key={`referred-to`}
    >
      <span
        className="prescription-item"
        {...(!isHistoryPage
          ? {
              onClick: (e) => {
                e.stopPropagation();
                setIsReferredToModal(true);
              },
            }
          : {})}
      >
        {element?.alterName?.length ? (
          <span>{element?.alterName} +</span>
        ) : (
          <span> Referred To +</span>
        )}
      </span>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <ul
              className="sub-child-list drag-drop referred-to-view-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {selectedReferredto?.map((item, index) => (
                <Draggable
                  key={item.name}
                  draggableId={item.name}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <>
                      <li
                        key={index}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getDragDropStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                        onClick={() => setIsEdit(index)}
                      >
                        <div>
                          <span
                            className="text-left pr-5"
                            style={itemSettingStyle(
                              prescriptionItems?.items?.['treatment-plan']
                                ?.itemStyle || {},
                            )}
                          >
                            {item.name}{' '}
                          </span>
                          {!isHistoryPage && (
                            <CommonDeleteBtn
                              action={deletePrescriptionItem}
                              itemName={'referredto'}
                              delId={item.name}
                            />
                          )}
                        </div>
                        {item.note !== '' ? (
                          <div
                            style={itemSettingStyle(
                              prescriptionItems?.items?.['treatment-plan']
                                ?.subItemStyle || {},
                            )}
                            className="pl-2 font-italic"
                          >
                            - {item.note}
                          </div>
                        ) : (
                          ''
                        )}
                      </li>
                      {index === isEdit && editMode(item)}
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default memo(Referredto);
