import React from 'react'

function AdminLogin() {
    return (
        <div>
            <h1>This is admin login page</h1>
        </div>
    )
}

export default AdminLogin;