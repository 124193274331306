import { memo, useEffect, useState } from 'react';
import parse from 'html-react-parser';
import Accordion from 'react-bootstrap/Accordion';
import { Tab, Nav } from 'react-bootstrap';

const Information = ({ indications }) => {
  const [activeKey, setActiveKey] = useState('0');
  const handleSelect = (key) => {
    setActiveKey(key);
  };

  // const showData = (data) => {
  //   return data?.map(
  //     (item) =>
  //       item?.details &&
  //       item?.details !== '' &&
  //       item.details !== '<p><br></p>' && (
  //           <div key={item.id}>
  //             <div className="indication-title">{item?.title}</div>
  //             <div className="indication-text">{parse(item?.details)}</div>
  //           </div>,
  //         ),
  //   );
  // };
  const showData = (data) => {
    return (
      data?.map(
        (item) =>
          item?.details &&
          item?.details.trim() !== '' &&
          item.details.trim() !== '<p><br></p>' && (
            <div key={item.id}>
              <div className="indication-title">{item?.title}</div>
              <div className="indication-text">{parse(item?.details)}</div>
            </div>
          ),
      ) || []
    );
  };

  return (
    <Accordion activeKey={activeKey} onSelect={handleSelect}>
      <Accordion.Item eventKey="0" size="sm">
        {/* <Accordion.Header>
          {indicationTitle ? indicationTitle : 'No Title Yet'}
        </Accordion.Header> */}
        <Accordion.Body>
          <Tab.Container defaultActiveKey="english">
            <Nav variant="pills" className="mt-1 mb-1 bangla-english">
              <Nav.Item>
                <Nav.Link eventKey="english">English</Nav.Link>
              </Nav.Item>
              {indications?.banglaResult?.length > 0 && (
                <Nav.Item>
                  <Nav.Link eventKey="bangla">Bangla</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="english" className="english">
                {showData(indications?.englishResult)}
              </Tab.Pane>
              <Tab.Pane eventKey="bangla" className="bangla">
                {showData(indications?.banglaResult)}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default memo(Information);
