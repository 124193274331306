import { memo } from 'react';
import { Row } from 'react-bootstrap';
import LoadMore from '../partials/loadMore';
import ItemWithDeleteIcon from './itemWithDeleteIcon';

const Observation = ({
  perPage,
  totalItem,
  currentPage,
  setCurrentPage,
  allObservations,
  observations,
  handleOnExaminationData,
  handleNoteOfObservation,
  suggestions,
}) => {
  // const selectObservation = (itemName) => {
  //   if (observations?.some((observation) => observation.name === itemName)) {
  //     observations = observations.filter(
  //       (observation) => observation.name !== itemName,
  //     );
  //     handleOnExaminationData(observations, 'observation');
  //   } else {
  //     observations = [...observations, { name: itemName, note: '' }];
  //     handleOnExaminationData(observations, 'observation');
  //   }
  // };

  const selectObservation = (item) => {
    if (observations?.some((observation) => observation.name === item.name)) {
      observations = observations.filter(
        (observation) => observation.name !== item.name,
      );
      handleOnExaminationData(observations, 'observation');
    } else {
      observations = [...observations, { name: item.name, side: '', note: '' }];
      handleOnExaminationData(observations, 'observation');
    }
  };

  const selectObservationWithNote = (item) => {
    if (observations?.some((observation) => observation.name === item.name)) {
      observations = observations.map((observation) =>
        observation.name !== item.name
          ? observation
          : { ...observation, side: '', note: item?.note },
      );
      handleOnExaminationData(observations, 'observation');
    } else {
      observations = [
        ...observations,
        { name: item.name, side: '', note: item?.note },
      ];
      handleOnExaminationData(observations, 'observation');
    }
  };

  const observationList = allObservations?.map((item, index) => {
    const isSelected = observations?.some(
      (selectedItem) => selectedItem.name === item.name,
    )
      ? true
      : false;
    return (
      <ItemWithDeleteIcon
        key={index}
        item={item}
        isSelected={isSelected}
        itemClickAction={selectObservation}
        // removeClickAction={deleteDiagnosis}
        suggestions={suggestions}
        selectItemWithNote={selectObservationWithNote}
      />
    );
  });

  return (
    <>
      <Row className="complains-area mr-0 ml-0">{observationList}</Row>
      <LoadMore
        currentPage={currentPage}
        totalItem={totalItem}
        perPage={perPage}
        currentPageAction={setCurrentPage}
      />
    </>
  );
};

export default memo(Observation);
