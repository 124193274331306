import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  profilePicture: '',
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.data = action.payload;
    },
    loginFailed: (state) => {
      console.log('working dispatch');
      state.data = {};
    },
    profilePicture: (state, action) => {
      state.profilePicture = action.payload;
    },
    removeProfilePicture: (state, action) => {
      state.profilePicture = '';
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loginSuccess,
  loginFailed,
  profilePicture,
  removeProfilePicture,
} = authSlice.actions;
export default authSlice.reducer;
