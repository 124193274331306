import React, { memo } from 'react';
import Select from 'react-select';

const SelectCustom = ({
  handleInputChange,
  options,
  onChange,
  components,
  placeholder,
  formatOptionLabel,
  selectedInputs,
  functionOnMenuClose,
}) => {
  const isSelectedItem = (itemName) => {
    const result = selectedInputs?.some(
      (template) =>
        (placeholder === 'follow up'
          ? template
          : placeholder === 'medicine'
          ? template.id
          : template.name) === itemName,
    );
    return {
      background: result ? '#e6fff7' : '',
    };
  };

  const colorStyles = {
    control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data }) => {
      return {
        ...styles,
        paddingTop: '2px',
        borderBottom: '1px solid #ffffff',
        cursor: 'pointer',
        ...isSelectedItem(placeholder === 'medicine' ? data.id : data.name),
      };
    },
  };

  return (
    <Select
      classNamePrefix="Select Type"
      value={''}
      closeMenuOnSelect={false}
      placeholder={
        placeholder
          ? `Search ${
              placeholder === 'template' ? placeholder : ` / Add ${placeholder}`
            }`
          : ''
      }
      isClearable={false}
      isSearchable={true}
      name="type"
      formatOptionLabel={formatOptionLabel ? formatOptionLabel : ''}
      components={components}
      options={options}
      onInputChange={handleInputChange}
      onChange={onChange}
      styles={colorStyles}
      autoFocus
      onMenuClose={functionOnMenuClose ? functionOnMenuClose : false}
    />
  );
};

export default memo(SelectCustom);
