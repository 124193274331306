import React, { memo, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { patchRequest } from '../../../utils/axiosRequests';
import { toast } from 'react-toastify';
import { loginSuccess } from '../../../store/slices/authSlice';

const GyneRxViewSetting = () => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const [isGyneRxView, setIsGyneRxView] = useState(
    userInfo?.doctorDetails?.isGyneRxView ?? false,
  );

  const dispatch = useDispatch();
  const handleSignature = async () => {
    await patchRequest(`doctors/update-gyne-rx-view/${userInfo.id}`, {
      isGyneRxView,
    })
      .then((res) => {
        const result = {
          ...userInfo,
          doctorDetails: {
            ...userInfo.doctorDetails,
            isGyneRxView: isGyneRxView,
          },
        };
        dispatch(loginSuccess({ ...result }));
        toast.success('Successfully uploaded', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        const errorMsg = error.message.split(',');
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div>
      <Row className="mt-3">
        <Col md={3}>
          <Form.Group controlId="code-type">
            <Form.Check
              type="radio"
              id="qrcode"
              name="GyneRxView"
              label="Normal view"
              checked={isGyneRxView === false}
              onChange={(e) => setIsGyneRxView(false)}
            />
            <Form.Check
              type="radio"
              id="none"
              name="GyneRxView"
              label="Gyne Rx view"
              checked={isGyneRxView === true}
              onChange={(e) => setIsGyneRxView(true)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2 mt-2">
        <Col md={1}></Col>
        <Col>
          <Button size="sm" onClick={() => handleSignature()}>
            Update
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default memo(GyneRxViewSetting);
