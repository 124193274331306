import React, { memo, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { defaultPatientPictureSettings } from '../../../utils/helpers';

const PatientPictureSetting = () => {
  const { position, height, width, isPrint } = defaultPatientPictureSettings();
  const [positionVal, setPositionVal] = useState(position);
  const [heightVal, setHeightVal] = useState(height);
  const [widthVal, setWidthVal] = useState(width);
  const [isPrintVal, setIsPrintVal] = useState(isPrint);

  const submitForm = () => {
    let result = {};
    if (positionVal) {
      result['position'] = positionVal;
    }
    if (heightVal) {
      result['height'] = heightVal;
    }
    if (widthVal) {
      result['width'] = widthVal;
    }
    result['isPrint'] = isPrintVal;
    localStorage.setItem('patientPicture', JSON.stringify(result));

    toast.success('Successfully uploaded', {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  return (
    <div>
      <Row className="mt-3">
        <Col md={2} sm={2}>
          <Form.Group controlId="code-type">
            <Form.Check
              type="radio"
              id="left"
              name="position"
              label="Left"
              checked={positionVal === 'left'}
              onChange={() => setPositionVal('left')}
            />
            <Form.Check
              type="radio"
              id="rigit"
              name="position"
              label="Right"
              checked={positionVal === 'right'}
              onChange={() => setPositionVal('right')}
            />
          </Form.Group>
          <Form.Group controlId="code-type" className="mt-2">
            <Form.Check
              type="checkbox"
              id="left"
              name="position"
              label="Show in prescription print?"
              checked={isPrintVal === true}
              onChange={(e) => setIsPrintVal(e.target.checked)}
            />
          </Form.Group>
        </Col>
        <Col md={3} sm={8}>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextHeight">
            <Form.Label column sm="6" className="text-right">
              Height
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="height"
                size="sm"
                value={heightVal}
                placeholder="Enter numeric value"
                onChange={(e) => setHeightVal(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextWidth">
            <Form.Label column sm="6" className="text-right">
              Width
            </Form.Label>
            <Col sm="6">
              <Form.Control
                type="text"
                name="width"
                size="sm"
                value={widthVal}
                placeholder="Enter numeric value"
                onChange={(e) => setWidthVal(e.target.value)}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4 mt-2">
        <Col md={1}></Col>
        <Col>
          <Button size="sm" onClick={() => submitForm()}>
            Update
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default memo(PatientPictureSetting);
