import React, { useState, useEffect, memo } from 'react';
import { Modal, Row, Col, Button, Form } from 'react-bootstrap';
import ModalHeader from '../partials/modalHeader';
import {
  bsaBmiCalculation,
  defaultGeneralData,
  separateDateTime,
} from '../../../utils/helpers';
import FieldTypeBtnOncology from '../partials/fieldTypeBtnOncology';
import GeneralExaminationBtn from '../partials/generalExaminationBtn';

const Predictions = ({
  modalTitle,
  patientInfo,
  setPatientInfo,
  showPredictions,
  setShowPredictions,
  selectedOnExamination,
  setSelectedOnExamination,
}) => {
  let { dob } = patientInfo;
  dob = separateDateTime(dob);
  let { generalExaminations } = selectedOnExamination;
  const [showDropDownBtn, setShowDropDownBtn] = useState(false);
  const [btnInputArray, setBtnInputArray] = useState([]);
  const [fieldName, setFieldName] = useState('');
  const [isPediatric, setIsPediatric] = useState(false);
  const [cvdResult, setCvdResult] = useState('N/A');
  const [strokeResult, setStrokResult] = useState('N/A');
  const [heartAttackResult, setHeartAttackResult] = useState('N/A');

  generalExaminations = generalExaminations || defaultGeneralData();
  let selectedData = generalExaminations?.predictions
    ? generalExaminations.predictions
    : {
        race: '',
        smoking: '',
        hdl: '',
        ldl: '',
        hypertension: '',
        hypertensionMedication: '',
        microalbuminuria: '',
        cataract: '',
        hemorrhages: '',
        avNicking: '',
        hollenhorst: '',
      };

  const getRaceVAlue = (raceName, withEyeParam, type = '') => {
    let result = 0;
    let race_3 = 0;
    let race_4 = 0;
    let race_5 = 0;
    switch (raceName) {
      case 'Asian or pacific islander':
        race_3 = 1;
        break;
      case 'Hispanic':
        race_4 = 2;
        break;
      case 'Other':
        race_5 = 0;
        break;
    }
    result = withEyeParam
      ? race_3 * 0.47 -
        race_4 * (type === 'heatAttack' ? 0.31 : 0.38) +
        race_5 * 0.14
      : race_3 * 0.47 -
        race_4 * (type === 'heatAttack' ? 0.31 : 0.34) +
        race_5 * 0.16;
    return result;
  };

  useEffect(() => {
    if (
      dob?.years > 0 &&
      selectedData?.smoking !== '' &&
      generalExaminations?.bloodPressure?.diastolic !== '' &&
      generalExaminations?.diabetes !== '' &&
      selectedData.hdl !== '' &&
      selectedData.hypertension !== '' &&
      selectedData?.microalbuminuria !== '' &&
      selectedData?.race !== '' &&
      selectedData?.hypertensionMedication !== '' &&
      generalExaminations?.bloodPressure?.systolic !== '' &&
      generalExaminations?.heightInfo?.feet !== '' &&
      generalExaminations?.weightInfo?.weight !== ''
    ) {
      const age = dob?.years;
      const smoking = selectedData?.smoking === 'Yes' ? 1 : 0;
      const diastolic = generalExaminations?.bloodPressure?.diastolic;
      const diabetes = generalExaminations?.diabetes === 'Yes' ? 1 : 0;
      const gender = patientInfo?.gender === 'female' ? 1 : 0;
      const hdl = selectedData.hdl;
      const hypertension = selectedData.hypertension === 'Yes' ? 1 : 0;
      const microalbuminuria = selectedData?.microalbuminuria === 'Yes' ? 1 : 0;
      const hypertensionMdication =
        selectedData?.hypertensionMedication === 'Yes' ? 1 : 0;
      const systolic = generalExaminations?.bloodPressure?.systolic;
      const cataract = selectedData?.cataract === 'Yes' ? 1 : 0;
      const hemorrhages = selectedData?.hemorrhages === 'Yes' ? 1 : 0;
      const avNicking = selectedData?.avNicking === 'Yes' ? 1 : 0;
      const hollenhorst = selectedData?.hollenhorst === 'Yes' ? 1 : 0;
      const feet = generalExaminations?.heightInfo?.feet;
      const inch = generalExaminations?.heightInfo?.inch;
      const heightUnit = generalExaminations?.heightInfo?.heightUnit;
      const weight = generalExaminations?.weightInfo?.weight;
      const weightUnit = generalExaminations?.weightInfo?.weightUnit;

      const predResult = getCvdResult(
        age,
        smoking,
        diastolic,
        diabetes,
        gender,
        hdl,
        hypertension,
        microalbuminuria,
        hypertensionMdication,
        systolic,
        cataract,
        hemorrhages,
        avNicking,
        hollenhorst,
        feet,
        inch,
        heightUnit,
        weight,
        weightUnit,
      );
      const percent = (predResult * 100) / 42.1;
      setCvdResult(percent);
      const strokeResult = getStrokeResult(
        age,
        smoking,
        diastolic,
        diabetes,
        gender,
        hdl,
        hypertension,
        microalbuminuria,
        hypertensionMdication,
        systolic,
        cataract,
        hemorrhages,
        avNicking,
        hollenhorst,
        feet,
        inch,
        heightUnit,
        weight,
        weightUnit,
      );
      const strokePercent = (strokeResult * 100) / 42.1;
      setStrokResult(strokePercent);
      const heartAttackResult = getHeartAttackResult(
        age,
        smoking,
        diastolic,
        diabetes,
        gender,
        hdl,
        hypertension,
        microalbuminuria,
        hypertensionMdication,
        systolic,
        cataract,
        hemorrhages,
        avNicking,
        hollenhorst,
        feet,
        inch,
        heightUnit,
        weight,
        weightUnit,
      );
      const heartAttackPercent = (heartAttackResult * 100) / 42.1;
      setHeartAttackResult(heartAttackPercent);
    } else {
      setCvdResult('N/A');
      setStrokResult('N/A');
      setHeartAttackResult('N/A');
    }
  }, [
    dob?.years,
    generalExaminations?.heightInfo?.feet,
    generalExaminations?.heightInfo?.inch,
    generalExaminations?.heightInfo?.heightUnit,
    generalExaminations?.weightInfo?.weight,
    generalExaminations?.weightInfo?.weightUnit,
    selectedData?.smoking,
    generalExaminations?.bloodPressure?.diastolic,
    generalExaminations?.diabetes,
    patientInfo?.gender,
    selectedData.hdl,
    selectedData.hypertension,
    selectedData?.microalbuminuria,
    selectedData?.race,
    selectedData?.hypertensionMedication,
    generalExaminations?.bloodPressure?.systolic,
    selectedData?.cataract,
    selectedData?.hemorrhages,
    selectedData?.avNicking,
    selectedData?.hollenhorst,
  ]);

  const getHeartAttackResult = (
    age,
    smoking,
    diastolic,
    diabetes,
    gender,
    hdl,
    hypertension,
    microalbuminuria,
    hypertensionMdication,
    systolic,
    cataract,
    hemorrhages,
    avNicking,
    hollenhorst,
    feet,
    inch,
    heightUnit,
    weight,
    weightUnit,
  ) => {
    let result = '';
    const { bmi } = bsaBmiCalculation(
      feet,
      inch,
      heightUnit,
      weight,
      weightUnit,
    );

    if (cataract && avNicking && hemorrhages && hollenhorst) {
      result =
        age * 0.045 +
        bmi * 0.01 +
        smoking * 0.88 -
        diastolic * 0.01 +
        diabetes * 0.49 +
        gender * 0.72 -
        0.01 * hdl +
        hypertension * 0.32 +
        microalbuminuria * 0.34 -
        getRaceVAlue(selectedData?.race, true, 'heatAttack') -
        hypertensionMdication * 0.09 +
        systolic * 0.01 -
        cataract * 0.14 +
        hemorrhages * 0.47 -
        avNicking * 0.08 +
        hollenhorst * 0.7;
    } else {
      result =
        age * 0.045 +
        bmi * 0.01 +
        smoking * 0.88 -
        diastolic * 0.01 +
        diabetes * 0.49 +
        gender * 0.74 -
        0.01 * hdl +
        hypertension * 0.32 +
        microalbuminuria * 0.37 -
        getRaceVAlue(selectedData?.race, false, 'heatAttack') -
        hypertensionMdication * 0.09 +
        systolic * 0.01;
    }
    return result;
  };
  const getStrokeResult = (
    age,
    smoking,
    diastolic,
    diabetes,
    gender,
    hdl,
    hypertension,
    microalbuminuria,
    hypertensionMdication,
    systolic,
    cataract,
    hemorrhages,
    avNicking,
    hollenhorst,
    feet,
    inch,
    heightUnit,
    weight,
    weightUnit,
  ) => {
    let result = '';
    const { bmi } = bsaBmiCalculation(
      feet,
      inch,
      heightUnit,
      weight,
      weightUnit,
    );

    if (cataract && avNicking && hemorrhages && hollenhorst) {
      result =
        age * 0.045 +
        bmi * 0.01 +
        smoking * 0.76 -
        diastolic * 0.01 +
        diabetes * 0.37 +
        gender * 0.72 -
        0.01 * hdl +
        hypertension * 0.32 +
        microalbuminuria * 0.34 -
        getRaceVAlue(selectedData?.race, true, '') -
        hypertensionMdication * 0.09 +
        systolic * 0.01 -
        cataract * 0.14 +
        hemorrhages * 0.47 -
        avNicking * 0.08 +
        hollenhorst * 0.7;
    } else {
      result =
        age * 0.045 +
        bmi * 0.01 +
        smoking * 0.75 -
        diastolic * 0.01 +
        diabetes * 0.37 +
        gender * 0.74 -
        0.01 * hdl +
        hypertension * 0.32 +
        microalbuminuria * 0.37 -
        getRaceVAlue(selectedData?.race, false, '') -
        hypertensionMdication * 0.09 +
        systolic * 0.01;
    }
    return result;
  };

  const getCvdResult = (
    age,
    smoking,
    diastolic,
    diabetes,
    gender,
    hdl,
    hypertension,
    microalbuminuria,
    hypertensionMdication,
    systolic,
    cataract,
    hemorrhages,
    avNicking,
    hollenhorst,
    feet,
    inch,
    heightUnit,
    weight,
    weightUnit,
  ) => {
    let result = '';
    const { bmi } = bsaBmiCalculation(
      feet,
      inch,
      heightUnit,
      weight,
      weightUnit,
    );
    console.log(bmi, 'BMI');
    if (cataract && avNicking && hemorrhages && hollenhorst) {
      result =
        age * 0.05 +
        bmi * 0.01 +
        smoking * 0.76 -
        diastolic * 0.01 +
        diabetes * 0.37 +
        gender * 0.72 -
        0.01 * hdl +
        hypertension * 0.32 +
        microalbuminuria * 0.34 -
        getRaceVAlue(selectedData?.race, true, '') -
        hypertensionMdication * 0.09 +
        systolic * 0.01 -
        cataract * 0.14 +
        hemorrhages * 0.47 -
        avNicking * 0.08 +
        hollenhorst * 0.7;
    } else {
      result =
        age * 0.05 +
        bmi * 0.01 +
        smoking * 0.75 -
        diastolic * 0.01 +
        diabetes * 0.49 +
        gender * 0.74 -
        0.01 * hdl +
        hypertension * 0.32 +
        microalbuminuria * 0.37 -
        getRaceVAlue(selectedData?.race, false, '') -
        hypertensionMdication * 0.09 +
        systolic * 0.01;
    }
    return result;
  };

  const handleBtnInputArray = (field) => {
    setFieldName(field);
    let buttonArray = [];
    if (field === 'systolic') {
      buttonArray = [
        70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
      ];
    } else if (field === 'diastolic') {
      buttonArray = [40, 50, 60, 70, 80, 90, 100, 110, 120];
    } else if (field === 'pulse') {
      buttonArray = [
        40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120,
      ];
    } else if (field === 'weight') {
      isPediatric
        ? (buttonArray = [2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35])
        : (buttonArray = [
            40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115,
            120,
          ]);
    } else if (field === 'feet') {
      buttonArray = [1, 2, 3, 4, 5, 6, 7];
    } else if (field === 'inch') {
      buttonArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    }
    setShowDropDownBtn(showDropDownBtn ? false : true);
    setBtnInputArray(buttonArray);
  };

  const handlePredictionsData = (fieldName, val) => {
    switch (fieldName) {
      case 'feet':
      case 'inch':
      case 'heightUnit':
        if (fieldName === 'heightUnit' && val === 'Cm') {
          generalExaminations.heightInfo['inch'] = '';
        }
        generalExaminations.heightInfo[fieldName] = val;
        break;
      case 'weight':
      case 'weightUnit':
        generalExaminations['weightInfo'][fieldName] = val;
        break;
      case 'race':
        selectedData[fieldName] = val;
        generalExaminations.predictions = selectedData;
        break;
      case 'systolic':
      case 'diastolic':
        generalExaminations.bloodPressure[fieldName] = val;
        break;
      case 'smoking':
      case 'hypertension':
      case 'hypertensionMedication':
      case 'microalbuminuria':
      case 'cataract':
      case 'hemorrhages':
      case 'avNicking':
      case 'hollenhorst':
        selectedData[fieldName] = selectedData[fieldName] === val ? '' : val;
        generalExaminations.predictions = selectedData;
        break;
      case 'hdl':
      case 'ldl':
        selectedData[fieldName] = val;
        generalExaminations.predictions = selectedData;
        break;
      case 'diabetes':
        generalExaminations[fieldName] =
          generalExaminations[fieldName] === val ? '' : val;
        break;
    }

    setSelectedOnExamination({
      ...selectedOnExamination,
      generalExaminations,
    });
    setShowDropDownBtn(false);
  };

  const poundToWeight = () => {
    let result = generalExaminations?.weightInfo?.weight;
    if (
      generalExaminations?.weightInfo?.weight > 0 &&
      generalExaminations?.weightInfo?.weightUnit === 'Pound'
    ) {
      result = generalExaminations?.weightInfo?.weight * 0.45359237;
    }
    return result;
  };

  return (
    <>
      <Modal
        show={showPredictions}
        size="lg"
        className="customize-modal-size"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader title={modalTitle} action={setShowPredictions} />
        <Modal.Body style={{ maxHeight: '518px', overflow: 'scroll' }}>
          <Row className="title">
            <Col>General Information</Col>
          </Row>
          <hr />
          <Row>
            <Col md={8}>
              <Row>
                <Col md={3} className="label-text pt-1">
                  Gender:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        patientInfo?.gender === 'male'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        setPatientInfo({ ...patientInfo, gender: 'male' })
                      }
                    >
                      Male
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        patientInfo?.gender === 'female'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        setPatientInfo({ ...patientInfo, gender: 'female' })
                      }
                    >
                      Female
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        patientInfo?.gender === 'others'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        setPatientInfo({ ...patientInfo, gender: 'others' })
                      }
                    >
                      Others
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} className="label-text">
                  Age:
                </Col>
                <Col>
                  <Form.Control
                    size="sm"
                    className="w-60"
                    type="number"
                    //defaultValue={patientInfo?.dob?.years}
                    defaultValue={dob?.years > 0 ? dob?.years : ''}
                    value={dob?.years > 0 ? dob?.years : ''}
                    onChange={(e) =>
                      setPatientInfo({
                        ...patientInfo,
                        dob: { ...patientInfo?.dob, years: e.target.value },
                      })
                    }
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md={3} className="label-text">
                  Height:
                </Col>
                <Col>
                  <Form.Control
                    className="form-control-sm"
                    value={generalExaminations?.heightInfo?.feet}
                    defaultValue={generalExaminations?.heightInfo?.feet}
                    type="text"
                    placeholder={
                      generalExaminations?.heightInfo.heightUnit === 'Ft'
                        ? 'Feet'
                        : 'Cm'
                    }
                    onClick={() => handleBtnInputArray('feet')}
                    onChange={(e) =>
                      handlePredictionsData('feet', e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Form.Control
                    className="form-control-sm"
                    value={generalExaminations?.heightInfo?.inch}
                    defaultValue={generalExaminations?.heightInfo?.inch}
                    type="text"
                    disabled={
                      generalExaminations?.heightInfo?.heightUnit === 'Cm'
                        ? true
                        : false
                    }
                    placeholder="Inch"
                    onClick={() => handleBtnInputArray('inch')}
                    onChange={(e) =>
                      handlePredictionsData('inch', e.target.value)
                    }
                  />
                </Col>

                <Col md="2">
                  <Form.Select
                    className="form-control form-control-sm"
                    defaultValue={generalExaminations?.heightInfo?.heightUnit}
                    onChange={(e) =>
                      handlePredictionsData('heightUnit', e.target.value)
                    }
                  >
                    <option value="Ft">Ft</option>
                    <option value="Cm">Cm</option>
                  </Form.Select>
                </Col>
              </Row>
              {showDropDownBtn &&
                (fieldName === 'feet' || fieldName === 'inch') && (
                  <Row>
                    <Col md={3}></Col>
                    <Col md={9}>
                      <GeneralExaminationBtn
                        fieldName={fieldName}
                        btnInputArray={btnInputArray}
                        onClickAction={handlePredictionsData}
                      />
                    </Col>
                  </Row>
                )}

              <Row className="mt-2">
                <Col md={3} className="label-text pt-4">
                  Weight:
                </Col>
                <Col>
                  <FieldTypeBtnOncology
                    btnArr={['KG', 'Pound']}
                    fieldName={'weightUnit'}
                    selectedItem={generalExaminations?.weightInfo?.weightUnit}
                    actionMethod={handlePredictionsData}
                  />
                  <Form.Control
                    size="sm"
                    className="w-60"
                    type="number"
                    defaultValue={generalExaminations?.weightInfo?.weight}
                    onChange={(e) =>
                      handlePredictionsData('weight', e.target.value)
                    }
                  />
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md={3} className="label-text">
                  Race:
                </Col>
                <Col>
                  <Form.Select
                    className="form-control form-control-sm"
                    defaultValue={selectedData?.race}
                    onChange={(e) =>
                      handlePredictionsData('race', e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    <option value="White (non hispanic)">
                      White (non hispanic)
                    </option>
                    <option value="Black (non hispanic)">
                      Black (non hispanic)
                    </option>
                    <option value="Asian or pacific islander">
                      Asian or pacific islander
                    </option>
                    <option value="Hispanic">Hispanic</option>
                    <option value="Other">Other</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md={3} className="label-text">
                  B. Pressure:
                </Col>
                <Col md={3}>
                  <Form.Control
                    className="form-control-sm"
                    type="number"
                    value={generalExaminations?.bloodPressure?.systolic}
                    defaultValue={generalExaminations?.bloodPressure?.systolic}
                    placeholder="Systolic"
                    onClick={() => handleBtnInputArray('systolic')}
                    onChange={(e) =>
                      handlePredictionsData('systolic', e.target.value)
                    }
                  />
                </Col>
                <Col md={1}>
                  <span
                    className="line"
                    style={{
                      width: '30px',
                      borderBottom: '1px solid #4e73df',
                      transform: `translateY(15px)
                            translateX(2px)
                            rotate(110deg)`,
                      position: 'absolute',
                      top: '3px',
                    }}
                  ></span>
                </Col>
                <Col md={3}>
                  <Form.Control
                    className="form-control-sm"
                    type="number"
                    value={generalExaminations?.bloodPressure?.diastolic}
                    defaultValue={generalExaminations?.bloodPressure?.diastolic}
                    placeholder="diastolic"
                    onClick={() => handleBtnInputArray('diastolic')}
                    onChange={(e) =>
                      handlePredictionsData('diastolic', e.target.value)
                    }
                  />
                </Col>
                <Col sm="2" className="pt-2">
                  <span>Mm Hg</span>
                </Col>
              </Row>
              {showDropDownBtn &&
                (fieldName === 'systolic' || fieldName === 'diastolic') && (
                  <Row>
                    <Col md={fieldName === 'systolic' ? 6 : 3}></Col>
                    <Col md={fieldName === 'diastolic' ? 5 : 9}>
                      <GeneralExaminationBtn
                        fieldName={fieldName}
                        btnInputArray={btnInputArray}
                        onClickAction={handlePredictionsData}
                      />
                    </Col>
                  </Row>
                )}
              <Row className="mt-1">
                <Col md={3} className="label-text pt-1">
                  Smoking:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.smoking === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('smoking', 'Yes')}
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.smoking === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('smoking', 'No')}
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="title mt-4">
                <Col>Clinical Parameters</Col>
              </Row>
              <hr />
              <Row className="mt-2">
                <Col md={3} className="label-text">
                  HDL:
                </Col>
                <Col md={7}>
                  <Form.Control
                    className="form-control-sm"
                    type="text"
                    value={selectedData?.hdl}
                    defaultValue={selectedData?.hdl}
                    placeholder="HDL"
                    onChange={(e) =>
                      handlePredictionsData('hdl', e.target.value)
                    }
                  />
                </Col>
                <Col sm="2" className="pt-2">
                  <span>mg/dl</span>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={3} className="label-text">
                  LDL:
                </Col>
                <Col md={7}>
                  <Form.Control
                    className="form-control-sm"
                    type="text"
                    value={selectedData?.ldl}
                    defaultValue={selectedData?.ldl}
                    placeholder="LDL"
                    onChange={(e) =>
                      handlePredictionsData('ldl', e.target.value)
                    }
                  />
                </Col>
                <Col sm="2" className="pt-2">
                  <span>mg/dl</span>
                </Col>
              </Row>
              <Row>
                <Col md={5} className="label-text pt-1">
                  Diabetes:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        generalExaminations?.diabetes === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('diabetes', 'Yes')}
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        generalExaminations?.diabetes === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('diabetes', 'No')}
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={5} className="label-text pt-1">
                  Hypertension:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hypertension === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('hypertension', 'Yes')
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hypertension === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('hypertension', 'No')
                      }
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={5} className="label-text pt-1">
                  Hypertension Medication:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hypertensionMedication === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('hypertensionMedication', 'Yes')
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hypertensionMedication === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('hypertensionMedication', 'No')
                      }
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={5} className="label-text pt-1">
                  Microalbuminuria:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.microalbuminuria === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('microalbuminuria', 'Yes')
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.microalbuminuria === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('microalbuminuria', 'No')
                      }
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <span className="title">Eye Parameters</span> (
                  <small>Optional</small>)
                </Col>
              </Row>
              <hr />

              <Row>
                <Col md={6} className="label-text pt-1">
                  Cataract:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.cataract === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('cataract', 'Yes')}
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.cataract === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('cataract', 'No')}
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="label-text pt-1">
                  Hemorrhages/Microneurysms:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hemorrhages === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('hemorrhages', 'Yes')
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hemorrhages === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('hemorrhages', 'No')}
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="label-text pt-1">
                  AV Nicking:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.avNicking === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('avNicking', 'Yes')}
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.avNicking === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('avNicking', 'No')}
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col md={6} className="label-text pt-1">
                  Hollenhorst plaque:
                </Col>
                <Col>
                  <div className="common-small-btn label-text">
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hollenhorst === 'Yes'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() =>
                        handlePredictionsData('hollenhorst', 'Yes')
                      }
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      variant={
                        selectedData?.hollenhorst === 'No'
                          ? 'primary'
                          : 'outline-secondary'
                      }
                      onClick={() => handlePredictionsData('hollenhorst', 'No')}
                    >
                      No
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="prediction-area">
              <div className="prediction">
                <div className="data-area">
                  <span>CVD:</span>
                  <span className="point-area">
                    <span>
                      {cvdResult !== 'N/A' ? cvdResult.toFixed(1) + '%' : 'N/A'}
                    </span>
                  </span>
                  <span className="low">
                    {cvdResult !== 'N/A' && parseInt(cvdResult) < 5
                      ? 'Low Risk'
                      : ''}
                  </span>
                  <span className="medium">
                    {cvdResult !== 'N/A' &&
                    parseInt(cvdResult) >= 5 &&
                    parseInt(cvdResult) < 12
                      ? 'Medium Risk'
                      : ''}
                  </span>
                  <span className="high">
                    {cvdResult !== 'N/A' && parseInt(cvdResult) >= 12
                      ? 'High Risk'
                      : ''}
                  </span>
                </div>
                <div className="data-area">
                  <span>Stroke:</span>
                  <span className="point-area">
                    <span>
                      {strokeResult !== 'N/A'
                        ? strokeResult.toFixed(1) + '%'
                        : 'N/A'}
                    </span>
                  </span>
                  <span className="low">
                    {strokeResult !== 'N/A' && parseInt(strokeResult) < 5
                      ? 'Low Risk'
                      : ''}
                  </span>
                  <span className="medium">
                    {strokeResult !== 'N/A' &&
                    parseInt(strokeResult) >= 5 &&
                    parseInt(strokeResult) < 12
                      ? 'Medium Risk'
                      : ''}
                  </span>
                  <span className="high">
                    {strokeResult !== 'N/A' && parseInt(strokeResult) >= 12
                      ? 'High Risk'
                      : ''}
                  </span>
                </div>
                <div className="data-area">
                  <span>Heart Attack:</span>
                  <span className="point-area">
                    <span>
                      {heartAttackResult !== 'N/A'
                        ? heartAttackResult.toFixed(1) + '%'
                        : 'N/A'}
                    </span>
                  </span>
                  <span className="low">
                    {heartAttackResult !== 'N/A' &&
                    parseInt(heartAttackResult) < 5
                      ? 'Low Risk'
                      : ''}
                  </span>
                  <span className="medium">
                    {heartAttackResult !== 'N/A' &&
                    parseInt(heartAttackResult) >= 5 &&
                    parseInt(heartAttackResult) < 12
                      ? 'Medium Risk'
                      : ''}
                  </span>
                  <span className="high">
                    {heartAttackResult !== 'N/A' &&
                    parseInt(heartAttackResult) >= 12
                      ? 'High Risk'
                      : ''}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default memo(Predictions);
